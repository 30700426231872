import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import {
  PricePeriodValue,
  PricingPlanOption,
} from '~/components/organisms/modules/Pricing/Pricing.types';

type PricingStoreState = {
  pricePeriod: PricePeriodValue;
  setPricePeriod: (curPricePeriod: PricePeriodValue) => void;
  selectedPlan: PricingPlanOption;
  setSelectedPlan: (plan: PricingPlanOption) => void;
};

const usePricingStore = create<PricingStoreState>()(
  subscribeWithSelector((set) => ({
    pricePeriod: PricePeriodValue.MONTH,
    setPricePeriod: (curPricePeriod: PricePeriodValue) =>
      set(() => ({ pricePeriod: curPricePeriod })),
    selectedPlan: 'pro',
    setSelectedPlan: (plan: PricingPlanOption) =>
      set(() => ({ selectedPlan: plan })),
  })),
);

export default usePricingStore;
