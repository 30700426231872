export const Moon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.9325 10.3227C14.2479 9.5838 13.3989 8.97921 12.6024 9.08475C12.3867 9.11334 12.1666 9.1281 11.943 9.1281C9.19734 9.1281 6.97151 6.90227 6.97151 4.15658C6.97151 3.93303 6.98627 3.71292 7.01486 3.49717C7.1204 2.70075 6.51581 1.8517 5.77691 2.16707C3.55636 3.11484 2 5.31828 2 7.88522C2 11.3173 4.78228 14.0996 8.21439 14.0996C10.7813 14.0996 12.9848 12.5433 13.9325 10.3227Z"
      fill="#FCFCFC"
    />
  </svg>
);

export const Sun = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75002 1.84961C8.75002 1.4354 8.41424 1.09961 8.00002 1.09961C7.58581 1.09961 7.25002 1.4354 7.25002 1.84961V3.34961C7.25002 3.76382 7.58581 4.09961 8.00002 4.09961C8.41424 4.09961 8.75002 3.76382 8.75002 3.34961V1.84961ZM1 8.09961C1 7.6854 1.33579 7.34961 1.75 7.34961H3.25C3.66421 7.34961 4 7.6854 4 8.09961C4 8.51382 3.66421 8.84961 3.25 8.84961H1.75C1.33579 8.84961 1 8.51382 1 8.09961ZM11.9956 8.09961C11.9956 7.6854 12.3314 7.34961 12.7456 7.34961H14.2456C14.6598 7.34961 14.9956 7.6854 14.9956 8.09961C14.9956 8.51382 14.6598 8.84961 14.2456 8.84961H12.7456C12.3314 8.84961 11.9956 8.51382 11.9956 8.09961ZM8.00002 12.0996C8.41424 12.0996 8.75002 12.4354 8.75002 12.8496V14.3496C8.75002 14.7638 8.41424 15.0996 8.00002 15.0996C7.58581 15.0996 7.25002 14.7638 7.25002 14.3496V12.8496C7.25002 12.4354 7.58581 12.0996 8.00002 12.0996ZM11.8995 10.9227C11.6066 10.6298 11.1317 10.6298 10.8388 10.9227C10.546 11.2156 10.546 11.6904 10.8388 11.9833L11.8995 13.044C12.1924 13.3369 12.6673 13.3369 12.9602 13.044C13.2531 12.7511 13.2531 12.2762 12.9602 11.9833L11.8995 10.9227ZM3.04095 3.15362C3.33385 2.86073 3.80872 2.86073 4.10161 3.15362L5.16227 4.21428C5.45517 4.50717 5.45517 4.98205 5.16227 5.27494C4.86938 5.56783 4.39451 5.56783 4.10161 5.27494L3.04095 4.21428C2.74806 3.92139 2.74806 3.44651 3.04095 3.15362ZM12.9614 4.21417C13.2543 3.92127 13.2543 3.4464 12.9614 3.15351C12.6685 2.86061 12.1936 2.86061 11.9007 3.15351L10.8401 4.21417C10.5472 4.50706 10.5472 4.98193 10.8401 5.27483C11.133 5.56772 11.6078 5.56772 11.9007 5.27483L12.9614 4.21417ZM5.16692 10.9304C5.45981 11.2233 5.45981 11.6981 5.16692 11.991L4.10626 13.0517C3.81337 13.3446 3.33849 13.3446 3.0456 13.0517C2.75271 12.7588 2.75271 12.2839 3.0456 11.991L4.10626 10.9304C4.39915 10.6375 4.87403 10.6375 5.16692 10.9304ZM8 11.0996C9.65685 11.0996 11 9.75646 11 8.09961C11 6.44276 9.65685 5.09961 8 5.09961C6.34315 5.09961 5 6.44276 5 8.09961C5 9.75646 6.34315 11.0996 8 11.0996Z"
      fill="#BDC0D4"
    />
  </svg>
);
