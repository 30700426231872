export const DURATION = 10;
export const PROGRESS_DELTA_STEPS = 0.0003;
export const EASE = 0.05;

export type MarqueeConfig = {
  // Speed of animation
  speed: number;
  deltaThreshold?: number;
  deltaThresholdMobile?: number;
};
