import { ForwardedRef, forwardRef } from 'react';

import BentoCard from '../BentoCard/BentoCard';
import {
  DesktopBentoCardsProps,
  DesktopBentoCardsRef,
} from './DesktopBentoCards.types';

const DesktopBentoCards = (
  { styles, cards, glow, isInView }: DesktopBentoCardsProps,
  ref: ForwardedRef<DesktopBentoCardsRef>,
) => {
  return (
    <>
      <div
        className={styles.cardsColumnLeft}
        ref={(node) => {
          if (ref && typeof ref !== 'function' && ref.current) {
            ref.current.$cardsColumnLeft.current = node;
          }
        }}
      >
        {cards.flatMap((card, index: number) =>
          index % 2 === 0 ? (
            <BentoCard
              {...card}
              key={card._key}
              className={styles.card}
              isInView={isInView !== false}
              glow={glow}
            />
          ) : null,
        )}
      </div>
      <div
        className={styles.cardsColumnRight}
        ref={(node) => {
          if (ref && typeof ref !== 'function' && ref.current) {
            ref.current.$cardsColumnRight.current = node;
          }
        }}
      >
        {cards.flatMap((card, index: number) =>
          index % 2 > 0 ? (
            <BentoCard
              {...card}
              key={card._key}
              className={styles.card}
              isInView={isInView !== false}
              glow={glow}
            />
          ) : null,
        )}
      </div>
    </>
  );
};

export default forwardRef<DesktopBentoCardsRef, DesktopBentoCardsProps>(
  DesktopBentoCards,
);
