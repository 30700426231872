'use client';
import { gsap } from 'gsap';
import { useCallback, useEffect, useRef, useState } from 'react';

import Modal from '~/components/molecules/Modal/Modal';
import {
  ModalBackgroundType,
  TransitionFunction,
} from '~/components/molecules/Modal/Modal.types';

import styles from './InteractiveQuotesLgModal.module.css';
import { InteractiveQuotesLgModalProps } from './InteractiveQuotesLgModal.types';
import InteractiveQuotesLgModalContent from './InteractiveQuotesLgModalContent/InteractiveQuotesLgModalContent';
import {
  EnterTimelineObject,
  InteractiveQuotesLgModalContentRef,
} from './InteractiveQuotesLgModalContent/InteractiveQuotesLgModalContent.types';

const InteractiveQuotesLgModal = ({
  currentIndex,
  sourceRect,
  quoteCards,
  onClose,
}: InteractiveQuotesLgModalProps) => {
  const [carouselIndex, setCarouselIndex] = useState<number | undefined>(
    currentIndex,
  );
  //  use to trigger closing animation
  const [shouldCloseModal, setShouldCloseModal] = useState<boolean>(false);

  const quoteCardsModalContent =
    useRef<InteractiveQuotesLgModalContentRef>(null);

  const transitionPlaying = useRef<boolean>(false);

  useEffect(() => {
    if (currentIndex !== undefined && !transitionPlaying.current) {
      setCarouselIndex(currentIndex);
    }
  }, [currentIndex]);

  const handlePrevious = useCallback(() => {
    if (carouselIndex !== undefined && carouselIndex > 0) {
      setCarouselIndex(carouselIndex - 1);
    }
  }, [carouselIndex]);

  const handleNext = useCallback(() => {
    if (carouselIndex !== undefined && carouselIndex < quoteCards.length) {
      setCarouselIndex(carouselIndex + 1);
    }
  }, [carouselIndex, quoteCards.length]);

  const handleKeyDown = useCallback(
    (event: globalThis.KeyboardEvent) => {
      if (event.code === 'ArrowLeft') {
        handlePrevious();
      }
      if (event.code === 'ArrowRight') {
        handleNext();
      }
    },
    [handlePrevious, handleNext],
  );

  const modalTransitionEnter: TransitionFunction = useCallback(
    ({ $modal, $modalBackground, done }) => {
      // since enter timeline of content is async, we need to wait for it
      if (!quoteCardsModalContent.current) return;

      quoteCardsModalContent.current
        .enterTimeline()
        .then(({ timeline: modalContentTl }: EnterTimelineObject) => {
          const tl = gsap.timeline({
            onComplete: () => {
              done();
            },
          });

          tl.set($modal.current, {
            alpha: 1,
          });

          tl.add(modalContentTl, '0');

          tl.fromTo(
            $modalBackground.current,
            {
              alpha: 0,
            },
            {
              alpha: 1,
            },
            '<+0.5',
          );
        });
    },
    [],
  );

  const modalTransitionLeave: TransitionFunction = useCallback(
    ({ $modal, done }) => {
      const tl = gsap.timeline({
        onComplete: () => {
          //  call done when animation is over to close modal
          done();
        },
      });
      if (quoteCardsModalContent.current)
        tl.add(quoteCardsModalContent.current.leaveTimeline(), '0');

      tl.to($modal.current, {
        alpha: 0,
        duration: 0.5,
      });
    },
    [],
  );

  return (
    <Modal
      backgroundType={ModalBackgroundType.ALPHA}
      contentClassName={styles.modalWrapper}
      defaultCloseButton={false}
      shouldClose={shouldCloseModal}
      onKeyDown={handleKeyDown}
      transitionEnter={modalTransitionEnter}
      transitionLeave={modalTransitionLeave}
      hasShadow={false}
      onClose={onClose}
    >
      <InteractiveQuotesLgModalContent
        carouselIndex={carouselIndex}
        quoteCards={quoteCards}
        ref={quoteCardsModalContent}
        sourceRect={sourceRect}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        startClosingModal={() => setShouldCloseModal(true)}
      />
    </Modal>
  );
};

export default InteractiveQuotesLgModal;
