'use client';

import { capitalize } from 'lodash';
import { ForwardedRef, forwardRef } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Media from '~/components/molecules/Media/Media';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { cn } from '~/utils';

import styles from './BentoCard.module.css';
import { BentoCardProps } from './BentoCard.types';

const BentoCard = (
  {
    className,
    content,
    background,
    foreground,
    textPlacement,
    textAlignment,
    bentoMediaLayout,
    row,
    bentoSize,
    isInView,
  }: BentoCardProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const ForegroundWrapper =
    foreground && foreground.decoration === 'glassborder'
      ? GlassWrapper
      : 'div';
  const ShadowWrapper =
    foreground && foreground.decoration !== 'none' ? Shadow : 'div';

  return (
    <Shadow ref={ref} className={cn(styles.shadow, className)}>
      <div
        className={cn(
          styles.bentoCard,
          styles[`bentoSize${bentoSize}`],
          styles[`textPlacement${capitalize(textPlacement)}`],
          styles[`textAlignment${capitalize(textAlignment)}`],
          // when bento span over multiple rows, make sure the text align with the edge
          row > 1 && styles.spaceBetween,
          background &&
            background.mediaDisplayMode === 'fill' &&
            styles.isFullBleedBackgound,
        )}
      >
        <PortableText
          value={content}
          className={styles.content}
          options={{
            block: {
              bodies: {
                className: cn(styles.body),
              },
            },
          }}
        />
        <div
          className={cn(
            styles.mediaWrapper,
            styles[bentoMediaLayout],
            background && styles[background.mediaDisplayMode],
            foreground &&
              bentoMediaLayout === 'foregroundOnly' &&
              styles[`foreground${capitalize(foreground.mediaSize)}`],
          )}
        >
          {background && (
            <div
              className={cn(
                styles.backgroundWrapper,
                background.mediaDisplayMode === 'fittedBakedShadows' &&
                  background.size &&
                  styles[`backgroundSize${capitalize(background.size)}`],
              )}
            >
              <Media
                sanityMedia={background.media.media.sanityMedia}
                className={cn(
                  styles.backgroundMedia,
                  styles[background.mediaDisplayMode],
                )}
                fixedAspectRatio={true}
                isMuted={true}
                isDisplayed={isInView}
              />
            </div>
          )}
          {foreground && (
            <div className={cn(styles.foregroundContainer)}>
              <ShadowWrapper>
                <ForegroundWrapper
                  className={
                    foreground && foreground.decoration !== 'glassborder'
                      ? styles.noBorder
                      : ''
                  }
                >
                  <Media
                    sanityMedia={foreground.media.media.sanityMedia}
                    className={styles.foregroundMedia}
                    fixedAspectRatio={true}
                    isMuted={true}
                  />
                </ForegroundWrapper>
              </ShadowWrapper>
            </div>
          )}
        </div>
      </div>
    </Shadow>
  );
};

export default forwardRef(BentoCard);
