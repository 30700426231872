import { CMSGraphicProps } from '~/components/atoms/Graphic/Graphic.types';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { PortableTextCustomValue } from '~/components/molecules/PortableText/PortableText.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export const DEFAULT_CARDS_DISPLAYED = 6;

export type CMSCardGridEditorialProps = CMSModuleBase & {
  cardGridEditorialItems: CardGridEditorialItem[];
};

export type CardGridEditorialProps = CMSModuleBase & {
  cardGridEditorialItems: CardGridEditorialItem[];
  logoWrapperHeight: number;
};

export type CardGridEditorialItem = {
  _key: string;
  text: PortableTextCustomValue;
  link: CMSLinkProps;
  image: CMSEnhancedMedia<'image'>;
  logo: CMSGraphicProps;
};
