'use client';
import { gsap } from 'gsap';
import { CSSProperties, useCallback, useRef, useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import customBreakpoints from '~/styles/theme/customBreakpoints.module.css';
import {
  cn,
  getBreakpointFromLabel,
  killTimeline,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from './Bento.module.css';
import {
  CMSBento,
  PARALLAX_COLUMN_SHIFT_RATIO,
  PARALLAX_DISTANCE,
  PARALLAX_DISTANCE_LG,
} from './Bento.types';
import BentoCard from './BentoCard/BentoCard';
import DesktopBentoCards from './DesktopBentoCards/DesktopBentoCards';
import { DesktopBentoCardsRef } from './DesktopBentoCards/DesktopBentoCards.types';

const columnsBreakpoint = parseInt(customBreakpoints.bentosMultipleColumns);
const lgBreakpoint = getBreakpointFromLabel('lg')?.min ?? 0;

const Bento = (props: CMSBento) => {
  const { cards, className, theme } = props;

  const $desktopColumns = useRef<DesktopBentoCardsRef>({
    $cardsColumnLeft: { current: null },
    $cardsColumnRight: { current: null },
  });
  const $desktopColumnsClones = useRef<DesktopBentoCardsRef>({
    $cardsColumnLeft: { current: null },
    $cardsColumnRight: { current: null },
  });

  const [isInViewDesktop, updateIsInViewDesktop] = useState<false | DOMRect>(
    false,
  );
  const [isInViewMobile, updateIsInViewMobile] = useState<false | DOMRect>(
    false,
  );

  const $element = useRef<HTMLDivElement>(null);

  const tl = useRef<GSAPTimeline>();

  useLayoutEffect(() => {
    const unsubscribe = UIStore.subscribe(
      (state) => state.windowWidth,
      () => {
        if (
          typeof $desktopColumns.current !== 'undefined' &&
          typeof $desktopColumnsClones.current !== 'undefined' &&
          typeof tl.current === 'undefined' &&
          (window.innerWidth ?? 0) >= columnsBreakpoint
        ) {
          // reduced parallax distance for md
          let parallaxDistance = PARALLAX_DISTANCE;
          const maxParallaxDistance = PARALLAX_DISTANCE_LG;

          if (lgBreakpoint && window.innerWidth >= lgBreakpoint) {
            // full parallax distance for lg
            parallaxDistance = PARALLAX_DISTANCE_LG;
          }

          tl.current = gsap.timeline({ paused: true });
          tl.current
            .fromTo(
              [
                $desktopColumns.current.$cardsColumnLeft.current,
                $desktopColumnsClones.current.$cardsColumnLeft.current,
              ],
              {
                y: `${parallaxDistance * PARALLAX_COLUMN_SHIFT_RATIO * 0.8}rem`,
              },
              {
                y: '0rem',
                ease: 'none',
              },
              0,
            )
            .fromTo(
              [
                $desktopColumns.current.$cardsColumnRight.current,
                $desktopColumnsClones.current.$cardsColumnRight.current,
              ],
              {
                y: `${parallaxDistance * PARALLAX_COLUMN_SHIFT_RATIO}rem`,
              },
              {
                y: `${
                  maxParallaxDistance * (1 - PARALLAX_COLUMN_SHIFT_RATIO * 0.8)
                }rem`,
                ease: 'none',
              },
              0,
            );
        } else if ((window.innerWidth ?? 0) < columnsBreakpoint && tl.current) {
          killTimeline(tl.current);
          tl.current = undefined;
        }
      },
      {
        fireImmediately: true,
      },
    );

    return () => {
      unsubscribe();
      if (tl.current) {
        killTimeline(tl.current);
        tl.current = undefined;
      }
    };
  }, []);

  const onProgress = useCallback((progress: number, isInView: boolean) => {
    if (isInView && tl.current) {
      tl.current?.progress(progress);
    }
  }, []);

  useScrollProgress($element, onProgress);

  return (
    <ModuleWrapper className={cn(styles.bento, className)} {...props}>
      <div
        className={cn(styles.cardsWrapper, styles[theme])}
        style={
          {
            '--parallax-shift-lg': `-${PARALLAX_DISTANCE_LG}rem`,
            '--parallax-shift': `-${PARALLAX_DISTANCE}rem`,
          } as CSSProperties
        }
      >
        <Observer
          className={styles.cardsDesktop}
          options={{ rootMargin: '200% 0%' }}
          callback={updateIsInViewDesktop}
          ref={$element}
        >
          <DesktopBentoCards
            cards={cards}
            isInView={isInViewDesktop}
            // The glows will be handled by the clones
            glow={false}
            styles={styles}
            ref={$desktopColumns}
          />
        </Observer>
        {/* Clone only for the glows to be displayed under */}
        <div className={cn(styles.cardsDesktop, styles.clone)}>
          <DesktopBentoCards
            cards={cards}
            // We don't need to load images in the clones
            isInView={false}
            glow={true}
            styles={styles}
            ref={$desktopColumnsClones}
          />
        </div>
        <Observer
          className={styles.cardsMobile}
          options={{ rootMargin: '200% 0%' }}
          callback={updateIsInViewMobile}
        >
          {cards.map((card) => (
            <BentoCard
              {...card}
              key={card._key}
              isInView={isInViewMobile !== false}
            />
          ))}
        </Observer>
        <div className={cn(styles.cardsMobile, styles.clone)}>
          {cards.map((card) => (
            <BentoCard {...card} key={card._key} isInView={false} glow={true} />
          ))}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default Bento;
