'use client';
import { gsap } from 'gsap';
import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import Graphic from '~/components/atoms/Graphic/Graphic';
import Image from '~/components/atoms/Image/Image';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';

import styles from './QuoteCarouselSlide.module.css';
import {
  ForwardedQuoteCarouselSlideRef,
  QuoteCarouselSlideProps,
} from './QuoteCarouselSlide.types';

/**
 * Quote Carousel slide item.
 * @param slideIndex Slide index number
 * @param gradientOverlay Selected gradient opacity option, defaults to medium

 * @param logo Image or SVG logo, displayed as eyebrow above the slide text
 * @param text Portable Text content displayed on the slide
 * @param image The slide image
 * @param onClick Callback for when a slide is clicked
 * @param isInView Boolean from intersection observer for whether the carousel is currently in view
 * @param variantName  Variant name for how wide the text block is, based on character counts
 * @param className
 * @example <QuoteCarouselSlide slides={slides]/>
 */
const QuoteCarouselSlide = (
  {
    className,
    eyebrow,
    image,
    isInView,
    logo,
    onClick,
    slideIndex,
    text,
    variantName,
  }: QuoteCarouselSlideProps,
  ref: ForwardedRef<ForwardedQuoteCarouselSlideRef>,
) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $portableText = useRef<HTMLDivElement>(null);
  const $staggerTextElements = useRef<NodeList>();
  const $image = useRef<HTMLElement>(null);
  const $logo = useRef<HTMLElement>(null);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const progressQuickSet = useRef<Function>();

  useImperativeHandle(
    ref,
    () => ({
      $element: $wrapper,
      // ignoring unused `direction` prop because it will be revisited in animation fast-follow
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setActive: (active: boolean, direction?: number) => {
        if (active) {
          if ($wrapper.current) {
            $wrapper.current.setAttribute('data-active', `${active}`);
            $wrapper.current.ariaHidden = 'false';
          }

          /*

          // animations are disabled until they are revisited as a fast-follow

          if ($logo.current && $staggerTextElements.current) {
            const tl = gsap.timeline();
            tl.fromTo(
              $image.current,
              {
                '--active-progress': 0 - (direction || -1),
              },
              {
                '--active-progress': 0,
                duration: 0.6,
                ease: EaseType.OUT,
              },
            );
            tl.fromTo(
              [$logo.current, ...$staggerTextElements.current],
              {
                '--active-progress': 0 - (direction || -1),
                opacity: 0,
              },
              {
                '--active-progress': 0,
                opacity: 1,
                duration: 0.6,
                stagger: 0.05,
                ease: EaseType.BASIC_BUTTER,
              },
              '<+0.1',
            );
          }
          */
        } else {
          if ($wrapper.current) {
            $wrapper.current.removeAttribute('data-active');
            $wrapper.current.ariaHidden = 'true';
          }

          /*

          // animations are disabled until they are revisited as a fast-follow

          if ($logo.current && $staggerTextElements.current) {
            const tl = gsap.timeline();
            tl.to([$logo.current, ...$staggerTextElements.current], {
              opacity: 0,
              duration: 0.6,
              ease: EaseType.BASIC_BUTTER,
            });
          }

          */
        }
      },
      setProgress: (progress: number) => {
        if (progressQuickSet.current) progressQuickSet.current(progress);
      },
    }),
    [],
  );

  useLayoutEffect(() => {
    progressQuickSet.current = gsap.quickSetter(
      $wrapper.current,
      '--scroll-progress',
    );

    $staggerTextElements.current =
      $wrapper.current?.querySelectorAll('.stagger');
  }, []);

  const handleClick = () => {
    if (onClick) onClick(slideIndex);
  };

  // Need to memoize this object, otherwise portable text gets re-rendered even with memo
  const memoizedPortableTextOptions = useMemo(() => {
    return {
      block: {
        titles: {
          className: cn(styles.titles, styles.stagger, 'stagger'),
          title4: {
            className: styles.title4,
            tagName: 'h3' as keyof JSX.IntrinsicElements,
          },
        },
        bodies: {
          className: cn(styles.bodies, styles.stagger, 'stagger'),
        },
        accents: {
          label: {
            className: cn(styles.label, styles.stagger, 'stagger'),
          },
        },
      },
      marks: {
        em: {
          className: styles.labelEm,
        },
      },
    };
  }, []);

  return (
    <div
      className={cn(styles.slide, className)}
      ref={$wrapper}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <Shadow className={styles.slideInner}>
        <EnhancedMedia
          overlay={image.overlay}
          className={styles.slideImageWrapper}
        >
          <Image
            className={styles.slideImage}
            ref={$image}
            source={image.image}
            isDisplayed={isInView}
            fixedAspectRatio={true}
          />
        </EnhancedMedia>
        {logo?.source && (
          <>
            <Graphic
              ref={$logo}
              {...logo}
              className={cn(styles.slideLogo)}
              name={logo.name}
              isInView={isInView !== false}
            />
            <div className={styles.lineBreak} />
          </>
        )}
        {!logo?.source && eyebrow && (
          <div className={styles.slideEyebrow}>{eyebrow}</div>
        )}
        <PortableText
          ref={$portableText}
          value={text}
          className={cn(styles.slideText, styles[variantName])}
          options={memoizedPortableTextOptions}
        />
      </Shadow>
    </div>
  );
};

const ForwardedQuoteCarouselSlide = forwardRef(QuoteCarouselSlide);

export default ForwardedQuoteCarouselSlide;
