import { ReactNode } from 'react';

import { PricingFeature } from '../../Pricing.types';
import styles from '../PricingTable.module.css';

export function FeatureLabel({
  title,
  children,
}: Pick<PricingFeature, 'title' | 'description'> & { children?: ReactNode }) {
  return (
    <div className={styles.featureComparisonTitle}>
      <span>{title}</span>
      {children && children}
    </div>
  );
}
