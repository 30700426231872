'use client';
import { gsap } from 'gsap';
import { useKeenSlider } from 'keen-slider/react';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import { PaginationDotsRef } from '~/components/atoms/Pagination/Dots/PaginationDots.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import { cn, keenSliderConfig, useScrollProgress } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './SideBySideStack.module.css';
import { SideBySideStackProps } from './SideBySideStack.types';
import StackedSideBySideWrapper from './StackedSideBySideWrapper/StackedSideBySideWrapper';
import {
  StackedSideBySideWrapperProps,
  StackedSideBySideWrapperRef,
} from './StackedSideBySideWrapper/StackedSideBySideWrapper.types';

const SideBySideStack = (props: SideBySideStackProps) => {
  const { className, contentWidth, layout, stackedSideBySides } = props;
  const $wrapper = useRef<HTMLDivElement>(null);
  const totalLockups = stackedSideBySides.length;

  const windowWidth = useUIStore((state) => state.windowWidth);
  const windowHeight = useUIStore((state) => state.windowHeight);

  const sectionRefs = useRef<StackedSideBySideWrapperRef[]>(
    Array(totalLockups),
  );
  const progressMap = useRef<{ low: number; high: number }[]>(
    Array(totalLockups),
  );

  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);
  const [isGlowHidden, setIsGlowHidden] = useState<boolean>(true);

  const pagination = useRef<PaginationDotsRef>(null);

  // Keen slider (mobile only)
  const [sliderRef, instanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    loop: false,
    selector: '.slideItem',
    slides: {
      origin: 'center',
      perView: 1,
      number: totalLockups,
    },
    detailsChanged: (slider) => {
      const progresses = slider.track.details.slides.map(
        (slide) => slide.portion,
      );
      progresses.forEach((progress: number, slideIndex: number) => {
        const slide = sectionRefs.current[slideIndex];
        if (slide) slide.setCarouselProgress(progress);
      });

      pagination.current?.setTrackPosition();
    },
    slideChanged(slider) {
      setActiveSectionIndex(slider.track.details.rel);
    },
    dragStarted: () => {
      setIsGlowHidden(true);
    },
    dragEnded: () => {
      setIsGlowHidden(false);
    },
    animationStopped: () => {
      setIsGlowHidden(false);
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: true,
      },
    },
  });

  const moveToIndex = (index: number) => {
    instanceRef.current?.moveToIdx(index);
  };

  // Scroll card stack
  const onProgress = useCallback(
    (progress: number, isInView: boolean) => {
      if (!instanceRef?.current?.options.disabled) return;
      // progress from 0 to sections array max index
      const normalizedProgress = progress / (1 / totalLockups);

      const index = Math.floor(normalizedProgress);

      if (index !== activeSectionIndex) {
        setActiveSectionIndex(index);
      }

      sectionRefs.current?.forEach(
        (ref: StackedSideBySideWrapperRef, sectionIndex: number) => {
          ref.setProgress(
            gsap.utils.clamp(
              0,
              1,
              gsap.utils.normalize(
                progressMap.current[sectionIndex].low,
                progressMap.current[sectionIndex].high,
                progress,
              ),
            ),
            isInView,
          );
        },
      );
    },
    [instanceRef, totalLockups, activeSectionIndex],
  );

  useScrollProgress($wrapper, onProgress);

  useEffect(() => {
    // map the ranges for each section so progress can be normalized accordingly
    let index = 0;
    let progressPixels = 0;
    for (const sectionRef of sectionRefs.current) {
      const $sectionElement = sectionRef.$element?.current;
      if ($sectionElement && $wrapper.current && windowHeight) {
        const lockupHeight = $sectionElement.offsetHeight;
        const wrapperHeight = $wrapper.current.offsetHeight;
        const windowLockupGap = (windowHeight - lockupHeight) / 2;
        const totalAmountToMove = wrapperHeight + windowHeight;

        if (index === 0) {
          // the intro section has to travel an additional distance to come to center from offscreen
          const firstLockupStartPixels = 0;
          const firstLockupStartRatio = 0;
          const firstLockupIntroPixels =
            firstLockupStartPixels + lockupHeight + windowLockupGap;
          const firstLockupOutroPixels = firstLockupIntroPixels + lockupHeight;
          const firstLockupCompleteRatio =
            firstLockupOutroPixels / totalAmountToMove;

          progressPixels = firstLockupIntroPixels;
          progressMap.current[index] = {
            low: firstLockupStartRatio,
            high: firstLockupCompleteRatio,
          };
        } else if (index === sectionRefs.current.length - 1) {
          // last section does not outro, so the high is set outside the progress range
          const lockupStartPixels = progressPixels;
          const lockupStartRatio = lockupStartPixels / totalAmountToMove;
          const lockupIntroPixels = lockupStartPixels + lockupHeight;

          progressPixels = lockupIntroPixels;

          progressMap.current[index] = {
            low: lockupStartRatio,
            high: 2,
          };
        } else {
          // an in-between section moves vertically one lockupHeight
          const lockupStartPixels = progressPixels;
          const lockupStartRatio = lockupStartPixels / totalAmountToMove;
          const lockupIntroPixels = lockupStartPixels + lockupHeight;
          const lockupOutroPixels = lockupIntroPixels + lockupHeight;
          const lockupCompleteRatio = lockupOutroPixels / totalAmountToMove;

          progressPixels = lockupIntroPixels;

          progressMap.current[index] = {
            low: lockupStartRatio,
            high: lockupCompleteRatio,
          };
        }
      }
      index++;
    }
  }, [windowWidth, windowHeight]);

  return (
    <ModuleWrapper
      className={cn(styles.sideBySideStack, className)}
      {...props}
      style={
        {
          '--total-sections': totalLockups,
        } as CSSProperties
      }
    >
      <div className={styles.container}>
        {/* Had to add a wrapping div, because `mergeRefs` was messing with keen slider */}
        <div ref={sliderRef}>
          <div ref={$wrapper} className={cn(styles.wrapper)}>
            {stackedSideBySides.map(
              (
                { content, logo, media, _key }: StackedSideBySideWrapperProps,
                index: number,
              ) => {
                return (
                  <StackedSideBySideWrapper
                    ref={(section: StackedSideBySideWrapperRef) =>
                      addToRefArray({
                        element: section,
                        refArray: sectionRefs,
                        index,
                      })
                    }
                    key={_key}
                    index={index}
                    className={cn('slideItem', styles.scrollingLockup)}
                    content={content}
                    contentWidth={contentWidth}
                    layout={layout}
                    logo={logo}
                    media={media}
                    total={totalLockups}
                    active={index === activeSectionIndex}
                    isGlowHidden={isGlowHidden}
                  />
                );
              },
            )}
          </div>
        </div>
        <PaginationDots
          className={styles.paginationDots}
          activeIndex={activeSectionIndex}
          total={totalLockups}
          onClick={moveToIndex}
          ref={pagination}
          parentSliderRef={instanceRef}
        />
      </div>
    </ModuleWrapper>
  );
};

export default SideBySideStack;
