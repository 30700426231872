import blur13 from '../utils/blur13';

const blur = /* glsl */ `
	precision highp float;

	${blur13}

	uniform sampler2D tMap;
	uniform vec2 u_direction;
	uniform vec2 u_resolution;
	uniform float u_blur_distance;
	varying vec2 vUv;

	void main() {
		// Swap with blur13 for higher quality
		// gl_FragColor = blur9(tMap, vUv, uResolution, uDirection * uBlurDistance);
		gl_FragColor = blur13(tMap, vUv, u_resolution, u_direction * u_blur_distance);
	}
`;

export default blur;
