'use client';
import throttle from 'lodash/throttle';
import { useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import UIStore from '~/state/ui';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import getTranslateValues from '~/utils/getTranslateValues';
import { tickerAddOnce } from '~/utils/ticker';

import { calculateIndex } from '../TileTable.utils';

export const useHighlightReference = (
  refs: HTMLDivElement[],
  parentSection: HTMLDivElement,
) => {
  const offsets = useRef<number[]>([]);

  const scrollPaddingTop = UIStore((state) => state.scrollPaddingTop);

  const [index, updateIndex] = useState(
    calculateIndex(offsets.current, ScrollPosition.y || 0),
  );

  useEffect(() => {
    const calculateOffsets = () => {
      tickerAddOnce(() => {
        const nextOffsets: number[] = [];

        // if the parent section has parallax applied, we need to accomodate for that value
        const parentSectionTransformY = parentSection
          ? getTranslateValues(parentSection)?.y || 0
          : 0;

        refs.forEach((ref, index) => {
          if (ref) {
            let nextOffset = 0;
            nextOffset = ref
              ? (ScrollPosition.y || 0) +
                ref.getBoundingClientRect().top -
                scrollPaddingTop -
                parentSectionTransformY
              : ScrollPosition.y || 0;

            nextOffsets[index] = nextOffset;
          }
        });
        offsets.current = nextOffsets;
      }, true);
    };
    calculateOffsets();
    calculateIndex(offsets.current, ScrollPosition.y || 0);

    // Make sure we re-calculate offsets on resize
    const unsubscribeResize = UIStore.subscribe(
      (state) => [state.windowWidth, state.windowHeight],
      calculateOffsets,
      {
        equalityFn: shallow,
      },
    );

    const indexUpdate = () => {
      const parentSectionTransformY = getTranslateValues(parentSection)?.y || 0;

      updateIndex(
        calculateIndex(
          offsets.current,
          ScrollPosition.y || 0 + parentSectionTransformY,
        ),
      );
    };

    const unsubscribeScroll = ScrollPosition.subscribe(
      throttle(indexUpdate, 100),
    );

    return () => {
      unsubscribeScroll();
      unsubscribeResize();
    };
  }, [refs, scrollPaddingTop, parentSection]);

  return index;
};
