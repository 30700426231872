import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

import { BentoCardProps } from './BentoCard/BentoCard.types';

export type CMSBento = CMSModuleBase & BentoProps;

export type BentoProps = {
  /**
   * bento cards
   */
  cards: BentoCardProps[];
  /**
   * bento theme
   */
  theme: 'light' | 'dark';
};

// in rem's
export const PARALLAX_DISTANCE = 8;
export const PARALLAX_DISTANCE_LG = 16;
export const PARALLAX_COLUMN_SHIFT_RATIO = 2;
