import PortableText from '~/components/molecules/PortableText/PortableText';
import { cn } from '~/utils';

import styles from './Stats.module.css';
import { StatsProps } from './Stats.types';

const Stats = ({ className, isHuge, stats }: StatsProps) => {
  const countClass = styles[`with${stats?.length ?? 0}Stats`];
  const renderClass = cn(
    countClass,
    styles.stats,
    isHuge ? styles.isHuge : styles.isNormal,
    className,
  );

  return (
    <div className={renderClass}>
      {stats?.length &&
        stats.map((stat) => {
          const { _key, value, valueSuffix, label } = stat;
          return (
            <p className={styles.stat} key={_key}>
              <span className={styles.valueAndSuffix}>
                <span className={isHuge ? styles.valueHuge : styles.value}>
                  {value}
                </span>
                <span
                  className={
                    isHuge ? styles.valueSuffixHuge : styles.valueSuffix
                  }
                >
                  {valueSuffix}
                </span>
              </span>
              <PortableText
                value={label}
                containerTagName="span"
                options={{
                  block: {
                    bodies: {
                      tagName: 'span',
                      className: isHuge ? styles.labelHuge : styles.label,
                    },
                  },
                }}
              />
            </p>
          );
        })}
    </div>
  );
};

export default Stats;
