'use client';
import { CSSProperties, useRef, useState } from 'react';

import DeviceWrapper from '~/components/atoms/DeviceWrapper/DeviceWrapper';
import Observer from '~/components/atoms/Observer/Observer';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import {
  textLockup2,
  textLockup5,
} from '~/components/molecules/TextLockups/TextLockups.config';
import { cn } from '~/utils';

import styles from '../MediaWithText.module.css';
import { WrapInDeviceOptions } from '../MediaWithText.types';
import { MediaWithTextContentProps } from './MediaWithTextContent.types';

const MAX_PARALLAX_PERCENT = 16;

const MediaWithTextContent = ({
  media,
  mediaWrapperClassName,
  text,
  textColumns,
  textContentClassName,
  textLayout = 'fullWidth',
  textPosition,
  wrapInDevice,
}: MediaWithTextContentProps) => {
  const $media = useRef<HTMLElement | VideoRef>(null);
  const needsParallax = false;
  const CMSAspectRatio =
    'asset' in media.sanityMedia
      ? media.sanityMedia.asset.aspectRatio
      : media.sanityMedia.aspectRatio;
  const mediaAspectRatio =
    CMSAspectRatio && needsParallax
      ? CMSAspectRatio * (needsParallax ? 1 + MAX_PARALLAX_PERCENT / 100 : 1)
      : CMSAspectRatio;
  const mediaOriginalAspectRatio = CMSAspectRatio || null;

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const textLockup5Options = textLockup5();
  const textLockup2Options = textLockup2();

  const renderText = () => {
    if (textLayout === 'twoColumn' && textColumns?.col1 && textColumns?.col2) {
      return (
        <div
          className={cn(
            styles.textContent,
            textContentClassName,
            textPosition === 'above' && styles.isTextAbove,
            wrapInDevice === WrapInDeviceOptions.studioDisplay &&
              styles.studioDisplay,
            wrapInDevice === WrapInDeviceOptions.macbookPro &&
              styles.macbookPro,
          )}
        >
          <PortableText
            className={cn(
              textLockup5Options.wrapperClass,
              styles.textContentCol1,
            )}
            value={textColumns.col1}
            options={textLockup5Options.options}
          />

          <PortableText
            className={cn(
              textLockup5Options.wrapperClass,
              styles.textContentCol2,
            )}
            value={textColumns.col2}
            options={textLockup5Options.options}
          />
        </div>
      );
    }

    if (textLayout === 'fullWidth' && text) {
      return (
        <PortableText
          className={cn(
            textLockup2Options.wrapperClass,
            styles.textContent,
            textContentClassName,
            textPosition === 'above' && styles.isTextAbove,
            wrapInDevice === WrapInDeviceOptions.studioDisplay &&
              styles.studioDisplay,
            wrapInDevice === WrapInDeviceOptions.macbookPro &&
              styles.macbookPro,
          )}
          value={text}
          options={textLockup2Options.options}
        />
      );
    }

    return null;
  };

  return (
    <>
      {textPosition === 'above' && renderText()}
      <div
        className={cn(
          styles.mediaWrapper,
          wrapInDevice === WrapInDeviceOptions.studioDisplay &&
            styles.studioDisplay,
          wrapInDevice === WrapInDeviceOptions.macbookPro && styles.macbookPro,
          mediaWrapperClassName,
        )}
      >
        <Shadow className={styles.dropShadow} />
        <Observer
          className={styles.contentWrapper}
          callback={updateIsInView}
          options={{ rootMargin: '200% 0%' }}
        >
          <div
            className={styles.mediaContainer}
            style={
              {
                ...(mediaAspectRatio
                  ? { '--media-aspect-ratio': mediaAspectRatio }
                  : {}),
                ...(mediaOriginalAspectRatio
                  ? {
                      '--media-original-aspect-ratio': mediaOriginalAspectRatio,
                    }
                  : {}),
              } as CSSProperties
            }
          >
            <Media
              ref={$media}
              className={cn(styles.media, styles.noMediaParallax)}
              sanityMedia={media.sanityMedia}
              isDisplayed={isInView !== false}
              fixedAspectRatio={true}
            />
          </div>
        </Observer>
        {wrapInDevice && (
          <DeviceWrapper
            deviceType={wrapInDevice}
            isInView={isInView !== false}
          />
        )}
      </div>
      {textPosition !== 'above' && renderText()}
    </>
  );
};

export default MediaWithTextContent;
