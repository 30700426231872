import SvgCircleCheckmark from '~/assets/svg/circle-checkmark.svg';
import AccordionItem from '~/components/atoms/AccordionItem/AccordionItem';
import { AccordionItemProps } from '~/components/atoms/AccordionItem/AccordionItem.types';
import usePricingStore from '~/state/pricing';
import { cn } from '~/utils';

import {
  PricingCategory as PricingCategoryType,
  PricingFeature,
  PricingPlanOption,
} from '../../Pricing.types';
import styles from '../PricingTable.module.css';
import { FeatureLabel } from './FeatureLabel';

export default function PricingCategory(
  props: PricingCategoryType & {
    plan?: PricingPlanOption;
    className?: string;
  } & Pick<AccordionItemProps, 'onToggle'>,
) {
  const { title, features, plan, defaultExpanded, onToggle, className } = props;

  const selectedPlan = usePricingStore((state) => state.selectedPlan);
  const activePlan = plan || selectedPlan;

  const renderBoolean = (val: boolean) =>
    val === true ? (
      <SvgCircleCheckmark className={styles.checkmarkComparisonCheck} />
    ) : (
      <figure className={styles.checkmarkComparisonNoCheck}>—</figure>
    );

  const renderValue = (value: string | number | boolean) =>
    typeof value === 'boolean' ? renderBoolean(value) : value.toString();

  const renderActiveValue = (limits: PricingFeature['limits']) => {
    const active = limits.find(({ plan }) => plan === activePlan);

    if (active) {
      return renderValue(active.value);
    }
  };

  return (
    <AccordionItem
      className={cn(styles.categoryRow, className)}
      classNameHeader={styles.categoryTitle}
      classNameContent={styles.features}
      key={title}
      header={title}
      defaultOpen={defaultExpanded}
      onToggle={onToggle}
    >
      {features.map((props) => (
        <div className={styles.featureRow} key={props.title}>
          <div className={styles.featureTitle}>
            <FeatureLabel title={props.title} description={props.description}>
              <div className={styles.mobileActiveValue}>
                {renderActiveValue(props.limits)}
              </div>
            </FeatureLabel>
          </div>
          {props.limits.map(({ plan, value }) => (
            <div key={`${plan}-${value}`} className={styles.featureLimit}>
              {renderValue(value)}
            </div>
          ))}
        </div>
      ))}
    </AccordionItem>
  );
}
