import { gsap } from 'gsap';

import { EaseType } from '~/utils/singletons/Easing';

export const animateIn = (
  $element: HTMLButtonElement | HTMLDivElement | null,
  $logo: HTMLElement,
  config?: { delay?: number; onStart?: () => void; onComplete?: () => void },
) => {
  if (!$element) return;
  const tl = gsap.timeline({ ...config });

  tl.set($logo, {
    opacity: 1,
  });

  tl.fromTo(
    $element,
    { '--hover-progress': 0 },
    {
      '--hover-progress': 1,
      duration: 0.35,
      ease: EaseType.DEFAULT,
    },
  );
  tl.fromTo(
    $element,
    { '--scale-y': 0.1, '--inner-scale-y': 2 },
    {
      '--scale-y': 1,
      '--inner-scale-y': 1,
      duration: 0.3,
      ease: EaseType.DEFAULT,
    },
    '<',
  );

  return tl;
};
export const animateOut = (
  $element: HTMLButtonElement | HTMLDivElement | null,
  $logo: HTMLElement,
  config?: { delay?: number; onStart?: () => void; onComplete?: () => void },
  animateLogo?: boolean,
) => {
  if (!$element) return;

  const tl = gsap.timeline();

  if (animateLogo) {
    tl.to($logo, {
      opacity: 0,
      duration: 0.1,
    });
  }
  tl.to($element, {
    '--hover-progress': 0,
    '--scale-y': 0.5,
    '--inner-scale-y': 2,
    duration: 0.3,
    ease: EaseType.DEFAULT,
    overwrite: true,
    ...config,
  });
  return tl;
};
