const calculateIndex = (offsets: number[], scrollY: number) => {
  const distance = (a: number) => Math.abs(a - scrollY);

  let closestIndex = 0;
  let closestDistance = distance(offsets[0]);

  for (let i = 1; i < offsets.length; i++) {
    const currentDistance = distance(offsets[i]);

    if (currentDistance < closestDistance) {
      closestIndex = i;
      closestDistance = currentDistance;
    }
  }
  return closestIndex;
};

export { calculateIndex };
