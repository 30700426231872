import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export type OrganicMosaicMediaItem = CMSEnhancedMedia<'media'> & {
  normalizedHeight: number;
  _key: string;
};
export type OrganicMosaicProps = CMSModuleBase & {
  medias: OrganicMosaicMediaItem[];
  sortedItemsDesktop: OrganicMosaicMediaItem[][];
  sortedItemsMobile: OrganicMosaicMediaItem[][];
};

export const NUM_COLS_MOBILE = 3;
export const NUM_COLS_DESKTOP = 5;

// these values will be calculated as %
export const COLUMN_PARALLAX_AMOUNT = 37;

// using px value here to ensure that the vertical gap between the items is consistent
export const ITEM_PARALLAX_AMOUNT = 60;
