const textPlaneFragment = `
precision mediump float;

// Texturing uniform
uniform sampler2D uTexture;

// Varying
varying vec2 vUv;

void main() {
    // Sample the texture with the UV coordinates
    gl_FragColor = texture2D(uTexture, vUv);
}
`;

export default textPlaneFragment;
