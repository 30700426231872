import { gsap } from 'gsap';
import { RefObject } from 'react';

export const heroAnimation = ({
  $overlay,
  $background,
}: {
  $overlay: RefObject<HTMLDivElement>;
  $background: RefObject<HTMLElement>;
}) => {
  const tl = gsap.timeline({ paused: true });
  if ($background.current) {
    tl.fromTo(
      $background.current,
      {
        scale: 1,
      },
      {
        scale: 1.08,
        ease: 'none',
      },
      0,
    );
  }

  if ($overlay.current) {
    tl.fromTo(
      $overlay.current,
      {
        opacity: 0,
      },
      {
        opacity: 0.9,
      },
      0,
    );
  }

  return tl;
};

export const coveredHeroAnimation = ({
  $overlay,
  $background,
  $textContainer,
}: {
  $overlay: RefObject<HTMLDivElement>;
  $background: RefObject<HTMLElement>;
  $textContainer: RefObject<HTMLDivElement>;
}) => {
  const tl = gsap.timeline({ paused: true });
  if ($background.current) {
    tl.fromTo(
      $background.current,
      {
        scale: 1,
      },
      {
        scale: 1.08,
        ease: 'none',
      },
      0,
    );
  }

  if ($textContainer.current) {
    tl.fromTo(
      $textContainer.current,
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 0,
        y: 80,
      },
      0,
    );
  }

  if ($overlay.current) {
    tl.fromTo(
      $overlay.current,
      {
        opacity: 0,
      },
      {
        opacity: 0.5,
      },
      0,
    );
  }

  return tl;
};
