'use client';

import { gsap } from 'gsap';
import { CSSProperties, useEffect, useRef, useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';
import { EaseType } from '~/utils/singletons/Easing';

import BentoCard from './BentoCard/BentoCard';
import styles from './BentosV4.module.css';
import { BentoProps } from './BentosV4.types';

const UIStore = useUIStore;

const BentosV4 = (props: BentoProps) => {
  const { cards, className, bentosLayout } = props;
  const $cardWrappers = useRef<HTMLDivElement[]>(Array(cards.length));
  const $cards = useRef<HTMLDivElement[]>(Array(cards.length));
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  const breakpoint = useUIStore((state) => state.breakpoint);
  const [shouldBentoAnimate, setShouldBentoAnimate] = useState(false);

  useEffect(() => {
    const isBpGreaterThanMobile = isBreakpointOrGreater(breakpoint, 'md');
    if (isBpGreaterThanMobile) {
      setShouldBentoAnimate(true);
    } else {
      setShouldBentoAnimate(false);
    }
  }, [breakpoint]);

  useEffect(() => {
    if (!shouldBentoAnimate) return;
    const options = {
      root: null,
      rootMargin: `-25px 0px`,
      threshold: 0,
    };

    const intersectionCallback: IntersectionObserverCallback = (entries) => {
      const intersectedEntries: IntersectionObserverEntry[] = [];
      entries.forEach((entry) => {
        // create timeline
        if (entry.intersectionRatio > 0) {
          intersectedEntries.push(entry);
          observer.unobserve(entry.target);
        }
      });

      if (intersectedEntries.length > 0) {
        const timeline = gsap.timeline();

        //  sort bentos, priorities by size first
        //  if same size then sort by proximity to the middle of the screen so the the center bento get animated first
        intersectedEntries.sort((entryA, entryB) => {
          const diff =
            entryA.boundingClientRect.width - entryB.boundingClientRect.width;
          if (diff !== 0) {
            return (
              entryB.boundingClientRect.width - entryA.boundingClientRect.width
            );
          } else {
            const screenCenter =
              (UIStore.getState().windowWidth || window.innerWidth) / 2;
            const rightA = Math.abs(
              screenCenter - entryA.boundingClientRect.right,
            );
            const rightB = Math.abs(
              screenCenter - entryB.boundingClientRect.right,
            );

            return rightA - rightB;
          }
        });

        // animate card instead of wrapper
        const elements = intersectedEntries.map((entry) => {
          const target = entry.target as HTMLDivElement;
          if (target.dataset.index) {
            const index = parseInt(target.dataset.index);
            return $cards.current[index];
          }
        });

        timeline.to(
          elements,
          {
            opacity: 1,
            duration: 0.25,
            ease: EaseType.DEFAULT,
            stagger: 0.05,
          },
          0,
        );
        timeline.to(
          elements,
          {
            y: 0,
            duration: 0.75,
            ease: EaseType.DEFAULT,
            stagger: 0.05,
          },
          0,
        );

        timeline.play();
      }
    };
    const observer = new IntersectionObserver(intersectionCallback, options);
    const cardWrappers = $cardWrappers.current;
    // observe card wrapper so that the initial translate on the cards get taken into account for the interesection
    cardWrappers.forEach((cardWrapper) => {
      if (cardWrapper) observer.observe(cardWrapper);
    });

    return () => {
      cardWrappers.forEach((cardWrapper) => {
        if (cardWrapper) observer.unobserve(cardWrapper);
      });
    };
  }, [shouldBentoAnimate]);

  return (
    <ModuleWrapper className={cn(styles.bentos, className)} {...props}>
      <Observer
        className={cn(styles.cardsGrid)}
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
      >
        {cards.map((card, index) => {
          const { column, row, bentoSize } = bentosLayout.layout[index];
          return (
            <div
              className={styles.bentoCardWrapper}
              key={index}
              data-index={index}
              style={
                {
                  '--bento-column': column,
                  '--bento-row': row,
                } as CSSProperties
              }
              ref={(element) =>
                $cardWrappers &&
                element &&
                addToRefArray({ element, refArray: $cardWrappers, index })
              }
            >
              <BentoCard
                ref={(element) =>
                  $cards &&
                  element &&
                  addToRefArray({ element, refArray: $cards, index })
                }
                {...card}
                column={column}
                row={row}
                bentoSize={bentoSize}
                isInView={!!isInView}
                className={shouldBentoAnimate ? styles.animatedBento : ''}
              />
            </div>
          );
        })}
      </Observer>
    </ModuleWrapper>
  );
};

export default BentosV4;
