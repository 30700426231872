import { breakpoints } from '@frameio-bs/tokens';

import { Breakpoint } from '~/types';

import { GlowBreakpoints, GlowShapeConfig } from '../Glow.types';

const getCurrentAvailableBreakpoint = (
  BreakpointKeyedObject: Record<GlowBreakpoints, number | GlowShapeConfig>,
  currentBreakpoint: Breakpoint | null,
  windowWidth: number | null,
) => {
  const configBreakpoints = Object.keys(BreakpointKeyedObject).map((bp) =>
    breakpoints.find((b) => b.name === bp),
  );
  // check if breakpoint config exists
  let breakpoint = configBreakpoints.find(
    (configBp) => configBp === currentBreakpoint,
  );
  // Otherwise find closest based on viewport width
  if (!breakpoint) {
    breakpoint = configBreakpoints.reduce((prev, curr) =>
      curr &&
      prev &&
      windowWidth &&
      Math.abs(curr.min - windowWidth) < Math.abs(prev.min - windowWidth)
        ? curr
        : prev,
    );
  }

  return breakpoint;
};
export default getCurrentAvailableBreakpoint;
