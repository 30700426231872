import { gsap } from 'gsap';
import { RefObject } from 'react';

import { EaseType } from '~/utils/singletons/Easing';
import { tickerAddOnce } from '~/utils/ticker';

import { EnterTimelineObject } from './InteractiveQuotesLgModalContent.types';

export const modalEnterTimeline = ({
  carouselIndex,
  $dummy,
  $shadow,
  $modalContent,
  $modalControls,
  $closeButton,
  $carouselItems,
  sourceRect,
}: {
  carouselIndex: number | undefined;
  $dummy: RefObject<HTMLDivElement>;
  $shadow: RefObject<HTMLDivElement>;
  $modalContent: RefObject<HTMLDivElement>;
  $modalControls: RefObject<HTMLDivElement>;
  $closeButton: RefObject<HTMLButtonElement>;
  $carouselItems: RefObject<HTMLLIElement[]>;
  sourceRect: DOMRect;
}) => {
  return new Promise<EnterTimelineObject>((resolve) => {
    tickerAddOnce(() => {
      const tl = gsap.timeline({ ease: EaseType.BASIC_BUTTER });
      const dummyEl = $dummy.current;

      const delay = 0.2;

      if (dummyEl) {
        const rect: DOMRect = dummyEl.getBoundingClientRect();
        const ratioX = sourceRect.width / rect.width;
        const ratioY = sourceRect.height / rect.height;
        // IN animation
        // Set to the dummy's size first, for correct sizing (& have the right image size)
        tl.set(
          [$modalContent.current, $shadow.current],
          {
            '--width': rect.width + 'px',
            '--height': rect.height + 'px',
            x: sourceRect?.x,
            y: sourceRect?.y,
            scaleX: 1,
            scaleY: 1,
            opacity: 0,
          },
          0,
        );
        // then scale it to match the hover card size
        tl.set(
          [$modalContent.current, $shadow.current],
          {
            scaleX: ratioX,
            scaleY: ratioY,
            opacity: 1,
          },
          // Delay of the animation is not directly added to the timeline itself as we want to set the correct width/height first
          `>+${delay}`,
        );

        //  animate from card position to center
        tl.fromTo(
          $closeButton.current,
          {
            scale: 1 / ratioX,
            // Magic numbers to fix offsets while scaling
            x: -8,
            y: -1,
            '--rotate': 0,
          },
          {
            scale: 1,
            x: 0,
            y: 0,
            '--rotate': 45,
          },
          '<',
        );
        tl.to(
          [$modalContent.current, $shadow.current],
          {
            x: rect.x,
            y: rect.y,
            scaleX: 1,
            scaleY: 1,
            duration: 0.5,
            delay: 0.1,
            ease: EaseType.BASIC_BUTTER,
          },
          '<',
        );
        tl.to(
          [$shadow.current],
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.1,
            ease: EaseType.BASIC_BUTTER,
          },
          '<+0.3',
        );
        tl.fromTo(
          $modalControls.current,
          {
            y: '100%',
          },
          {
            y: 0,
          },
        );
        tl.fromTo(
          $modalControls.current,
          {
            '--controls-progress': 0,
          },
          {
            '--controls-progress': 1,
          },
          '<+0.1',
        );
        tl.fromTo(
          $modalContent.current,
          {
            '--shadow-opacity': 0,
          },
          {
            '--shadow-opacity': 1,
          },
          '<',
        );
        if (
          carouselIndex !== undefined &&
          $carouselItems.current &&
          $carouselItems.current[carouselIndex]
        ) {
          tl.fromTo(
            $carouselItems.current[carouselIndex],
            {
              '--item-progress': 0,
            },
            {
              '--item-progress': 1,
            },
            '<',
          );
        }
      }

      //  have to wrap it in an object otherwise, TL gets played automatically before getting nested in a parent timeline
      resolve({ timeline: tl });
    }, true);
  });
};

export const modalLeaveTimeline = ({
  $modalContent,
  $shadow,
  onStart,
  onComplete,
}: {
  $modalContent: RefObject<HTMLDivElement>;
  $shadow: RefObject<HTMLDivElement>;
  onStart?: () => void;
  onComplete?: () => void;
}) => {
  const tween = gsap.to([$modalContent.current, $shadow.current], {
    scale: 0.9,
    opacity: 0,
    transformOrigin: 'center',
    duration: 0.4,
    ease: EaseType.BASIC_BUTTER,
    onStart,
    onComplete,
  });

  return tween;
};
