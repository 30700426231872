'use client';
import { gsap } from 'gsap';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import SideBySide from '~/components/molecules/SideBySide/SideBySide';
import { SideBySideRef } from '~/components/molecules/SideBySide/SideBySide.types';
import useUIStore from '~/state/ui';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';

import styles from './StackedSideBySideWrapper.module.css';
import {
  StackedSideBySideWrapperProps,
  StackedSideBySideWrapperRef,
} from './StackedSideBySideWrapper.types';
import {
  enterProgressTransformer,
  leaveProgressTransformer,
  nextProgressTransformer,
} from './StackedSideBySideWrapper.utils';

const StackedSideBySideWrapper = (
  {
    index,
    content,
    contentWidth,
    total,
    layout,
    logo,
    linkTo,
    media,
    className,
    active,
    isGlowHidden = true,
  }: StackedSideBySideWrapperProps,
  ref: ForwardedRef<StackedSideBySideWrapperRef>,
) => {
  const sideBySideRef = useRef<SideBySideRef>(null);
  const $element = useRef<HTMLDivElement>(null);
  const enterProgressSetter = useRef<(value: number) => void>();
  const leaveProgressSetter = useRef<(value: number) => void>();
  const nextProgressSetter = useRef<(value: number) => void>();

  const animation = useRef<GSAPTimeline>();

  const windowWidth = useUIStore((state) => state.windowWidth);
  const windowHeight = useUIStore((state) => state.windowHeight);
  const breakpoint = useUIStore((state) => state.breakpoint);

  const isComputedStyleComplete = useUIStore(
    (state) => state.isComputedStyleComplete,
  );

  const updateVideoState = useCallback(
    (progress: number, isInView: boolean) => {
      if (media.media.sanityMedia?.mediaType === 'video') {
        const mediaRef = sideBySideRef.current?.mediaRef;
        if (mediaRef && mediaRef.current && 'pause' in mediaRef.current) {
          if (1 - progress < 0.15 || !isInView) {
            mediaRef.current.pause();
          } else {
            mediaRef.current.play();
          }
        }
      }
    },
    [media.media.sanityMedia?.mediaType],
  );

  useImperativeHandle(
    ref,
    () => ({
      get $element() {
        return $element;
      },
      setProgress(progress: number, isInView: boolean) {
        if (enterProgressSetter.current)
          enterProgressSetter.current(enterProgressTransformer(progress));
        if (leaveProgressSetter.current)
          leaveProgressSetter.current(leaveProgressTransformer(progress));

        const nextProgress = nextProgressTransformer(progress);
        if (nextProgressSetter.current)
          nextProgressSetter.current(nextProgress);
        updateVideoState(nextProgress, isInView);
      },
      setCarouselProgress(progress: number) {
        animation.current?.progress(progress);
      },
      getRect() {
        return $element.current?.getBoundingClientRect();
      },
    }),
    [updateVideoState],
  );

  useLayoutEffect(() => {
    enterProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-enter-progress',
    ) as (value: number) => void;
    leaveProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-leave-progress',
    ) as (value: number) => void;
    nextProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-next-progress',
    ) as (value: number) => void;
  }, []);

  // TODO: shouldn't these be useEffect?
  useLayoutEffect(() => {
    const rect = $element.current?.getBoundingClientRect();
    if (rect)
      gsap.set($element.current, {
        '--top-offset': `${
          ((windowHeight || window.innerHeight) - rect.height) / 2
        }px`,
      });
  }, [windowWidth, windowHeight]);

  useLayoutEffect(() => {
    if (
      breakpoint &&
      isBreakpointOrGreater(breakpoint, 'lg') &&
      animation.current
    ) {
      // kill mobile animation if no longer on mobile
      animation.current.kill();
    }
  }, [breakpoint, isComputedStyleComplete, index]);

  // hide glow on `sm` breakpoint if user is dragging the slider and breakpoint is currently `sm`
  const isGlowHiddenSm =
    isGlowHidden && breakpoint && !isBreakpointOrGreater(breakpoint, 'lg');

  const isBpGreaterThanMobile = breakpoint
    ? isBreakpointOrGreater(breakpoint, 'md')
    : false;

  return (
    <div
      ref={$element}
      className={cn(
        className,
        styles.stackedSideBySideWrapper,
        active && styles.active,
        isGlowHiddenSm && styles.isGlowHiddenSm,
        index === 0 && styles.firstItem,
      )}
      style={
        {
          '--reverse-index': total - index - 1,
        } as CSSProperties
      }
    >
      {content && contentWidth && (
        <SideBySide
          ref={sideBySideRef}
          textContentType="richText"
          $wrapper={$element}
          innerClassNames={{
            content: styles.content,
            glowBackground: styles.glowBackground,
            glowWrapper: styles.glowWrapper,
            mediaContainer: styles.mediaContainer,
            mediaGlowContainer: styles.mediaGlowContainer,
            mediaGlowElement: styles.mediaGlowElement,
            shadowWrapper: styles.shadowWrapper,
          }}
          content={content}
          contentWidth={contentWidth}
          isParallaxDisabled={true}
          layout={layout}
          logo={logo}
          linkTo={linkTo}
          media={media}
          forceIsInView={true}
          willAutoplay={isBpGreaterThanMobile ? false : active}
        />
      )}
    </div>
  );
};

export default forwardRef(StackedSideBySideWrapper);
