import { Mesh, OGLRenderingContext, Plane, Program, Texture } from 'ogl';

import isNotSmall from '~/utils/isNotSmall';

import textPlaneFragment from './shaders/textPlaneFragment';
import textPlaneVertex from './shaders/textPlaneVertex';

/**
 * The object handling the content of the OGL WebGL scene. It takes an HTML text wrapping element,
 * letters elements, and draw them on a texture to match the text element exactly by using
 * the position of those individual letters relative to the wrapping element. This texture will
 * then be positioned relatively to match the position of the wrapping element compared to the
 * main OGL canvas element (the one rendered on the site)
 */
class OGLText {
  gl: OGLRenderingContext;
  plane: Mesh;
  $text: HTMLElement;
  $letters: HTMLElement[];
  openQuoteWidth: number;
  blockHasOpenQuote: boolean;
  adjustAscenderRatio: number;
  allowedLineEndSpace: number;
  pixelRatio: number;
  program: Program;

  textureCanvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D | null;
  texture: Texture;

  wrapperBoundingRect: DOMRect = new DOMRect();
  $parent: HTMLElement;
  parentBoundingRect: DOMRect = new DOMRect();

  constructor({
    gl,
    $letters,
    adjustAscenderRatio = 0.1,
    allowedLineEndSpace = 0.5,
    $text,
    openQuoteWidth,
    blockHasOpenQuote,
    pixelRatio,
    $parent,
  }: {
    gl: OGLRenderingContext;
    $parent: HTMLElement;
    $text: HTMLElement;
    $letters: HTMLElement[];
    adjustAscenderRatio?: number;
    allowedLineEndSpace?: number;
    pixelRatio: number;
    openQuoteWidth: number;
    blockHasOpenQuote: boolean;
  }) {
    this.gl = gl;
    this.$text = $text;
    this.pixelRatio = pixelRatio * 2;

    this.$letters = $letters;
    this.adjustAscenderRatio = adjustAscenderRatio;
    this.allowedLineEndSpace = allowedLineEndSpace;

    this.$parent = $parent;

    this.openQuoteWidth = openQuoteWidth;
    this.blockHasOpenQuote = blockHasOpenQuote;

    this.textureCanvas = document.createElement('canvas');

    this.context = this.textureCanvas.getContext('2d');

    this.texture = new Texture(gl, {
      minFilter: gl.LINEAR,
      magFilter: gl.LINEAR,
      anisotropy: 1,
    });

    this.program = new Program(gl, {
      vertex: textPlaneVertex,
      fragment: textPlaneFragment,
      uniforms: {
        uTexture: { value: this.texture },
      },
    });

    this.plane = new Mesh(gl, {
      program: this.program,
    });

    this.setRects();

    this.setTextureSize();

    this.setGeometry();

    this.setPlanePosition();

    this.writeText();
  }

  /***
   Sets our canvas element sizes and scales
  ***/
  setRects() {
    this.wrapperBoundingRect = this.$text.getBoundingClientRect();
    this.parentBoundingRect = this.$parent.getBoundingClientRect();
  }

  setTextureSize() {
    const isMdOrLarger = isNotSmall();
    const openQuoteWidth = isMdOrLarger ? this.openQuoteWidth : 0;
    // set sizes
    this.textureCanvas.width =
      (this.wrapperBoundingRect.width + openQuoteWidth * 2) * this.pixelRatio;

    // We make the canvas slightly taller to accomodate the descending letters overflowing
    this.textureCanvas.height =
      this.wrapperBoundingRect.height * 1.05 * this.pixelRatio;

    this.texture.width = this.textureCanvas.width;
    this.texture.height = this.textureCanvas.height;
  }

  setGeometry() {
    const geometry = new Plane(this.gl, {
      width: this.textureCanvas.width / this.pixelRatio,
      height: this.textureCanvas.height / this.pixelRatio,
    });

    this.plane.geometry = geometry;
  }

  setPlanePosition() {
    this.plane.position.y =
      this.parentBoundingRect.height / 2 -
      this.textureCanvas.height / this.pixelRatio / 2 +
      this.parentBoundingRect.top -
      this.wrapperBoundingRect.top;

    this.plane.position.x =
      -this.parentBoundingRect.width / 2 +
      (this.wrapperBoundingRect.left - this.parentBoundingRect.left) +
      this.wrapperBoundingRect.width / 2;
  }

  writeText() {
    if (this.context && this.pixelRatio && this.texture && this.$text) {
      this.context.clearRect(
        0,
        0,
        this.textureCanvas.width,
        this.textureCanvas.height,
      );

      let firstLetterRect;

      for (const letter of this.$letters) {
        const style = getComputedStyle(letter);
        // first, apply the correct styles
        this.context.fillStyle = style.color;
        this.context.strokeStyle = style.color;

        this.context.font =
          style.fontStyle +
          ' ' +
          style.fontWeight +
          ' ' +
          parseFloat(style.fontSize) * this.pixelRatio +
          'px ' +
          style.fontFamily;

        const lineHeight = parseFloat(style.lineHeight) * this.pixelRatio;
        const fontSize = parseFloat(style.fontSize) * this.pixelRatio;

        // start at the right position
        this.context.textBaseline = 'hanging';

        // top position needs to be adjusted based on line height and font size

        const lineHeightRatio = lineHeight / fontSize;

        // if the textlock up includes the open quote mark, the rendered letters' x position will be calculated based on the x position of the quote mark, which overflows the text block on the left side. therefore, the block that includes the mark will start at x = 0, and blocks in the same instance that don't have the quote mark will need to have some extra space applied on the left side.
        const offsetX = this.blockHasOpenQuote ? 0 : this.openQuoteWidth;

        let offsetY = fontSize * 0.09 + (lineHeightRatio - 1) * fontSize * 0.5;

        // safari seems to handle this differently!
        if (
          navigator.userAgent.indexOf('Safari') !== -1 &&
          navigator.userAgent.indexOf('Chrome') === -1
        ) {
          offsetY = ((lineHeightRatio - 1.2) * fontSize) / 2;
        }

        const letterRect = letter.getBoundingClientRect();

        if (typeof firstLetterRect === 'undefined') {
          firstLetterRect = letterRect;
        }

        let text = letter.textContent;

        if (text) {
          if (style.textTransform === 'uppercase') {
            text = text.toUpperCase();
          } else if (style.textTransform === 'lowercase') {
            text = text.toLowerCase();
          }

          // now we're gonna write the text on the canvas
          // write the text
          const isMdOrLarger = isNotSmall();

          const x = isMdOrLarger
            ? (letterRect.x - firstLetterRect.x + offsetX) * this.pixelRatio
            : (letterRect.x -
                firstLetterRect.x +
                firstLetterRect.x -
                this.wrapperBoundingRect.x) *
              this.pixelRatio;

          const y =
            (letterRect.y - firstLetterRect.y) * this.pixelRatio + offsetY;

          this.context.fillText(text, x, y);
        }
      }

      let retries = 0;
      const updateImage = () => {
        if (this.context) {
          try {
            this.texture.image = this.context.getImageData(
              0,
              0,
              this.textureCanvas.width,
              this.textureCanvas.height,
            ).data;
          } catch (e) {
            console.error('Failed to create texture:', e);

            if (retries < 20) {
              retries++;
              updateImage();
            }
          }
        }
      };

      updateImage();

      // update the texture to send the newly written canvas to the GPU
      this.texture.needsUpdate = true;
    }
  }

  resize() {
    this.setRects();

    this.setTextureSize();

    this.setGeometry();

    this.setPlanePosition();

    this.writeText();
  }
}

export default OGLText;
