/**
 * Calculates the intersection progress of one element over another.
 * The progress is 0 when the first element just starts to cover the second
 * and reaches 1 when the first element is completely past the second.
 *
 * @param element1 - The first HTML element, which moves over the second element.
 * @param element2 - The second HTML element, which is covered by the first.
 * @returns The progress of the intersection as a number between 0 and 1.
 */
const getOverlap = ($element1: HTMLElement, $element2: HTMLElement): number => {
  const rect1 = $element1.getBoundingClientRect();
  const rect2 = $element2.getBoundingClientRect();

  return rect1.bottom - rect2.top;
};

export default getOverlap;
