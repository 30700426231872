import { gsap } from 'gsap';
import { RefObject } from 'react';

import { EaseType } from '~/utils/singletons/Easing';

import { DEFAULT_CARDS_DISPLAYED } from './CardGridEditorial.types';

export const showCardsAnimation = ({
  $cardGridWrapper,
  $cards,
  shortWrapperHeight,
  onComplete,
}: {
  $cardGridWrapper: RefObject<HTMLDivElement>;
  $cards: RefObject<HTMLAnchorElement[]>;
  shortWrapperHeight: number;
  onComplete: () => void;
}) => {
  const showTl = gsap.timeline({ onComplete });

  if ($cards.current) {
    showTl
      .fromTo(
        $cardGridWrapper.current,
        {
          height: shortWrapperHeight,
        },
        {
          height: 'auto',
          delay: 0.2,
          duration: 1,
          ease: EaseType.BASIC_BUTTER,
        },
      )
      .fromTo(
        $cards.current.slice(DEFAULT_CARDS_DISPLAYED),
        {
          y: 20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          visibility: 'visible',
          duration: 0.5,
          stagger: 0.1,
          ease: EaseType.EMBELLISHMENT,
        },
        '-=0.85',
      );
  }

  return showTl;
};

export const hideCardsAnimation = ({
  $cardGridWrapper,
  $cards,
  shortWrapperHeight,
  tallWrapperHeight,
  onComplete,
}: {
  $cardGridWrapper: RefObject<HTMLDivElement>;
  $cards: RefObject<HTMLAnchorElement[]>;
  shortWrapperHeight: number;
  tallWrapperHeight: number;
  onComplete: () => void;
}) => {
  const hideTl = gsap.timeline({
    onComplete,
  });
  if ($cards.current) {
    hideTl
      .to($cards.current.slice(DEFAULT_CARDS_DISPLAYED), {
        opacity: 0,
        y: 20,
        duration: 0.3,
        ease: EaseType.DEFAULT,
      })
      .fromTo(
        $cardGridWrapper.current,
        {
          height: tallWrapperHeight,
        },
        {
          height: shortWrapperHeight,
          duration: 1,
          ease: EaseType.EMBELLISHMENT,
        },
        '-=0.1',
      );
  }

  return hideTl;
};
