'use client';
import { gsap } from 'gsap';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import DeviceWrapper from '~/components/atoms/DeviceWrapper/DeviceWrapper';
import Graphic from '~/components/atoms/Graphic/Graphic';
import Observer from '~/components/atoms/Observer/Observer';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { textLockup5 } from '~/components/molecules/TextLockups/TextLockups.config';
import Touts from '~/components/molecules/Touts/Touts';
import UIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import ConditionalInteractiveWrapper from '../ConditionalInteractiveWrapper/ConditionalInteractiveWrapper';
import Glow from '../Glow/Glow';
import PortableText from '../PortableText/PortableText';
import MultiDeviceMediaContainer from './MultiDeviceMediaContainer/MultiDeviceMediaContainer';
import styles from './SideBySide.module.css';
import { SideBySideProps, SideBySideRef } from './SideBySide.types';

const intMaxParallax = parseInt(styles.maxParallax);
const intMaxParallaxSm = parseInt(styles.maxParallaxSm);

const SideBySideLockup = (
  props: SideBySideProps,
  ref: ForwardedRef<SideBySideRef>,
) => {
  const {
    className,
    innerClassNames,
    content,
    contentWidth,
    isParallaxDisabled,
    layout,
    linkTo,
    logo,
    media,
    touts,
    wrapInDevice,
    $wrapper,
    forceIsInView,
    willAutoplay,
  } = props;

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const isInDevice = typeof wrapInDevice !== 'undefined';

  const isParallaxing = !isInDevice && !isParallaxDisabled;

  const progressSetter = useRef<(value: number) => void>();

  const mediaRef = useRef<HTMLElement | VideoRef>(null);

  useLayoutEffect(() => {
    // We apply the translation value for the parallax effect only if it's not in a device
    if (mediaRef.current && !isInDevice) {
      let $element: HTMLElement | undefined;

      // The HTML element to update differs based on the type of media
      if ('$wrapper' in mediaRef.current && mediaRef.current.$wrapper.current) {
        $element = mediaRef.current.$wrapper.current;
      } else if (mediaRef.current instanceof HTMLElement) {
        $element = mediaRef.current;
      }

      // If we did find an HTML element to update, then we update it through GSAP
      if (typeof $element !== 'undefined' && 'media' in media) {
        progressSetter.current = gsap.quickSetter(
          '$wrapper' in mediaRef.current
            ? mediaRef.current.$wrapper.current
            : mediaRef.current,
          'y',
          'rem',
        ) as (value: number) => void;
      }
    }
  }, [isInDevice, media]);

  const onProgress = useCallback(
    (progress: number) => {
      if (progressSetter.current && isParallaxing) {
        const roundedProgress = Math.round(progress * 1000) / 1000;

        progressSetter.current(
          roundedProgress *
            (UIStore.getState().breakpoint?.name === 'sm'
              ? intMaxParallaxSm
              : intMaxParallax) *
            -1,
        );
      }
    },
    [isParallaxing],
  );

  useScrollProgress($wrapper, onProgress);

  useImperativeHandle(
    ref,
    () => ({
      mediaRef,
    }),
    [],
  );

  const options = textLockup5().options;

  return (
    <div
      className={cn(
        styles.sideBySide,
        layout === 'mediaLeftTextRight'
          ? styles.isTextRight
          : styles.isTextLeft,
        isInDevice && styles.isInDevice,
        !isInDevice && styles.isNotInDevice,
        isParallaxing && styles.parallax,
        logo && styles.hasLogo,
        touts && styles.hasTouts,
        className,
      )}
      {...(contentWidth
        ? { style: { '--content-width': contentWidth } as CSSProperties }
        : {})}
    >
      {/* render touts */}
      {touts && (
        <Touts className={styles.touts} {...touts} isColumnLayout={true} />
      )}
      {/* Wrapped in a desktop or no wrapper */}
      {wrapInDevice !== 'multi' && (
        <Observer
          className={cn(
            styles.mediaContainer,
            'asset' in media.media.sanityMedia,
            innerClassNames?.mediaContainer,
          )}
          callback={updateIsInView}
          options={{ rootMargin: '200% 0%' }}
        >
          <Glow
            backgroundClassName={cn(
              styles.glowBackground,
              innerClassNames?.glowBackground,
            )}
            className={cn(styles.media, innerClassNames?.glowWrapper)}
            source={media.glow}
          >
            <div
              className={cn(
                wrapInDevice === 'desktop'
                  ? styles.mediaWrappedDesktop
                  : styles.mediaNoWrapper,
              )}
            >
              {wrapInDevice ? (
                <>
                  <Shadow />
                  <DeviceWrapper
                    className={styles.desktopWrapper}
                    deviceType="studioDisplay"
                    isInView={isInView !== false}
                  />
                </>
              ) : ('asset' in media.media?.sanityMedia &&
                  media.media.sanityMedia.asset.isOpaque) ||
                media.media.sanityMedia.mediaType === 'video' ? (
                <Shadow
                  className={cn(styles.shadow, innerClassNames?.shadowWrapper)}
                />
              ) : null}
              {logo && (
                <Graphic
                  {...logo}
                  className={styles.logo}
                  isInView={isInView !== false}
                />
              )}
              <div
                className={cn(
                  styles.mediaElementWrapper,
                  innerClassNames?.mediaGlowContainer,
                )}
              >
                <Media
                  ref={mediaRef}
                  sanityMedia={media.media.sanityMedia}
                  className={cn(
                    styles.mediaElement,
                    innerClassNames?.mediaGlowElement,
                  )}
                  isDisplayed={isInView !== false}
                  fixedAspectRatio={true}
                  forceIsInView={forceIsInView}
                  willAutoplay={willAutoplay}
                />
              </div>
            </div>
          </Glow>
        </Observer>
      )}

      {/* Wrapped in multi devices */}
      {wrapInDevice === 'multi' && (
        <MultiDeviceMediaContainer
          {...innerClassNames}
          media={media}
          $wrapper={$wrapper}
        />
      )}

      {content && (
        <div
          className={cn(styles.contentWrapper, innerClassNames?.contentWrapper)}
        >
          <ConditionalInteractiveWrapper
            className={cn(styles.content, innerClassNames?.content)}
            linkTo={linkTo}
          >
            <PortableText value={content} options={options} />
          </ConditionalInteractiveWrapper>
        </div>
      )}
    </div>
  );
};

export default forwardRef<SideBySideRef, SideBySideProps>(SideBySideLockup);
