'use client';
import { useState } from 'react';

import { cn } from '~/utils';

import styles from './RadioButtons.module.css';
import { RadioButtonsProps } from './RadioButtons.types';

const RadioButtons = ({
  className = '',
  buttons,
  initialIndex = 0,
  onChange,
}: RadioButtonsProps) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const onButtonClick = (index: number) => {
    if (index !== activeIndex) {
      onChange(index);
      setActiveIndex(index);
    }
  };

  return (
    <div className={cn(styles.radioButtons, className)}>
      <ul className={styles.radioButtonItemsWrapper}>
        {buttons.map((button, index) => (
          <li
            className={cn(
              styles.radioButtonItem,
              activeIndex === index && styles.isActive,
            )}
            key={button._key}
            aria-label={button.value}
          >
            <button
              className={styles.radioButtonInner}
              onClick={() => onButtonClick(index)}
            >
              <figure className={styles.radioButtonIcon} />
              <span className={styles.radioButtonTitle}>{button.title}</span>
              {button.subtitle && (
                <span className={styles.radioButtonSubtitle}>
                  {button.subtitle}
                </span>
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RadioButtons;
