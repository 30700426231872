import { CMSGraphicProps } from '~/components/atoms/Graphic/Graphic.types';
import { PortableTextCustomValue } from '~/components/molecules/PortableText/PortableText.types';
import { MoleculeBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export const MOBILE_SIZE_TEXT_LIMIT = 70;

export type CMSToutsProps = {
  touts: Tout[];
} & (
  | {
      isTile: true;
      isMobileSlider: boolean;
    }
  | {
      isTile: false;
      isMobileSlider?: never;
    }
);

export type ToutsProps = MoleculeBase &
  CMSToutsProps & {
    isColumnLayout?: boolean;
    tallestWrapperHeight?: number;
  };

// individual tout
export type Tout = MoleculeBase & {
  content: PortableTextCustomValue;
  icon: CMSGraphicProps;
  graphicType?: 'logo' | 'icon';
};
