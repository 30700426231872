'use client';

import { useEffect, useRef, useState } from 'react';

import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn, getUniqueId } from '~/utils';

import LogoTileRow from './LogoTileRow/LogoTileRow';
import { LogoTileWallRow } from './LogoTileRow/LogoTileRow.types';
import styles from './LogoTileWall.module.css';
import { LogoTileWallProps, SPEEDS } from './LogoTileWall.types';

const LogoTileWall = (props: LogoTileWallProps) => {
  const { className, rows } = props;
  const $wrapper = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const element = $wrapper.current;
    const observer = new IntersectionObserver(
      (entries) => {
        setIsVisible(entries[0].isIntersecting);
      },
      {
        rootMargin: '50% 0%',
        threshold: 0,
      },
    );

    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <ModuleWrapper
      ref={$wrapper}
      className={cn(styles.logoTileWall, className)}
      {...props}
    >
      <div className={styles.container}>
        {rows.map((row: LogoTileWallRow, index: number) => (
          <LogoTileRow
            row={row}
            key={getUniqueId('row')}
            isInView={isVisible}
            speed={SPEEDS[index]}
          />
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default LogoTileWall;
