import { BreakpointName } from '~/types';

import { CMSPricingProduct, PricePeriodValue } from '../../Pricing.types';

export type PricingCardProps = {
  product: CMSPricingProduct;
  pricePeriod: PricePeriodValue;
};

export const EXPAND_BREAKPOINTS: BreakpointName[] = ['sm'];
