'use client';
import { gsap } from 'gsap';
import {
  CSSProperties,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import SvgPlusIcon from '~/assets/svg/plus-icon.svg';
import Graphic from '~/components/atoms/Graphic/Graphic';
import Image from '~/components/atoms/Image/Image';
import Observer from '~/components/atoms/Observer/Observer';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Glow from '~/components/molecules/Glow/Glow';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { dict } from '~/data/stores/Dictionary';
import useUIStore from '~/state/ui';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';
import { tickerAddOnce } from '~/utils/ticker';

import {
  InteractiveQuoteCard,
  InteractiveQuotesProps,
} from '../InteractiveQuotes.types';
import { animateIn, animateOut } from './InteractiveQuotesLg.animation';
import styles from './InteractiveQuotesLg.module.css';
import InteractiveQuotesLgModal from './InteractiveQuotesLgModal/InteractiveQuotesLgModal';

const InteractiveQuotesLg = ({
  content,
  quoteCards,
  className,
}: InteractiveQuotesProps) => {
  const $cards = useRef<HTMLDivElement[]>(Array(quoteCards.length));
  const $cardsInner = useRef<HTMLButtonElement[]>(Array(quoteCards.length));
  const $titles = useRef<HTMLButtonElement[]>(Array(quoteCards.length));
  const $logos = useRef<HTMLElement[]>(Array(quoteCards.length));
  const $list = useRef<HTMLUListElement>(null);
  const $dummy = useRef<HTMLDivElement>(null);

  const isAnimatingRef = useRef(false);

  const [currentCardRect, setCurrentCardRect] = useState<DOMRect>();
  const [hasInteractedSinceResize, setHasInteractedSinceResize] =
    useState<boolean>(false);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>();
  const listRect = useRef<DOMRect>();
  const preloadedImages = useRef<boolean[]>(
    Array(quoteCards.length).fill(false),
  );

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const mousePositionXSetter = useRef<(value: number) => void>();
  const mousePositionYSetter = useRef<(value: number) => void>();
  const windowWidth = useUIStore((state) => state.windowWidth);

  const handleHover = (index: number) => {
    if (index === currentIndex) return;

    setCurrentIndex(index);
    setHasInteractedSinceResize(true);
    setShowCard(true);
  };

  const handleMouseLeave = () => {
    setShowCard(false);
    setCurrentIndex(undefined);
  };

  const handleClick = (index: number) => {
    const target = $cardsInner.current[index] as HTMLButtonElement;
    tickerAddOnce(() => {
      if (isAnimatingRef.current) return;
      const rect = target.getBoundingClientRect();
      setShowModal(true);
      setCurrentIndex(index);
      setCurrentCardRect(rect);
    }, true);
  };

  const handleMouseMove = (event: MouseEvent<HTMLButtonElement>) => {
    if (listRect.current) {
      const max = listRect.current.width;
      const x = gsap.utils.mapRange(
        0,
        max,
        0,
        max / 1.5,
        event.clientX - listRect.current.x,
      );
      const y = gsap.utils.mapRange(
        0,
        max,
        0,
        max / 1.5,
        event.nativeEvent.offsetY,
      );
      if (mousePositionXSetter.current) mousePositionXSetter.current(x);
      if (mousePositionYSetter.current) mousePositionYSetter.current(y);
    }
  };

  const onClose = useCallback(() => {
    //  restore focus to the trigger element
    $titles.current[currentIndex || 0].focus();
    setShowModal(false);
  }, [currentIndex]);

  const handleKeyDown = (
    event: KeyboardEvent<HTMLButtonElement | HTMLLIElement>,
  ) => {
    if (isAnimatingRef.current) return;
    if (event.code === 'Enter') {
      setShowModal(true);
    }
  };

  useEffect(() => {
    $cards.current.forEach(($card, index) => {
      if (index === currentIndex) return;
      animateOut(
        $card,
        $logos.current[index],
        {
          // add delay to prevent flashing when opening modal
          delay: showModal ? 0.2 : 0,
        },
        showModal,
      );
    });

    if (currentIndex !== undefined) {
      animateIn($cards.current[currentIndex], $logos.current[currentIndex], {
        onStart: () => {
          isAnimatingRef.current = true;
        },
        onComplete: () => {
          isAnimatingRef.current = false;
        },
      });
    }
    // intentionally omitting showModal state because it creates double animation triggers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    // when resize, reset preload flags to false so that we trigger preload again
    preloadedImages.current.fill(false);
    setHasInteractedSinceResize(false);
    tickerAddOnce(() => {
      listRect.current = $list.current?.getBoundingClientRect();
    }, true);
  }, [windowWidth]);

  useLayoutEffect(() => {
    mousePositionXSetter.current = gsap.quickSetter(
      $list.current,
      '--mouse-x',
      'px',
    ) as (value: number) => void;
    mousePositionYSetter.current = gsap.quickSetter(
      $list.current,
      '--mouse-y',
      'px',
    ) as (value: number) => void;

    // Setting the expanded size to the hover card using
    // a dummy element that is sized just like the modal
    // so that we can load the larger image once in the card
    //(thanks to autResize={false} on the <Image/> component)
    gsap.set($list.current, {
      '--expanded-width': $dummy.current?.getBoundingClientRect().width + 'px',
    });
  }, []);

  return (
    <div
      className={cn(
        styles.interactiveQuotesLg,
        showModal && styles.modalOpen,
        hasInteractedSinceResize && styles.hasInteracted,
        className,
      )}
    >
      <div ref={$dummy} className={styles.dummy}></div>
      <PortableText
        value={content}
        className={styles.content}
        options={{
          block: {
            titles: {
              className: styles.titles,
            },
            accents: {
              className: styles.accents,
              eyebrow: {
                className: styles.eyebrow,
              },
            },
            bodies: {
              className: styles.accents,
            },
          },
        }}
      />
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
        className={styles.interactiveQuotesWrapper}
      >
        <ul
          ref={$list}
          className={styles.interactiveQuotes}
          onMouseLeave={handleMouseLeave}
        >
          {quoteCards.map((quoteCard: InteractiveQuoteCard, index: number) => (
            <li
              className={styles.interactiveQuote}
              style={{ '--index-interactive-quote': index } as CSSProperties}
              key={quoteCard._key}
              onMouseOver={() => handleHover(index)}
              onFocus={() => handleHover(index)}
            >
              {/* Title */}
              <button
                ref={(node: HTMLButtonElement) =>
                  ($titles.current[index] = node)
                }
                className={styles.interactiveQuoteTitleWrapper}
                onMouseMove={handleMouseMove}
                onClick={() => handleClick(index)}
                onKeyDown={handleKeyDown}
              >
                <p className={styles.interactiveQuoteTitle}>
                  {quoteCard.eyebrow}
                </p>
              </button>
              {/* Card */}
              <div
                ref={(node: HTMLDivElement) => ($cards.current[index] = node)}
                className={styles.interactiveQuoteCardWrapper}
              >
                <Glow
                  className={styles.interactiveQuoteGlowWrapper}
                  source={
                    quoteCard.image.glow && {
                      ...quoteCard.image.glow,
                    }
                  }
                >
                  <Shadow className={styles.interactiveQuoteShadowWrapper}>
                    <button
                      ref={(node: HTMLButtonElement) =>
                        ($cardsInner.current[index] = node)
                      }
                      className={cn(
                        styles.interactiveQuoteCard,
                        showCard && index === currentIndex && styles.active,
                      )}
                      onKeyDown={handleKeyDown}
                      aria-label={dict('clickToViewTestimonial')}
                    >
                      <EnhancedMedia
                        overlay={quoteCard.image.overlay}
                        className={styles.interactiveQuoteMedia}
                      >
                        <Image
                          className={styles.interactiveQuoteMediaImage}
                          source={quoteCard.image.image}
                          isDisplayed={isInView !== false}
                          autoResize={false}
                          fixedAspectRatio={true}
                        />
                      </EnhancedMedia>

                      {quoteCard.logo && (
                        <Graphic
                          ref={(node: HTMLDivElement) =>
                            ($logos.current[index] = node)
                          }
                          className={styles.interactiveQuoteLogo}
                          source={quoteCard.logo.source}
                          isInView={true}
                          name={quoteCard.logo.name}
                        />
                      )}

                      <div data-index={index} className={styles.moreButton}>
                        <SvgPlusIcon className={styles.buttonIcon} />
                      </div>
                    </button>
                  </Shadow>
                </Glow>
              </div>
            </li>
          ))}
        </ul>
        <ul
          className={cn(
            styles.interactiveQuotes,
            styles.interactiveQuotesGradients,
            showCard && styles.hidden,
          )}
          aria-hidden="true"
        >
          {quoteCards.map((quoteCard: InteractiveQuoteCard) => (
            <li className={styles.interactiveQuote} key={quoteCard._key}>
              <p className={styles.interactiveQuoteTitle}>
                {quoteCard.eyebrow}
              </p>
            </li>
          ))}
        </ul>
      </Observer>
      {showModal && currentCardRect && (
        <InteractiveQuotesLgModal
          currentIndex={currentIndex}
          sourceRect={currentCardRect}
          quoteCards={quoteCards}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default InteractiveQuotesLg;
