/**
 * Calculates the distance between two points in a 2D plane.
 *
 * @param x1 - The x-coordinate of the first point.
 * @param y1 - The y-coordinate of the first point.
 * @param x2 - The x-coordinate of the second point.
 * @param y2 - The y-coordinate of the second point.
 * @returns The distance between the two points.
 *
 * @example
 * ```typescript
 * // Calculate the distance between the points (0,0) and (3,4)
 * const distance = distance(0, 0, 3, 4);
 * console.log(distance); // Output will be 5
 * ```
 */
const distance = (x1: number, y1: number, x2: number, y2: number): number => {
  const xDistance = x2 - x1;
  const yDistance = y2 - y1;
  return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
};

export default distance;
