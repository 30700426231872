import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export enum WrapInDeviceOptions {
  studioDisplay = 'desktop',
  macbookPro = 'laptop',
}

export type StandaloneMediaProps = CMSModuleBase & {
  name: string;
  mobileAlignment?: 'left' | 'right' | 'center';
  wrapInDevice?: WrapInDeviceOptions;
  media: CMSEnhancedMedia<'media'>;
};
