'use client';
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import useStateRef from 'react-usestateref';

import useUIStore from '~/state/ui';
import { cn } from '~/utils';
import { tickerAddOnce } from '~/utils/ticker';

import styles from './Glow.module.css';
import { GlowProps, GlowRef } from './Glow.types';
import GlowWebglFactory from './helpers/GlowWebglFactory';

/**
 * Glow module
 * @param backgroundClassName classname applied to the glow in the background
 * @param source GlowSource, contains glow colors, shapeType & shapes config.
 * @param debug (Optional), disable blur on true, and hides the content, to highlight the glow.
 */
const Glow = (
  {
    className,
    backgroundClassName,
    children,
    style,
    source: sourceProps,
    shouldGenerate = true,
    debug = false,
  }: GlowProps,
  ref: ForwardedRef<GlowRef>,
) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $background = useRef<HTMLDivElement>(null);
  const $canvas = useRef<HTMLCanvasElement>(null);

  const breakpoint = useUIStore((state) => state.breakpoint);
  const webglDisabled = useUIStore((state) => state.webglDisabled);

  const [isVisible, setIsVisible] = useState(false);

  const id = useId();

  const [isReady, setIsReady] = useState<boolean>(false);

  const [hasRendered, setHasRendered, hasRenderedRef] =
    useStateRef<boolean>(false);
  const shouldRender = useRef(true);

  const setIsReadyIfDisplayed = useCallback((isReady: boolean) => {
    tickerAddOnce(() => {
      if ($wrapper.current) {
        const isDisplayed =
          getComputedStyle($wrapper.current, null).display !== 'none';

        if (isDisplayed) {
          setIsReady(isReady);
        }
      }
    }, true);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        $wrapper,
        startGlow: () => {
          setIsReadyIfDisplayed(true);
        },
      };
    },
    [setIsReadyIfDisplayed],
  );

  const requestGlow = useCallback(() => {
    if (!$canvas.current || !sourceProps || !shouldGenerate) {
      return;
    }

    const context = $canvas.current.getContext('2d');

    if (context) {
      let rect: DOMRect | undefined;

      const measureGlowCanvas = () => {
        rect = $canvas.current?.getBoundingClientRect();
      };
      tickerAddOnce(measureGlowCanvas, true);
      const requestGlowFromFactory = () => {
        if ($wrapper.current && $canvas.current && rect) {
          $canvas.current.width = rect.width;
          $canvas.current.height = rect.height;
          GlowWebglFactory.requestGlow({
            $wrapper: $wrapper.current,
            params: sourceProps,
            targetCanvasContext: context,
            windowWidth: window.innerWidth,
            breakpoint,
            debug,
          });
          shouldRender.current = false;
          setHasRendered(true);
        }
      };
      tickerAddOnce(requestGlowFromFactory);
    }
  }, [breakpoint, sourceProps, debug, shouldGenerate, setHasRendered]);

  useEffect(() => {
    if (isReady && shouldRender.current && !hasRendered) {
      requestGlow();
    }

    return () => {
      shouldRender.current = true;
    };
  }, [isReady, hasRendered, requestGlow]);

  useEffect(() => {
    //  trigger re-render of glows on breakpoint change
    setHasRendered(false);
    setIsReady(false);
    if (isVisible) {
      requestGlow();
    }
  }, [breakpoint, isVisible, setHasRendered, requestGlow]);

  useEffect(() => {
    let unsubscribe: undefined | (() => void);
    async function initIntersectionObserver() {
      if ($background.current === null || $canvas.current === null) return;
      $background.current.setAttribute('data-id', id);
      const Observer = (
        await import('./helpers/glowIntersectionObserverSingleton')
      ).default;
      unsubscribe = Observer.subscribe($background.current, id, {
        isVisible: () => {
          setIsVisible(true);
          if (!hasRenderedRef.current) {
            // Trigger request for glow if first time enters the viewport
            setIsReady(true);
          }
        },
        isHidden: () => {
          if (hasRenderedRef.current) {
            setIsVisible(false);
          }
        },
      });
    }
    initIntersectionObserver();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // We want to avoid any rerun for this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={$wrapper}
      className={cn(styles.glowWrapper, className)}
      style={style}
    >
      <div
        ref={$background}
        className={cn(
          styles.glowBackground,
          backgroundClassName,
          hasRendered && styles.displayed,
          !children && styles.baked,
        )}
      >
        <canvas
          className={cn(styles.glowCanvas)}
          ref={$canvas}
          style={{
            display: isVisible && !webglDisabled ? 'block' : 'none',
          }}
        ></canvas>
      </div>
      {debug ? <div style={{ opacity: 0 }}>{children}</div> : children}
    </div>
  );
};

export default forwardRef<GlowRef, GlowProps>(Glow);
