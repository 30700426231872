import { gsap } from 'gsap/all';
import { RefObject } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import { EaseType } from '~/utils/singletons/Easing';

import styles from './FloatingActionButton.module.css';

const videoWidth = styles.v_videoWidth;
const horizontalPadding = styles.v_horizontalPadding;
const initialWidth = parseFloat(videoWidth) + parseFloat(horizontalPadding) * 2;

export const closeVideoAnimation = ({
  $video,
  $modalBackground,
  $modalVideoWrapper,
  $modalPlaceholder,
  $modalCloseButton,
  callback,
}: {
  $video: VideoRef;
  $modalPlaceholder: RefObject<HTMLElement>;
  $modalBackground: RefObject<HTMLElement>;
  $modalVideoWrapper: RefObject<HTMLElement>;
  $modalCloseButton: RefObject<HTMLElement>;
  callback: () => void;
}) => {
  const tl = gsap.timeline();

  tl.set($modalPlaceholder.current, {
    clearProps: 'pointerEvents',
  });

  tl.add($video.muteSmoothly);

  tl.to(
    [$modalBackground.current, $modalCloseButton.current],
    {
      opacity: 0,
    },
    0,
  );

  tl.to(
    $modalVideoWrapper.current,
    {
      opacity: 0,
      scale: 0.95,
      duration: 0.2,
    },
    0,
  );

  tl.add(callback);
};

export const openVideoAnimation = ({
  $video,
  $modalBackground,
  $modalVideoWrapper,
  $modalPlaceholder,
  $modalCloseButton,
}: {
  $video: VideoRef;
  $modalPlaceholder: RefObject<HTMLElement>;
  $modalBackground: RefObject<HTMLElement>;
  $modalVideoWrapper: RefObject<HTMLElement>;
  $modalCloseButton: RefObject<HTMLElement>;
}) => {
  gsap.killTweensOf($video.$wrapper.current);

  const tl = gsap.timeline();

  tl.to([$modalBackground.current, $modalCloseButton.current], {
    opacity: 1,
  });

  tl.to(
    $modalVideoWrapper.current,
    {
      scale: 1,
      opacity: 1,
    },
    0,
  );

  tl.set($modalPlaceholder.current, {
    pointerEvents: 'all',
  });
};

export const show = ({
  $element,
  $label,
}: {
  $element: HTMLElement;
  $label: HTMLDivElement;
}) => {
  gsap.killTweensOf($element);
  const tl = gsap.timeline({
    onComplete: () => {
      // clear overflow so the hover state scale doesnt get cut off
      gsap.set($element, { clearProps: 'overflow' });
    },
  });

  // set to overflow hidden while button width is animating to reveal the label
  gsap.set($element, { overflow: 'hidden', visibility: 'visible' });

  tl.fromTo(
    $element,
    { yPercent: 200 },
    {
      yPercent: 0,
      duration: 0.5,
      ease: EaseType.EMBELLISHMENT,
    },
  );
  tl.fromTo(
    $element,
    { width: `${initialWidth}rem` },
    {
      width: 'auto',
      duration: 0.75,
      ease: EaseType.BASIC_BUTTER,
    },
    '>-50%',
  );
  tl.fromTo(
    $label,
    { x: -25 },
    {
      x: 0,
      duration: 0.75,
      ease: EaseType.BASIC_BUTTER,
    },
    '<0',
  );
};

export const hide = ({
  $element,
  $label,
}: {
  $element: HTMLElement;
  $label: HTMLDivElement;
}) => {
  gsap.killTweensOf([$element, $label]);
  const tl = gsap.timeline();
  tl.set($element, {
    visibility: 'hidden',
    width: `${initialWidth}rem`,
    yPercent: 200,
  });
};
