import { fitsMap } from '../Frame/Frame.types';

const getDrawParameters = (
  context: CanvasRenderingContext2D,
  img: HTMLImageElement,
  fit: fitsMap,
) => {
  let dx = 0;
  let dy = 0;
  let dWidth = context.canvas.width;
  let dHeight = context.canvas.height;

  let sx: number | null = null;
  let sy: number | null = null;
  let sWidth: number | null = null;
  let sHeight: number | null = null;

  if (fit === 'CONTAIN') {
    const hRatio = dWidth / img.naturalWidth;
    const vRatio = dHeight / img.naturalHeight;
    const ratio = Math.min(hRatio, vRatio);
    sx = 0;
    sy = 0;
    sWidth = img.naturalWidth;
    sHeight = img.naturalHeight;
    dx = (dWidth - img.naturalWidth * ratio) / 2;
    dy = (dHeight - img.naturalHeight * ratio) / 2;
    dWidth = img.naturalWidth * ratio;
    dHeight = img.naturalHeight * ratio;
  } else if (fit === 'COVER') {
    const imageWidth = img.naturalWidth;
    const imageHeight = img.naturalHeight;
    const aspectRatio = Math.min(dWidth / imageWidth, dHeight / imageHeight);
    let nw = imageWidth * aspectRatio;
    let nh = imageHeight * aspectRatio;
    let ar = 1;
    sx = 1;
    sy = 1;
    sWidth = 1;
    sHeight = 1;

    // decide which gap to fill
    if (nw < dWidth) {
      ar = dWidth / nw;
    }
    if (Math.abs(ar - 1) < 1e-14 && nh < dHeight) {
      ar = dHeight / nh;
    }
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    sWidth = imageWidth / (nw / dWidth);
    sHeight = imageHeight / (nh / dHeight);

    sx = (imageWidth - sWidth) * 0.5;
    sy = (imageHeight - sHeight) * 0.5;

    // make sure source rectangle is valid
    if (sx < 0) sx = 0;
    if (sy < 0) sy = 0;
    if (sWidth > imageWidth) sWidth = imageWidth;
    if (sHeight > imageHeight) sHeight = imageHeight;
  }

  return { sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight };
};

export default getDrawParameters;
