'use client';
import { useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn, getUniqueId } from '~/utils';

import styles from './LogoWall.module.css';
import { DuplicatesLogos, LogoWallProps } from './LogoWall.types';
import LogoWallRow from './LogoWallRow/LogoWallRow';

const LogoWall = (props: LogoWallProps) => {
  const { className, desktopLogos, mobileLogos } = props;
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  return (
    <ModuleWrapper className={cn(styles.container, className)} {...props}>
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
        className={styles.content}
      >
        <div className={cn(styles.layout, styles.desktopLayout)}>
          <LogoWallRow row={desktopLogos} isInView={isInView !== false} />
        </div>
        <div className={cn(styles.layout, styles.mobileLayout)}>
          {mobileLogos.map((row: DuplicatesLogos, index: number) => {
            return (
              <LogoWallRow
                key={getUniqueId('mobileRow')}
                row={row}
                isInView={isInView !== false}
                speed={index % 2 ? 1.3 : 1}
              />
            );
          })}
        </div>
      </Observer>
    </ModuleWrapper>
  );
};

export default LogoWall;
