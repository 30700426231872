/**
 * Gets computed translate values
 * @param {HTMLElement} element
 * @returns {Object}
 * @see https://zellwk.com/blog/translate-in-javascript
 */
export default function translateValues(element: HTMLElement):
  | {
      x: number;
      y: number;
      z: number;
    }
  | undefined {
  const style = window.getComputedStyle(element);
  const matrix = style.transform;

  // No transform property. Simply return 0 values.
  if (matrix === 'none' || typeof matrix === 'undefined') {
    return {
      x: 0,
      y: 0,
      z: 0,
    };
  }

  // Can either be 2d or 3d transform
  const matrixType = matrix.includes('3d') ? '3d' : '2d';
  const matrixMatch = matrix.match(/matrix.*\((.+)\)/);
  if (matrixMatch) {
    const matrixValues = matrixMatch[1].split(', ');

    // 2d Matrixes have 6 values
    // Last 2 values are X and Y.
    // 2d Matrixes does not have Z value.
    if (matrixType === '2d') {
      return {
        x: parseInt(matrixValues[4]),
        y: parseInt(matrixValues[5]),
        z: 0,
      };
    }

    // 3d Matrixes have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
      return {
        x: parseInt(matrixValues[12]),
        y: parseInt(matrixValues[13]),
        z: parseInt(matrixValues[14]),
      };
    }
  }
}
