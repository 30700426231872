import PortableText from '~/components/molecules/PortableText/PortableText';
import { default as StatsMolecule } from '~/components/molecules/Stats/Stats';
import { textLockup5 } from '~/components/molecules/TextLockups/TextLockups.config';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './Stats.module.css';
import { StatsProps } from './Stats.types';

const Stats = (props: StatsProps) => {
  const { className, isHuge, stats, content } = props;
  const renderClass = cn(
    isHuge ? styles.hugeStatsWrapper : styles.wrapper,
    className,
  );

  const options = textLockup5().options;

  return (
    <ModuleWrapper className={renderClass} {...props}>
      {isHuge ? (
        <>
          <div className={styles.container}>
            <div className={styles.columnLeft}>
              <PortableText
                value={content}
                className={styles.content}
                options={options}
              />
            </div>
            <div className={styles.columnRight}>
              <StatsMolecule
                isHuge={isHuge}
                stats={stats}
                className={styles.stats}
              />
            </div>
          </div>
        </>
      ) : (
        <StatsMolecule
          className={styles.normalStats}
          isHuge={isHuge}
          stats={stats}
        />
      )}
    </ModuleWrapper>
  );
};

export default Stats;
