import { default as ToutsMolecule } from '~/components/molecules/Touts/Touts';

import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import styles from './Touts.module.css';
import { ToutsProps } from './Touts.types';

const Touts = (props: ToutsProps) => {
  const { className } = props;

  return (
    <ModuleWrapper className={className} {...props}>
      <div className={styles.container}>
        <ToutsMolecule {...props} className={styles.touts} />
      </div>
    </ModuleWrapper>
  );
};

export default Touts;
