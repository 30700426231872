const composite = /* glsl */ `
precision highp float;
uniform sampler2D tMap;
uniform vec2 uResolution;
varying vec2 vUv;
void main() {
	gl_FragColor = texture2D(tMap, vUv);
}
`;

export default composite;
