'use client';

import PortableText from '~/components/molecules/PortableText/PortableText';
import { textLockup3 } from '~/components/molecules/TextLockups/TextLockups.config';
import textLockup3Styles from '~/components/molecules/TextLockups/TextLockupTitle3.module.css';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './BookEnd.module.css';
import { BookEndProps } from './BookEnd.types';

const BookEnd = (props: BookEndProps) => {
  const { content, className } = props;
  const options = textLockup3();

  return (
    <ModuleWrapper className={cn(styles.bookEnd, className)} {...props}>
      <div className={styles.container}>
        <PortableText
          className={cn(
            options.wrapperClass,
            textLockup3Styles.alignCenter,
            styles.content,
          )}
          value={content}
          options={options.options}
        />
      </div>
    </ModuleWrapper>
  );
};

export default BookEnd;
