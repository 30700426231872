'use client';
import { gsap } from 'gsap/all';
import { useCallback, useRef } from 'react';

import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';
import { getSpacersValues } from '~/utils/getSpacersStyles';

import getSequenceHeight from '../../Sequence/helpers/getSequenceHeight';
import Sequence from '../../Sequence/Sequence';
import styles from './Underlay.module.css';
import { UnderlayProps } from './Underlay.types';

const Underlay = (props: UnderlayProps) => {
  const {
    sequence,
    className,
    startOnMiddleOfScreen,
    fallbackImage,
    sequence2,
    offset,
  } = props;

  const $sequence = useRef(null);
  const $sequence2 = useRef<HTMLDivElement>(null);

  const spacers = useRef<Record<string, string>>(
    getSpacersValues(props.hasSpacers?.after || [], {
      halfSpacers: false,
      asCustomProperties: false,
      unit: 'px',
    }),
  );

  let sequenceHeight;

  switch (sequence.type) {
    case 'image':
      sequenceHeight = getSequenceHeight({
        type: sequence.type,
        numberOfFrames: sequence.frames.length,
        speed: sequence.speed,
      });

      break;
    case 'video':
      sequenceHeight = getSequenceHeight({
        type: sequence.type,
        videoDuration: sequence.videoSequenceDuration,
        speed: sequence.speed,
      });

      break;
  }
  let sequence2Height;

  switch (sequence2.type) {
    case 'image':
      sequence2Height = getSequenceHeight({
        type: sequence2.type,
        numberOfFrames: sequence2.frames.length,
        speed: sequence2.speed,
      });

      break;
    case 'video':
      sequence2Height = getSequenceHeight({
        type: sequence2.type,
        videoDuration: sequence2.videoSequenceDuration,
        speed: sequence2.speed,
      });

      break;
  }

  const renderProps = {
    canvasClassName: styles.canvas,
    startOnMiddleOfScreen: startOnMiddleOfScreen,
    fallbackImage: fallbackImage,
  };

  const onProgressSequence1 = useCallback((progress: number) => {
    if (progress >= 1) {
      gsap.set($sequence.current, {
        opacity: 0,
      });
      gsap.set($sequence2.current, {
        opacity: 1,
      });
    } else {
      gsap.set($sequence.current, {
        opacity: 1,
      });
      gsap.set($sequence2.current, {
        opacity: 0,
      });
    }

    const opacity = gsap.utils.clamp(
      0,
      1,
      gsap.utils.normalize(0, 0.07, progress),
    );
    gsap.set($sequence.current, {
      opacity,
    });
  }, []);

  useScrollProgress($sequence, onProgressSequence1, {
    startOnMiddleOfScreen: true,
    finishOnMiddleOfScreen: true,
    shouldAlwaysComplete: false,
  });

  const onProgressSequence2 = useCallback((progress: number) => {
    const breakpointName = UIStore.getState().breakpoint?.name;
    if ($sequence2.current && breakpointName) {
      gsap.set($sequence2.current.querySelector(`.${styles.canvas}`), {
        x: 0,
        y: progress * parseInt(spacers.current[breakpointName]),
        yPercent: 0,
        xPercent: -50,
      });
    }
  }, []);

  useScrollProgress($sequence2, onProgressSequence2, {
    startOnMiddleOfScreen: false,
    finishOnMiddleOfScreen: false,
    shouldAlwaysComplete: true,
  });

  const styleProps: Record<string, unknown> = {
    '--sequence-2-height': sequence2Height,
    '--sequence-1-height': sequenceHeight,
  };

  if (offset) {
    styleProps['--sequence-offset'] = offset;
  }

  return (
    <ModuleWrapper
      className={cn(className, styles.underlay)}
      {...props}
      style={styleProps}
    >
      <div className={styles.noPaddingWrapper}>
        {sequence.type === 'video' && (
          <Sequence
            {...renderProps}
            className={styles.sequence}
            sequence={sequence}
            startOnMiddleOfScreen={true}
            finishOnMiddleOfScreen={true}
            shouldAlwaysComplete={false}
            ref={$sequence}
          />
        )}
        {sequence.type === 'image' && (
          <Sequence
            {...renderProps}
            className={styles.sequence}
            sequence={sequence}
            startOnMiddleOfScreen={true}
            finishOnMiddleOfScreen={true}
            shouldAlwaysComplete={false}
            ref={$sequence}
          />
        )}
        {sequence2.type === 'video' && (
          <Sequence
            {...renderProps}
            className={styles.sequence2}
            sequence={sequence2}
            startOnMiddleOfScreen={false}
            finishOnMiddleOfScreen={true}
            shouldAlwaysComplete={false}
            ref={$sequence2}
          />
        )}
        {sequence2.type === 'image' && (
          <Sequence
            {...renderProps}
            className={styles.sequence2}
            sequence={sequence2}
            startOnMiddleOfScreen={false}
            finishOnMiddleOfScreen={false}
            shouldAlwaysComplete={false}
            ref={$sequence2}
          />
        )}
      </div>
    </ModuleWrapper>
  );
};

export default Underlay;
