import PortableText from '~/components/molecules/PortableText/PortableText';
import { cn } from '~/utils';

import styles from './CallToAction.module.css';
import { CallToActionProps } from './CallToAction.types';

/**
 * Speedbump - Call to action variant
 * @param content Portable Text content
 */
const CallToAction = ({
  content,
  className,
  variantName,
}: CallToActionProps) => (
  <div className={cn(className, styles[variantName], styles.callToAction)}>
    <PortableText
      className={styles.content}
      value={content}
      options={{
        block: {
          titles: {
            title4: { className: styles.paragraph },
          },
        },
      }}
    />
  </div>
);

export default CallToAction;
