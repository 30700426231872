'use client';
import { gsap } from 'gsap';
import {
  FocusEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import SvgCaret from '~/assets/svg/caret.svg';
import SvgCheckmark from '~/assets/svg/checkmark.svg';
import SvgI from '~/assets/svg/i.svg';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import ButtonExpand from '~/components/atoms/Buttons/UI/ButtonExpand/ButtonExpand';
import ButtonToggle from '~/components/atoms/Buttons/UI/ButtonToggle/ButtonToggle';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { dict } from '~/data/stores/Dictionary';
import useUIStore from '~/state/ui';
import { BreakpointName } from '~/types';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './PricingCard.module.css';
import { EXPAND_BREAKPOINTS, PricingCardProps } from './PricingCard.types';
import PricingDetailTooltip from './PricingDetailTooltip';

const PricingCard = ({ product, pricePeriod }: PricingCardProps) => {
  const breakpoint = useUIStore((state) => state.breakpoint);
  const detailsExpandable =
    breakpoint &&
    EXPAND_BREAKPOINTS.includes(breakpoint?.name as BreakpointName);
  const cardDetailsRef = useRef<HTMLDivElement>(null);
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const expandHandler = useCallback(() => {
    return setDetailsExpanded(!detailsExpanded);
  }, [detailsExpanded]);

  useEffect(() => {
    if (cardDetailsRef.current) {
      cardDetailsRef.current.style.height = 'auto';
      setDetailsExpanded(!detailsExpandable);
    }
  }, [detailsExpandable]);

  useEffect(() => {
    if (cardDetailsRef.current && detailsExpandable) {
      gsap.set(cardDetailsRef.current, {
        height: detailsExpanded
          ? `${cardDetailsRef.current.scrollHeight}px`
          : 0,
      });
    }
  }, [detailsExpanded, detailsExpandable]);

  // if a product uses the secondary title instead of a price, it's a wide card
  const isWide = product.secondaryTitle ? true : false;

  const MAX_BULLETS = isWide
    ? Math.floor(product.detailsBullets.length / 2)
    : 7;

  // first column should have one more bullet than second column if the split is not even
  const MAX_BULLETS_WIDE_COL_1 =
    MAX_BULLETS < product.detailsBullets.length / 2
      ? MAX_BULLETS + 1
      : MAX_BULLETS;

  const refBullets = useRef<HTMLElement[]>([]);

  // bullets on wide cards can expand on tablet
  const [isBulletsExpanded, setIsBulletsExpanded] = useState(false);

  const priceDetails =
    product.pricesByPeriod?.filter(
      (price) => price.pricePeriod.value === pricePeriod,
    )[0] || null;

  // sign up button can be from current price period or fallback
  const signUpButton =
    priceDetails?.signUpButton.button || product.signUpButton.button;

  const onMouseOver = (
    e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
  ) => {
    const $bullets = refBullets.current;
    const $i = e.currentTarget;
    for (const $bullet of $bullets) {
      if ($bullet && $bullet.contains($i)) {
        $bullet.setAttribute('data-is-active', 'true');
      }
    }
  };

  const onMouseLeave = (
    e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
  ) => {
    const $bullets = refBullets.current;
    const $i = e.currentTarget;
    for (const $bullet of $bullets) {
      if ($bullet && $bullet.contains($i)) {
        $bullet.removeAttribute('data-is-active');
      }
    }
  };

  return (
    <div
      className={cn(styles.pricingCard, isWide && styles.pricingCardWide)}
      key={product._key}
    >
      <Shadow className={styles.shadow} />

      {/* top blue text */}
      <div className={styles.cardTitle}>{product.tier.title}</div>

      <div className={styles.cardContentWrapper}>
        <div className={styles.cardIntro}>
          {/* big white text - either a price or a big title */}
          <div className={styles.cardPriceWrapper}>
            {priceDetails && !product.secondaryTitle && (
              <>
                <div className={styles.cardPrice}>${priceDetails.price}</div>
                <div className={styles.cardPriceContext}>
                  {priceDetails.contextLine1 && (
                    <span className={styles.cardPriceContextLine}>
                      {priceDetails.contextLine1}
                    </span>
                  )}
                  {priceDetails.contextLine2 && (
                    <span className={styles.cardPriceContextLine}>
                      {priceDetails.contextLine2}
                    </span>
                  )}
                </div>
              </>
            )}
            {product.secondaryTitle && (
              <div
                className={cn(
                  styles.cardSecondaryTitle,
                  isWide && styles.cardSecondaryTitleWide,
                )}
              >
                {product.secondaryTitle}
              </div>
            )}
          </div>

          {/* top subtext */}
          <div className={styles.cardDescription}>{product.description}</div>

          {/* 2 bullets or descriptive paragraph */}
          {!product.topDescription &&
            product.topBullets &&
            product.topBullets.length && (
              <ul className={styles.cardTopBullets}>
                {product.topBullets.map((bullet) => (
                  <li
                    className={styles.cardTopBulletItem}
                    key={bullet._key}
                    ref={(element) =>
                      bullet.description &&
                      addToRefArray({ element, refArray: refBullets })
                    }
                  >
                    <span className={styles.cardTopBulletText}>
                      {bullet.title}
                    </span>
                    {bullet.description && (
                      <PricingDetailTooltip
                        onMouseLeave={onMouseLeave}
                        onMouseOver={onMouseOver}
                        text={bullet.description}
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}

          {product.topDescription && (
            <div className={styles.cardTopDescription}>
              {product.topDescription}
            </div>
          )}
          {/* TODO: should this be an array of buttons? (q from mick) */}
          {/* cta */}
          <div className={styles.ctaRow}>
            {signUpButton?.to?.url && (
              <Button {...signUpButton} className={styles.cardDetailButton}>
                {signUpButton.to?.label}
              </Button>
            )}
            {/* cta */}
            {product.secondaryButton?.to?.url && (
              <Button
                {...product.secondaryButton}
                className={styles.cardDetailButton}
              >
                {product.secondaryButton.to?.label}
              </Button>
            )}
            <ButtonExpand
              open={detailsExpanded}
              className={cn(
                styles.expandButton,
                detailsExpanded && styles.expanded,
              )}
              iconClassName={styles.expandIcon}
              onClick={expandHandler}
              buttonColorScheme="glass"
            />
          </div>
        </div>

        <div
          className={cn(
            styles.cardDetails,
            detailsExpanded && styles.cardDetailsExpanded,
          )}
          ref={cardDetailsRef}
        >
          {/* bottom block of bullets */}
          <div className={styles.cardDetailsTitle}>{product.detailsTitle}</div>

          {product.detailsBullets && product.detailsBullets.length && (
            <>
              <div
                className={cn(
                  styles.cardDetailsBulletsWrapper,
                  isBulletsExpanded && styles.isBulletsExpanded,
                )}
              >
                <ul
                  className={cn(
                    styles.cardDetailBullets,
                    isBulletsExpanded && styles.isBulletsExpanded,
                  )}
                >
                  {product.detailsBullets.map((bullet, index) => (
                    <li
                      className={cn(
                        styles.cardDetailBulletItem,
                        isWide &&
                          index >= MAX_BULLETS_WIDE_COL_1 &&
                          styles.cardDetailBulletItemExtra,
                        !isWide &&
                          index >= MAX_BULLETS &&
                          styles.cardDetailBulletItemExtra,
                      )}
                      key={bullet._key}
                      ref={(element) =>
                        bullet.description &&
                        addToRefArray({ element, refArray: refBullets })
                      }
                    >
                      <SvgCheckmark className={styles.cardDetailBulletCheck} />
                      <span className={styles.cardDetailBulletText}>
                        {bullet.title}
                      </span>
                      {bullet.description && (
                        <>
                          <button
                            className={styles.cardDetailBulletSvgButton}
                            onMouseOver={(e) => onMouseOver(e)}
                            onFocus={(e) => onMouseOver(e)}
                            onMouseOut={(e) => onMouseLeave(e)}
                            onBlur={(e) => onMouseLeave(e)}
                            aria-label={bullet.description}
                          >
                            <SvgI className={styles.cardDetailBulletSvg} />
                          </button>
                          <div className={styles.cardDetailBulletTooltip}>
                            <span
                              className={styles.cardDetailBulletTooltipText}
                            >
                              {bullet.description}
                            </span>
                            <figure
                              className={styles.cardDetailBulletTooltipTail}
                            />
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              {isWide && (
                <ButtonToggle
                  icon={<SvgCaret />}
                  onClick={(isExpanded) => {
                    setIsBulletsExpanded(isExpanded);
                  }}
                  className={styles.showMoreButton}
                  labelIdle={dict('showLess')}
                  labelActive={dict('showMore')}
                />
              )}
            </>
          )}
        </div>

        {isWide && product.detailsBullets.length > MAX_BULLETS_WIDE_COL_1 && (
          <div className={styles.cardDetailsExtra}>
            <div className={styles.cardDetailsSpacer}>&nbsp;</div>
            {/* extra set bullets on the right */}
            {product.detailsBullets && product.detailsBullets.length && (
              <ul className={styles.cardDetailBullets}>
                {product.detailsBullets.flatMap((bullet, index) =>
                  index < MAX_BULLETS_WIDE_COL_1 ? null : (
                    <li
                      className={styles.cardDetailBulletItem}
                      key={bullet._key}
                      ref={(element) =>
                        bullet.description &&
                        addToRefArray({ element, refArray: refBullets })
                      }
                    >
                      <SvgCheckmark className={styles.cardDetailBulletCheck} />
                      <span className={styles.cardDetailBulletText}>
                        {bullet.title}
                      </span>
                      {bullet.description && (
                        <>
                          <button
                            className={styles.cardDetailBulletSvgButton}
                            onMouseOver={(e) => onMouseOver(e)}
                            onFocus={(e) => onMouseOver(e)}
                            onMouseOut={(e) => onMouseLeave(e)}
                            onBlur={(e) => onMouseLeave(e)}
                            aria-label={bullet.description}
                          >
                            <SvgI className={styles.cardDetailBulletSvg} />
                          </button>
                          <div className={styles.cardDetailBulletTooltip}>
                            <span
                              className={styles.cardDetailBulletTooltipText}
                            >
                              {bullet.description}
                            </span>
                            <figure
                              className={styles.cardDetailBulletTooltipTail}
                            />
                          </div>
                        </>
                      )}
                    </li>
                  ),
                )}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
