const gradient = /* glsl */ `
#ifdef GL_ES
precision mediump float;
#endif

#define PI 3.1415926535897932384626433832795

uniform vec2 u_resolution;
uniform float u_canvas_scale;
uniform float u_wrapper_scale;

uniform float u_shape_type;
uniform vec2 u_shape_size;

// 0  Radial or 1 linear
uniform float u_gradient_type;
uniform float u_gradient_orientation;
uniform float u_gradient_scale;
uniform vec2 u_gradient_offset;

// shapes config
uniform vec3 u_shape_visible;
uniform vec3 u_shape_origin;
uniform vec3 u_shape_scale;


uniform vec2 u_shape1_pos;
uniform vec2 u_shape2_pos;
uniform vec2 u_shape3_pos;


uniform vec4 u_color1;
uniform vec4 u_color2;
uniform vec4 u_color3;
uniform vec4 u_color4;

uniform float u_roundness;



// origin offset for each direction
vec2 topLeft = vec2(0., 1.);
vec2 topRight = vec2(1., 1.);
vec2 bottomLeft = vec2(0.,0.);
vec2 bottomRight = vec2(1., 0.);

// Direction for remap origin to each corner
vec2 topLeftDirection = vec2(1.,-1.);
vec2 topRightDirection = vec2(-1.,-1.);
vec2 bottomLeftDirection = vec2(1, 1);
vec2 bottomRightDirection = vec2(-1.,1.);


// float ratio = u_resolution.x/u_resolution.y;

float sdBox( in vec2 p, in vec2 b )
{
    vec2 d = abs(p)-b;
    return length(max(d,0.0)) + min(max(d.x,d.y),0.0);
}

float sdHexagon( in vec2 p, in float r )
{
    const vec3 k = vec3(-0.866025404,0.5,0.577350269);
    p = abs(p);
    p -= 2.0*min(dot(k.xy,p),0.0)*k.xy;
    p -= vec2(clamp(p.x, -k.z*r, k.z*r), r);
    return length(p)*sign(p.y);
}

mat2 rotate2d(float _angle){
    return mat2(cos(_angle),-sin(_angle),
                sin(_angle),cos(_angle));
}


vec4 gradient(in vec2 pos, in float size, in float type, in float shapeType)
    {

    pos -= u_gradient_offset * size;

    // slighty deform the radial gradient
    pos *= mix(vec2(0.8, 0.6), vec2(0.8,0.50), shapeType);
    // radial gradient
    float dist = length(pos) * ( 1. / size);
    // linear gradient
    vec2 rotatedPos = rotate2d(PI / u_gradient_orientation) * pos;
    dist = mix(dist, (rotatedPos.x + size) * 0.5 / size, type);


    float step1 = 0.0;
    float step2 = 0.40;
    float step3 = 0.72;
    float step4 = 1.0;

    vec4 color = mix(u_color1, u_color2, smoothstep(step1, step2, dist));
    color = mix(color, u_color3, smoothstep(step2, step3, dist));
    color = mix(color, u_color4, smoothstep(step3, step4, dist));
    return color;
}


// 0 bottom left - 1 top right
vec2 uvWithOriginPx (in vec2 pos, in float origin, in vec2 direction){
  vec2 st = gl_FragCoord.xy;

  vec2 originOffset = mix(topLeft, topRight, smoothstep(0.000, 1., origin));
  originOffset = mix(originOffset, bottomLeft, smoothstep(1., 2., origin));
  originOffset = mix(originOffset, bottomRight, smoothstep(2., 3., origin));
  st -= originOffset * u_resolution;

   vec2 offset = (u_resolution / 2. - (u_resolution * (1. / u_canvas_scale) ) / 2.);

   st -= offset * direction;

   st *= u_canvas_scale * (1. / u_wrapper_scale);

   st -= pos * direction;


    return st;
}


vec4 shape(in vec2 offset, in float origin, in float scale) {
     float boxHexMix = smoothstep(0.0,1.0, u_shape_type);

      vec2 direction = mix(topLeftDirection, topRightDirection, smoothstep(0.000, 1., origin));
     direction = mix(direction, bottomLeftDirection, smoothstep(1., 2., origin));
     direction = mix(direction, bottomRightDirection, smoothstep(2., 3., origin));

    vec2 st = uvWithOriginPx(offset, origin, direction);

	vec2 size = u_shape_size;

    vec2 scaledSize = size  * 0.5;

    st -= scaledSize * direction;

    vec4 color = gradient(st * 1. / u_canvas_scale , mix(scaledSize.x, scaledSize.x, boxHexMix) * u_canvas_scale * u_gradient_scale * 0.5, u_gradient_type, u_shape_type);

    // half the roundness on hexagon
    float roundness = u_roundness /mix(1., 2., u_shape_type);

    float shapeBox = sdBox(st, scaledSize - roundness) - roundness;

    float shapeHexagon = sdHexagon(st * rotate2d(PI / 180. * 16.), scaledSize.x - roundness) - roundness;


    float shape = mix(shapeBox, shapeHexagon, boxHexMix);

    shape = step(shape, 0.001);

    return color * shape;
}

void main() {

    vec4 final = shape(u_shape1_pos, u_shape_origin.x, u_shape_scale.x) * u_shape_visible.x;

    final += shape( u_shape2_pos, u_shape_origin.y, u_shape_scale.y) * u_shape_visible.y;

    final += shape( u_shape3_pos, u_shape_origin.z, u_shape_scale.z) * u_shape_visible.z;


    gl_FragColor += final;


    // gl_FragColor = vec4(1.0, 0., 0., 1.);
}
`;

export default gradient;
