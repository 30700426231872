'use client';
import { gsap } from 'gsap';
import { useRef } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Image from '~/components/atoms/Image/Image';
import { CMSImage } from '~/components/atoms/Image/Image.types';
import Video from '~/components/atoms/Video/Video';
import { CMSVideoProps, VideoRef } from '~/components/atoms/Video/Video.types';
import Shadow from '~/components/molecules/Shadow/Shadow';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn, useScrollProgress } from '~/utils';

import styles from './HeroTransitionV4.module.css';
import { HeroTransitionV4Props } from './HeroTransitionV4.types';

/**
 * Hero Transition V4 component
 * @param media The main media element displayed on the device screen.
 * @param moduleIndex The module's index within its section
 * @param className
 * @example <HeroTransitionV4 media={media} />
 */
const HeroTransitionV4 = (props: HeroTransitionV4Props) => {
  const { loopCueTime, hasBlurredBackground, media, className } = props;

  const videoProps = media.media.sanityMedia as CMSVideoProps;
  const $element = useRef<HTMLDivElement>(null);
  const $media = useRef<VideoRef>(null);
  const $mediaInner = useRef<HTMLDivElement>(null);

  const onProgress = (progress: number) => {
    gsap.set($mediaInner.current, {
      '--hero-progress': progress,
    });

    if (progress > 0.05) {
      try {
        $media.current?.play();
      } catch (e) {
        console.error(e);
      }
    }
  };

  useScrollProgress($element, onProgress);

  const onVideoEnded = () => {
    $media.current?.seekTo(loopCueTime);
    $media.current?.play();
  };

  return (
    <ModuleWrapper
      className={cn(styles.heroTransitionV4, className)}
      ref={$element}
      {...props}
    >
      <Image
        source={videoProps?.thumbnail as CMSImage}
        className={cn(
          styles.blur,
          hasBlurredBackground && styles.hasBlurredBackground,
        )}
        dpr={1}
        blur={500}
      />
      <div className={styles.mediaContainer}>
        <div className={styles.mediaInner} ref={$mediaInner}>
          <GlassWrapper className={styles.mediaWrapper}>
            <Video
              aspectRatio={videoProps.aspectRatio}
              src={videoProps.url}
              isLooping={false}
              isMuted={videoProps.isMuted}
              willAutoplay={true}
              controls={false}
              className={styles.media}
              onEnded={onVideoEnded}
              ref={$media}
              thumbnail={videoProps.thumbnail}
            />
          </GlassWrapper>
          <Shadow />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default HeroTransitionV4;
