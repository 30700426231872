import { SanityImageSource } from '@sanity/asset-utils';

import { MediaProps } from '~/components/molecules/Media/Media.types';
import {
  PortableTextColumns,
  PortableTextCustomValue,
} from '~/components/molecules/PortableText/PortableText.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export enum WrapInDeviceOptions {
  studioDisplay = 'studioDisplay',
  macbookPro = 'macbookPro',
}

export enum CustomGlowLayout {
  CenterRightPurple = 'centerRightPurple',
  FullImageGlow = 'fullImageGlow',
}

export type MediaWithTextProps = CMSModuleBase & {
  name: string;
  mobileAlignment?: 'left' | 'right';
  textPosition?: 'below' | 'above';
  wrapInDevice?: WrapInDeviceOptions;
  media: MediaProps;
  text?: PortableTextCustomValue;
  textColumns?: PortableTextColumns;
  textLayout: 'fullWidth' | 'twoColumn';
  customGlowLayout?: CustomGlowLayout;
  customGlowImage?: SanityImageSource;
};
