import { Camera, Pass, Post, Renderer, Transform } from 'ogl';
import { MutableRefObject, RefObject } from 'react';

import { TextBlockPropertiesGroup } from '../../TextLockups/TextLockups.types';
import draw from './draw';
import OGLText from './OGLText';

/**
 * This function handles the basic instanciation of the OGL render, scene and camera, and then triggers
 * the draw function to create the visual elements
 */
const initializeRenderer = ({
  $canvas,
  $canvasWrapper,
  canvasWrapperRect,
  pixelRatio,
  renderer,
  distortedPost,
  camera,
  letterGroups,
  texts,
  scene,
  postParams,
  $textLockupEl,
}: {
  $canvas: RefObject<HTMLCanvasElement>;
  $canvasWrapper: RefObject<HTMLElement>;
  canvasWrapperRect: MutableRefObject<DOMRect | undefined>;
  pixelRatio?: number;
  renderer: MutableRefObject<Renderer | undefined | null>;
  distortedPost: MutableRefObject<Post | undefined | null>;
  camera: MutableRefObject<Camera | undefined | null>;
  letterGroups: TextBlockPropertiesGroup[];
  texts: MutableRefObject<OGLText[]>;
  scene: MutableRefObject<Transform>;
  postParams: Partial<Pass>[];
  $textLockupEl: HTMLDivElement;
}) => {
  if ($canvas.current && $canvasWrapper.current) {
    canvasWrapperRect.current = $canvasWrapper.current.getBoundingClientRect();

    pixelRatio = pixelRatio || window.devicePixelRatio;

    renderer.current = new Renderer({
      canvas: $canvas.current,
      dpr: pixelRatio,
      alpha: true,
      width: canvasWrapperRect.current.width,
      height: canvasWrapperRect.current.height,
    });

    distortedPost.current = new Post(renderer.current.gl);

    camera.current = new Camera(renderer.current.gl, {
      near: 0.1,
      far: 1000,
      left: -canvasWrapperRect.current.width / 2,
      right: canvasWrapperRect.current.width / 2,
      top: canvasWrapperRect.current.height / 2,
      bottom: -canvasWrapperRect.current.height / 2,
    });
    camera.current.position.z = 5;

    draw({
      letterGroups,
      renderer,
      $canvasWrapper,
      texts,
      scene,
      distortedPost,
      postParams,
      $textLockupEl,
    });
  }
};

export default initializeRenderer;
