'use client';
import { mergeRefs } from '@react-aria/utils';
import Hls from 'hls.js';
import debounce from 'lodash/debounce';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import UIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';
import { tickerAddOnce } from '~/utils/ticker';

import getSequenceHeight from '../helpers/getSequenceHeight';
import useIsSequenceVisible from '../helpers/useIsSequenceVisible';
import sequenceStyles from '../Sequence.module.css';
import styles from './Video.module.css';
import { SequenceTypeVideoProps } from './Video.types';

const useUIStore = UIStore;

const Video = (
  {
    sequence,
    top,
    className,
    children,
    finishOnMiddleOfScreen = false,
    startOnMiddleOfScreen = false,
    shouldAlwaysComplete,
    canvasClassName,
  }: SequenceTypeVideoProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const breakpoint = useUIStore((state) => state.breakpoint);
  const $wrapper = useRef<HTMLDivElement>(null);
  const startPosition = useRef<number>(0);
  const progress = useRef(0);
  const hslVideoPlayer = useRef<Hls | null>(null);

  const elementHeight = getSequenceHeight({
    type: 'video',
    videoDuration: sequence.videoSequenceDuration,
    speed: sequence.speed,
  });

  const isSequenceVisible = useIsSequenceVisible($wrapper);
  const $video = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if ($video.current) {
      // HLS videos can play natively on Safari
      if ($video.current.canPlayType('application/vnd.apple.mpegurl')) {
        // iPhones are stubborn and we want to serve them a small mp4 video instead of the HLS playlist
        if (navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/)) {
          // We don't use a simple "480" resolution since we also have a different aspect ratio on mobile
          $video.current.src = `${sequence.videoSequence}_mobile.mp4`;
        } else if (breakpoint) {
          if (isBreakpointOrGreater(breakpoint, 'xl')) {
            $video.current.src = `${sequence.videoSequence}_1440.mp4`;
          } else if (isBreakpointOrGreater(breakpoint, 'lg')) {
            $video.current.src = `${sequence.videoSequence}_1080.mp4`;
          } else if (isBreakpointOrGreater(breakpoint, 'md')) {
            $video.current.src = `${sequence.videoSequence}_720.mp4`;
          } else {
            $video.current.src = `${sequence.videoSequence}_mobile.mp4`;
          }
        } else {
          $video.current.src = `${sequence.videoSequence}_1080.mp4`;
        }

        $video.current.addEventListener('loadedmetadata', function () {
          $video.current?.pause();
        });
      } else if (Hls.isSupported() && !hslVideoPlayer.current) {
        hslVideoPlayer.current = new Hls({
          testBandwidth: true,
          // The observer will take care of starting the load at the right time via `isSequenceVisible`
          autoStartLoad: false,
          ignoreDevicePixelRatio: true,
          capLevelToPlayerSize: true,
          startLevel: -1,
          backBufferLength: Infinity,
          maxMaxBufferLength: Infinity,
          maxBufferHole: 0,
        });
        hslVideoPlayer.current.loadSource(`${sequence.videoSequence}.m3u8`);
        hslVideoPlayer.current.attachMedia($video.current);
        hslVideoPlayer.current.on(
          Hls.Events.MANIFEST_PARSED,
          () => $video.current?.pause(),
        );

        return () => {
          hslVideoPlayer.current?.destroy();
          hslVideoPlayer.current = null;
        };
      }
    }
  }, [sequence, breakpoint]);

  useLayoutEffect(() => {
    if ($wrapper.current) {
      UIStore.subscribe(
        (state) => state.windowWidth,
        debounce(() => {
          tickerAddOnce(() => {
            const box = $wrapper.current?.getBoundingClientRect();
            if (box) {
              // TODO: like for garage doors, let's see if we can avoid querying the window directly here
              // Actually we also need to get the offset from the garage door if it's part of one
              const start = top || box.top + window.scrollY;

              // TODO: This calculation seems to be off when loading the page with an initial scroll position that is not 0
              startPosition.current = start;
            }
          }, true);
        }, 300),
        {
          fireImmediately: true,
        },
      );
    }
  }, []);

  const onProgress = useCallback((currentProgress: number) => {
    progress.current = currentProgress;

    // Prevent stutter
    if ($video.current && currentProgress >= 0.01) {
      if (!$video.current.paused) $video.current?.pause();
      $video.current.currentTime =
        currentProgress * $video.current.duration || 0;
    }
  }, []);

  useScrollProgress($wrapper, onProgress, {
    finishOnMiddleOfScreen,
    startOnMiddleOfScreen,
    shouldAlwaysComplete,
  });

  useEffect(() => {
    if (hslVideoPlayer.current && isSequenceVisible) {
      hslVideoPlayer.current.startLoad();
    }
  }, [sequence, isSequenceVisible, breakpoint]);

  return (
    <div
      className={cn(sequenceStyles.container, className)}
      style={
        {
          '--sequence-height': elementHeight,
        } as CSSProperties
      }
      ref={mergeRefs($wrapper, ref)}
    >
      {children}
      <video
        ref={$video}
        aria-hidden={true}
        disablePictureInPicture
        className={cn(styles.video, canvasClassName)}
        playsInline
        muted
        autoPlay
        preload="none"
      />
    </div>
  );
};

export default forwardRef(Video);
