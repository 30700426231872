import { FocusEvent, MouseEvent } from 'react';

import SvgI from '~/assets/svg/i.svg';
import { cn } from '~/utils';

import styles from './PricingCard.module.css';

const PricingDetailTooltip = ({
  onMouseOver,
  onMouseLeave,
  text,
  tooltipClass,
  triggerClass,
}: {
  text: string;
  tooltipClass?: string;
  triggerClass?: string;
  onMouseOver: (
    e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
  ) => void;
  onMouseLeave: (
    e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
  ) => void;
}) => (
  <>
    <button
      className={cn(styles.topDetailBulletSvgButton, triggerClass)}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseOut={onMouseLeave}
      onBlur={onMouseLeave}
      aria-label={text}
    >
      <SvgI className={styles.topDetailBulletSvg} />
    </button>
    <div className={cn(styles.topDetailBulletTooltip, tooltipClass)}>
      <span className={styles.topDetailBulletTooltipText}>{text}</span>
      <figure className={styles.topDetailBulletTooltipTail} />
    </div>
  </>
);

export default PricingDetailTooltip;
