'use client';
import { useRef, useState } from 'react';

import DeviceWrapper from '~/components/atoms/DeviceWrapper/DeviceWrapper';
import Observer from '~/components/atoms/Observer/Observer';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Glow from '~/components/molecules/Glow/Glow';
import Media from '~/components/molecules/Media/Media';
import Shadow from '~/components/molecules/Shadow/Shadow';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './StandaloneMedia.module.css';
import {
  StandaloneMediaProps,
  WrapInDeviceOptions,
} from './StandaloneMedia.types';

const MediaWithText = (props: StandaloneMediaProps) => {
  const { media, mobileAlignment, wrapInDevice } = props;

  const $mediaContainer = useRef<HTMLDivElement>(null);
  const $media = useRef<HTMLElement | VideoRef>(null);

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  return (
    <ModuleWrapper
      className={cn(
        styles.standaloneMedia,
        mobileAlignment === 'right' && styles.isMobileRightAligned,
        mobileAlignment === 'left' && styles.isMobileLeftAligned,
        !wrapInDevice && styles.noWrapper,
        wrapInDevice === WrapInDeviceOptions.studioDisplay &&
          styles.studioDisplay,
        wrapInDevice === WrapInDeviceOptions.macbookPro && styles.macbookPro,
      )}
      ref={$mediaContainer}
      {...props}
    >
      <Observer
        className={cn(
          styles.mediaWrapper,
          wrapInDevice === WrapInDeviceOptions.studioDisplay &&
            styles.mediaWrapperStudioDisplay,
          wrapInDevice === WrapInDeviceOptions.macbookPro &&
            styles.mediaWrapperMacbookPro,
          mobileAlignment === 'right' && styles.mediaWrapperMobileRightAligned,
          mobileAlignment === 'left' && styles.mediaWrapperMobileLeftAligned,
          mobileAlignment === 'center' &&
            styles.mediaWrapperMobileCenterAligned,
        )}
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
      >
        {(!wrapInDevice ||
          (wrapInDevice &&
            wrapInDevice === WrapInDeviceOptions.studioDisplay)) &&
          media.glow && (
            <>
              <Glow className={styles.glow} source={media.glow} />
              <Shadow className={styles.dropShadow} />
            </>
          )}
        {wrapInDevice && (
          <DeviceWrapper
            className={styles.deviceWapper}
            deviceType={
              wrapInDevice === 'desktop' ? 'studioDisplay' : 'macbookPro'
            }
            isInView={isInView !== false}
          />
        )}
        <div ref={$mediaContainer} className={styles.mediaContainer}>
          <Media
            ref={$media}
            className={cn(styles.media)}
            sanityMedia={media.media.sanityMedia}
            isDisplayed={isInView !== false}
            fixedAspectRatio={true}
            // If the video has controls and is not set to autoplay, it shouldn't play inline on mobile
            playsInline={
              !(
                media.media.sanityMedia?.mediaType === 'video' &&
                !media.media.sanityMedia.willAutoplay
              )
            }
          />
        </div>
      </Observer>
    </ModuleWrapper>
  );
};

export default MediaWithText;
