import Graphic from '~/components/atoms/Graphic/Graphic';
import PortableText from '~/components/molecules/PortableText/PortableText';
import { cn } from '~/utils';

import styles from './SocialProof.module.css';
import { SocialProofProps } from './SocialProof.types';

/**
 * Pothole - Social proof variant
 * @param content Portable Text content
 * @param logo SVG content coming from Sanity, used only on certain layouts
 * @param variantName (From formatter) Based on character count of the body block of the content, used to determine the number of columns automatically
 */
const SocialProof = ({
  content,
  logo,
  variantName,
  className,
}: SocialProofProps) => (
  <div className={cn(className, styles.socialProof, styles[variantName])}>
    <Graphic className={styles.logo} {...logo} />
    <PortableText
      className={styles.content}
      value={content}
      options={{
        marks: {
          em: {
            className: styles.labelEm,
          },
        },
        block: {
          titles: {
            title4: {
              className: styles.paragraph,
            },
          },
        },
      }}
    />
  </div>
);

export default SocialProof;
