import { gsap } from 'gsap';

// normalized progress of card entering the viewport
export const enterProgressTransformer = gsap.utils.pipe(
  gsap.utils.normalize(0, 0.5),
  gsap.utils.clamp(0, 1),
);

// normalized progress of card starting to scale down + fade out
export const leaveProgressTransformer = gsap.utils.pipe(
  gsap.utils.normalize(0.65, 0.9),
  gsap.utils.clamp(0, 1),
);
// normalized progress of next card entering the viewport
export const nextProgressTransformer = gsap.utils.pipe(
  gsap.utils.normalize(0.5, 1),
  gsap.utils.clamp(0, 1),
);
