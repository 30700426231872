'use client';

import { ForwardedRef, forwardRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import { cn } from '~/utils';

import Frame from './Frame/Frame';
import styles from './Sequence.module.css';
import { SequenceProps } from './Sequence.types';
import Video from './Video/Video';

const Sequence = (props: SequenceProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { fallbackImage, className, sequence } = props;

  const FallbackImage = () => {
    if (fallbackImage) {
      return (
        <div className={cn(styles.fallbackImageContainer, className)}>
          <Image className={styles.fallbackImage} source={fallbackImage} />
        </div>
      );
    }
  };

  if (sequence.type === 'image') {
    return (
      <>
        <FallbackImage />
        <Frame
          className={cn(styles.sequenceContainer, className)}
          sequence={sequence}
          top={props.top}
          canvasClassName={props.canvasClassName}
          fit={props.fit}
          finishOnMiddleOfScreen={props.finishOnMiddleOfScreen}
          startOnMiddleOfScreen={props.startOnMiddleOfScreen}
          ref={ref}
        >
          {props.children}
        </Frame>
      </>
    );
  }
  return (
    <>
      <FallbackImage />
      <Video
        sequence={sequence}
        top={props.top}
        finishOnMiddleOfScreen={props.finishOnMiddleOfScreen}
        startOnMiddleOfScreen={props.startOnMiddleOfScreen}
        shouldAlwaysComplete={props.shouldAlwaysComplete}
        canvasClassName={props.canvasClassName}
        className={cn(styles.sequenceContainer, className)}
        ref={ref}
      >
        {props.children}
      </Video>
    </>
  );
};

export default forwardRef(Sequence);
