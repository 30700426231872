import { ForwardedRef, forwardRef } from 'react';

import { cn } from '~/utils';

import styles from './Dim.module.css';
import { DimTypes } from './Dim.types';

const Dim = (
  { dim, className }: DimTypes,
  ref: ForwardedRef<HTMLDivElement>,
) => <div className={cn(styles[dim], className)} ref={ref} />;

export default forwardRef<HTMLDivElement, DimTypes>(Dim);
