import { LivestreamEvent } from '../Live.types';

export function findNextClosestEvent(
  schedule: LivestreamEvent[],
  fromDate: Date,
): LivestreamEvent | null {
  // find the next upcoming event in the schedule.
  for (let i = 0; i < schedule.length; i++) {
    const eventDate = new Date(schedule[i].date);
    if (eventDate < fromDate) {
      if (i === 0) {
        return schedule[0];
      }
      return schedule[i - 1];
    }
  }
  // if there are no upcoming events, select the most recent event
  return schedule[schedule.length - 1];
}

export function sortEventsByDate(
  first: LivestreamEvent,
  second: LivestreamEvent,
): number {
  if (first.date === second.date) {
    return 0;
  }

  const fDate = new Date(first.date);
  const sDate = new Date(second.date);

  return fDate > sDate ? -1 : 1;
}
