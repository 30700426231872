import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { PortableTextCustomValue } from '~/components/molecules/PortableText/PortableText.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export type ImageCloudProps = CMSModuleBase & {
  content: PortableTextCustomValue;
  images: ({ _key: string } & CMSEnhancedMedia<'image'>)[];
};

export const NUMBER_OF_IMAGES = 9;
