import { ReactNode } from 'react';

import ButtonBase from '~/components/atoms/Buttons/Ctas/ButtonBase';
import DriftButton from '~/components/atoms/Buttons/Ctas/DriftButton/DriftButton';

import { ExtractedButton } from '../PortableText/utils/getLinkFromPortableText.types';

const ConditionalInteractiveWrapper = ({
  linkTo,
  className,
  children,
}: {
  linkTo?: ExtractedButton;
  className?: string;
  children: ReactNode;
}) => {
  return linkTo ? (
    linkTo.originalButton._type === 'driftButton' ? (
      <DriftButton
        driftConfiguration={linkTo.originalButton.button.driftConfiguration}
        className={className}
      >
        {children}
      </DriftButton>
    ) : (
      <ButtonBase to={linkTo.to} className={className}>
        {children}
      </ButtonBase>
    )
  ) : (
    <div className={className}>{children}</div>
  );
};

export default ConditionalInteractiveWrapper;
