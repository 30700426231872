const shader = /* glsl */ `
#ifdef GL_ES
  precision mediump float;
  precision mediump int;
#endif

  attribute vec3 position;
  attribute vec4 random;

  uniform mat4 modelMatrix;
  uniform mat4 viewMatrix;
  uniform mat4 projectionMatrix;

  uniform vec2  uResolution;
  uniform float uScaleFactor;
  uniform float uTime;
  uniform float uTimeFactor;
  uniform float uDT;
  uniform float uScroll;
  uniform float uScrollFactor;
  uniform vec2  uMouse;

  varying vec4  vRandom;
  varying vec2  vParticleIndex;
  varying float vParticleLife;
  varying float vParticleAlpha;

  float easeInOutQuint(float x) {
    if (x < 0.5) {
      return 16.0 * x * x * x * x * x;
    }
    return 1.0 - pow(-2.0 * x + 2.0, 5.0) / 2.0;
  }

  float easeInOutExpo(float x) {
    if (x == 0.0) return 0.0;
    if (x == 1.0) return 1.0;
    if (x < 0.5) {
      return pow(2.0, 20.0 * x - 10.0) / 2.0;
    }
    return (2.0 - pow(2.0, -20.0 * x + 10.0)) / 2.0;
  }

  float particleAlpha(float progress) {
    if (progress < 0.25) {
      return easeInOutQuint(progress / 0.25);
    }
    return 1.0 - easeInOutQuint((progress - 0.5) / 0.5);
  }

  void main() {
    float time = uTime * 0.001 * uTimeFactor;
    float scroll = uScroll * 0.002 * uScrollFactor;

    // pass in our random attribute data to the frgment shader
    vRandom = random;
    // set the particle texture atlas index based on the random value
    vParticleIndex = vec2(floor(random.x * 4.0) * 0.25, floor(random.y * 4.0) * 0.25);
    // particle life
    vParticleLife = mod(time * random.w, 3.0) / 3.0;
    // particle alpha
    vParticleAlpha = particleAlpha(vParticleLife) * random.z * 0.9;

    // compute new position and add movement
    vec4 pos = vec4(1.0);

    float sx = sin(mod(time * position.x, 2.0) * 3.1415) * 0.1;
    float sy = cos(mod(time * position.y, 2.0) * 3.1415) * 0.2 - mod(scroll * 0.5, 1.0);

    pos.x = (-3.0 + mod(time * position.x + sx, 6.0));
    pos.y = ( 3.0 - mod(time * 1.5 * position.y + sy, 6.0));
    pos.z = (-6.0 + mod(time * position.z, 4.0));

    gl_PointSize = 5.0 + 40.0 / log(-pos.z) * random.w * uScaleFactor;
    gl_Position = modelMatrix * viewMatrix * projectionMatrix * pos;
  }
`;

export default shader;
