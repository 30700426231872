'use client';
import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useRef, useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import { PaginationDotsRef } from '~/components/atoms/Pagination/Dots/PaginationDots.types';
import useUIStore from '~/state/ui';
import { cn, getSpacer, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './InteractiveQuotesSmCarousel.module.css';
import { InteractiveQuotesSmCarouselProps } from './InteractiveQuotesSmCarousel.types';
import InteractiveQuotesSmCarouselSlide from './InteractiveQuotesSmCarouselSlide/InteractiveQuotesSmCarouselSlide';
import { ForwardedInteractiveQuotesSmCarouselSlideSlideRef } from './InteractiveQuotesSmCarouselSlide/InteractiveQuotesSmCarouselSlide.types';

/**
 * Quote carousel component
 * @param slides An array of slide data objects
 * @param className
 * @example <QuoteCardCarousel slides={slides]/>
 */

const InteractiveQuotesSmCarousel = (
  props: InteractiveQuotesSmCarouselProps,
) => {
  const { slides, className } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  const lastIndex = useRef<number>(-1);
  const refSlides = useRef<ForwardedInteractiveQuotesSmCarouselSlideSlideRef[]>(
    Array(slides.length),
  );
  const pagination = useRef<PaginationDotsRef>(null);

  const isComputedStyleComplete = useUIStore(
    (state) => state.isComputedStyleComplete,
  );

  const total = slides.length;

  const [sliderRef, instanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.slideItem',
    slides: {
      origin: 'center',
      spacing: getSpacer(16),
      perView: 'auto',
      number: total,
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: true,
      },
    },
    animationStarted: (slider) => {
      // this gets triggered as soon as the seek animation begins
      const targetIdx = slider.animator.targetIdx ?? -1;
      if (targetIdx >= 0) {
        checkCurrentSlide.current(targetIdx);
      }
    },
    slideChanged() {
      checkCurrentSlide.current();
    },
    detailsChanged() {
      pagination.current?.setTrackPosition();
    },
  });

  const checkCurrentSlide = useRef((targetIdx = -1) => {
    const keenSlider = instanceRef.current;
    if (keenSlider && keenSlider.track) {
      const useTargetIdx = targetIdx >= 0;
      const currentIndex =
        (useTargetIdx ? targetIdx : keenSlider.track.details?.rel) || 0;

      if (currentIndex !== lastIndex.current) {
        for (let i = 0; i < refSlides.current.length; i++) {
          const slide = refSlides.current[i];
          if (slide) {
            if (currentIndex === i) {
              slide.setActive(
                true,
                Math.sign(lastIndex.current - currentIndex),
              );
            } else {
              slide.setActive(false);
            }
          }
        }

        // for pagination dots
        setCurrentIndex(currentIndex);
        lastIndex.current = currentIndex;
      }
    }
  });

  const moveToIndex = (index: number) => {
    instanceRef.current?.moveToIdx(index);
  };

  useEffect(() => {
    if (isComputedStyleComplete && instanceRef?.current?.update) {
      instanceRef.current?.update();
    }
  }, [isComputedStyleComplete, instanceRef]);

  useEffect(() => {
    checkCurrentSlide.current();
  }, []);

  return (
    <div className={cn(styles.interactiveQuotesSmCarousel, className)}>
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
        className={styles.slidesViewport}
      >
        <div className={`${styles.slidesContainer}`} ref={sliderRef}>
          {slides.map(({ _key, eyebrow, image, logo, text }, slideIndex) => (
            <InteractiveQuotesSmCarouselSlide
              ref={(ref: ForwardedInteractiveQuotesSmCarouselSlideSlideRef) => {
                addToRefArray({
                  element: ref,
                  refArray: refSlides,
                  index: slideIndex,
                });
              }}
              _key={_key}
              className={cn(styles.slide, 'slideItem')}
              eyebrow={eyebrow}
              image={image}
              isInView={isInView !== false}
              key={_key}
              logo={logo}
              onClick={moveToIndex}
              slideIndex={slideIndex}
              text={text}
            />
          ))}
        </div>
        <PaginationDots
          className={styles.paginationDots}
          activeIndex={currentIndex}
          total={slides.length}
          onClick={moveToIndex}
          ref={pagination}
          parentSliderRef={instanceRef}
        />
      </Observer>
    </div>
  );
};

export default InteractiveQuotesSmCarousel;
