'use client';
import { SideBySideV4ContentProps } from '~/components/molecules/SideBySideV4/SideBySideV4.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import SideBySideV4Lockup from '../../../molecules/SideBySideV4/SideBySideV4';
import styles from './StandaloneSideBySideV4.module.css';
import { SideBySideV4Props } from './StandaloneSideBySideV4.types';

/**
 * Side by side module
 * @param content (Optional) PortableText content
 * @param mediaFront Media data coming from Sanity
 * @param mediaBack Media data coming from Sanity
 * @param touts (Optional) Touts to be rendered in place of the content
 */
const StandaloneSideBySideV4 = (props: SideBySideV4Props) => {
  const { className, content, linkTo, media, title, touts } = props;

  // check contentProps to match SideBySideV4Lockup type requirements
  let contentProps: SideBySideV4ContentProps | undefined;
  if (content) {
    contentProps = {
      textContentType: 'richText',
      content,
      linkTo,
    };
  } else if (touts) {
    contentProps = {
      textContentType: 'touts',
      touts,
    };
  }

  // if contentProps are invalid, do not attempt to render.
  if (typeof contentProps === 'undefined') {
    return null;
  }

  return (
    <ModuleWrapper
      className={cn(styles.standaloneSideBySide, className)}
      {...props}
    >
      <SideBySideV4Lockup title={title} media={media} {...contentProps} />
    </ModuleWrapper>
  );
};

export default StandaloneSideBySideV4;
