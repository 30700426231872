'use client';

import { default as QuoteCarouselMolecule } from '~/components/molecules/QuoteCarousel/QuoteCarousel';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './QuoteCarousel.module.css';
import { QuoteCarouselProps } from './QuoteCarousel.types';

/**
 * Quote carousel component
 * @param slides An array of slide data objects
 * @param className
 * @example <QuoteCarousel slides={slides]/>
 */
const QuoteCarousel = (props: QuoteCarouselProps) => {
  const { accentType, slides, className } = props;
  const renderClasses = cn(styles.quoteCarousel, className);

  return (
    <ModuleWrapper className={renderClasses} {...props}>
      <QuoteCarouselMolecule slides={slides} accentType={accentType} />
    </ModuleWrapper>
  );
};

export default QuoteCarousel;
