import Image from '~/components/atoms/Image/Image';
import { getAspectRatio } from '~/components/atoms/Image/utils';
import { cn } from '~/utils';

import styles from './DeviceWrapper.module.css';
import deviceWrapperStore from './DeviceWrapper.store';
import { DeviceWrapperType } from './DeviceWrapper.types';

const DeviceWrapper = ({
  className,
  deviceType = 'studioDisplay',
  isInView,
}: DeviceWrapperType) => {
  const deviceWrappers = deviceWrapperStore.getState().deviceWrappers;

  if (deviceWrappers) {
    return (
      <div className={cn(styles.deviceWrapper, className)}>
        <div
          className={styles.deviceScreen}
          style={{
            // Enforce aspect ratio to make sure the device doesn't get cropped
            aspectRatio: getAspectRatio(deviceWrappers[deviceType]),
          }}
        >
          {deviceType === 'iPhone' && (
            <Image
              source={deviceWrappers.iPhone}
              className={styles.iphone}
              isDisplayed={isInView}
              fixedAspectRatio={true}
            />
          )}
          {deviceType === 'iPad' && (
            <Image
              source={deviceWrappers.iPad}
              className={styles.ipad}
              isDisplayed={isInView}
              fixedAspectRatio={true}
            />
          )}
          {deviceType === 'studioDisplay' && (
            <Image
              source={deviceWrappers.studioDisplay}
              className={styles.appleStudioDisplayStand}
              isDisplayed={isInView}
              fixedAspectRatio={true}
            />
          )}
          {deviceType === 'macbookPro' && (
            <>
              <Image
                source={deviceWrappers.macbookPro}
                className={styles.macbookPro}
                isDisplayed={isInView}
                fixedAspectRatio={true}
              />
              <Image
                source={deviceWrappers.macbookProReflection}
                className={styles.macbookProReflection}
                isDisplayed={isInView}
                fixedAspectRatio={true}
              />
            </>
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

export default DeviceWrapper;
