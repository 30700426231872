import { ReactNode } from 'react';

import { cn } from '~/utils';

import styles from './GlassWrapper.module.css';

const GlassWrapper = ({
  children,
  className,
  contentClassName,
}: {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
}) => {
  return (
    <div className={cn(styles.glassWrapper, className)}>
      <div className={cn(styles.glassWrapperContent, contentClassName)}>
        {children}
      </div>
    </div>
  );
};

export default GlassWrapper;
