import Dim from '~/components/atoms/Dim/Dim';
import { cn } from '~/utils';

import styles from './EnhancedMedia.module.css';
import { EnhancedMediaProps } from './EnhancedMedia.types';

/**
 * Displays an asset with an overlay on top of it and a glow below
 * @param props.children Either a `<Media>`, <Image>`, `<Video>` or `<GlowMedia>` single compoment
 * @param props.overlay Value of the overlay, according to the overlay tokens
 * @param props.glow Value of the overlay, according to the overlay tokens
 * @param props.className ClassName of the wrapping div
 * @param props.overlayClassName ClassName of the overlay div
 * @returns
 */
const EnhancedMedia = ({
  children,
  className,
  overlay,
  // Temporarily commented out until glows rework is done
  // glow,
  overlayClassName,
}: EnhancedMediaProps) => {
  return (
    <div className={cn(styles.enhancedMedia, className)}>
      {children}
      {overlay !== 'none' && (
        <Dim dim={overlay} className={cn(styles.overlay, overlayClassName)} />
      )}
    </div>
  );
};

export default EnhancedMedia;
