import blurFragment from './fragments/blur';
import compositeFragment from './fragments/composite';
import shapeGradientsFragment from './fragments/shapeGradients';

const vertex = /* glsl */ `
attribute vec2 uv;
attribute vec2 position;

varying vec2 vUv;

void main() {
	vUv = uv;
	gl_Position = vec4(position, 0, 1);
}

`;

export {
  vertex,
  shapeGradientsFragment,
  //  shader for post processing
  blurFragment,
  compositeFragment,
};
