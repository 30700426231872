'use client';
import { debounce } from 'lodash';
import { Camera, Post, Renderer, Transform } from 'ogl';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import UIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';
import { tickerAddOnce } from '~/utils/ticker';

import { PortableTextOptions } from '../PortableText/PortableTextOptions.types';
import TextLockup from '../TextLockups/TextLockup';
import { ForwardedTextLockupRef } from '../TextLockups/TextLockups.types';
import initializeRenderer from './utils/initializeRenderer';
import OGLText from './utils/OGLText';
import styles from './WebglText.module.css';
import { WebglTextProps, WebglTextRef } from './WebglText.types';

/**
 * This component will re-create a Portable Text content in WebGL, matching it perfectly to the pixel. It uses
 * OGL as a library, and instanciate a scene containing a Plane for each Portable Text block, rendered with an
 * orthographic camera. Each plane is displaying a texture that is extracted from a 2D canvas on which we drew
 * each letter individually.
 *
 * Notes: the HTML text is initially rendered normally, and then hidden by setting the font color to transparent
 *
 * @param props.content Portable text content to be displayed in WebGL
 * @param props.options Portable text options that will be passed down to the Portable Text component
 * @param props.className The class name to be applied to the portable text element (not the wrapper)
 * @param props.postParams The params of a post-processing effect we want to apply. (e.g. the distortion effect)
 * @param props.pixelRatio The DPR value to use in our renderer
 * @param ref Returns an access to the canvas wrapper, the portable tet component, a forceRender function
 * and a function to update uniforms of our posts
 */
const WebglText = (
  { content, options, className, postParams = [], pixelRatio }: WebglTextProps,
  ref: ForwardedRef<WebglTextRef>,
) => {
  const renderer = useRef<Renderer | null>(null);
  const camera = useRef<Camera | null>(null);
  const scene = useRef<Transform>(new Transform());
  const distortedPost = useRef<Post | null>(null);
  const texts = useRef<OGLText[]>([]);

  const $canvasWrapper = useRef<HTMLDivElement>(null);
  const canvasWrapperRect = useRef<DOMRect>();
  const $canvas = useRef<HTMLCanvasElement>(null);
  const $element = useRef<HTMLDivElement>(null);

  const textLockup = useRef<ForwardedTextLockupRef>(null);

  const $letters = useRef([]);

  const isInView = useRef(false);

  const isMounted = useRef(false);

  useImperativeHandle(ref, () => ({
    $canvasWrapper,
    $element,
    updatePassesUniforms: (
      uniformsValues: Record<string, number | number[]>,
    ) => {
      if (distortedPost.current) {
        for (const uniformKey in uniformsValues) {
          const uniformValue = uniformsValues[uniformKey];
          for (
            let index = 0;
            index < distortedPost.current.passes.length;
            index++
          ) {
            const post = distortedPost.current.passes[index];
            const uniform = post.uniforms[uniformKey];
            if (uniform) {
              uniform.value = uniformValue;
            }
          }
        }
      }
    },
    forceRender: () => {
      if (camera.current) {
        distortedPost.current?.render({
          scene: scene.current,
          camera: camera.current,
        });
      }
    },
  }));

  const customOptions: PortableTextOptions = {
    ...options,
    block: {
      ...options.block,
      bodies: {
        ...options.block?.bodies,
        letterRef: $letters,
      },
      titles: {
        ...options.block?.titles,
        letterRef: $letters,
      },
      accents: {
        ...options.block?.accents,
        letterRef: $letters,
      },
    },
    marks: {
      ...options.marks,
      openQuote: {
        ...options.marks?.openQuote,
        letterRef: $letters,
      },
    },
  };

  const rendererCleanup = () => {
    texts.current = [];
    if (textLockup.current?.$element?.current) {
      textLockup.current.$element.current.classList.remove(
        styles.canvasRendered,
      );
    }
    renderer.current = null;
    distortedPost.current = null;
    scene.current = new Transform();
    camera.current = null;
  };

  const resizeRenderer = () => {
    if ($canvasWrapper.current && renderer.current && camera.current) {
      canvasWrapperRect.current =
        $canvasWrapper.current.getBoundingClientRect();

      renderer.current.setSize(
        canvasWrapperRect.current.width,
        canvasWrapperRect.current.height,
      );

      camera.current.left = -canvasWrapperRect.current.width / 2;
      camera.current.right = canvasWrapperRect.current.width / 2;
      camera.current.top = canvasWrapperRect.current.height / 2;
      camera.current.bottom = -canvasWrapperRect.current.height / 2;

      camera.current.orthographic();
    }
  };

  const retries = useRef(0);

  // Initialize renderer
  const tryInitialization = () => {
    if (renderer.current === null && isMounted.current && textLockup.current) {
      const letterGroups = textLockup.current?.refs?.current;
      const $textLockupEl = textLockup.current?.$element?.current;

      if (letterGroups && $textLockupEl) {
        initializeRenderer({
          $canvas,
          $canvasWrapper,
          canvasWrapperRect,
          pixelRatio,
          renderer,
          distortedPost,
          camera,
          letterGroups,
          texts,
          scene,
          postParams,
          $textLockupEl,
        });
      }
    } else if (retries.current < 10) {
      retries.current++;
      tickerAddOnce(() => {
        tryInitialization();
      });
    }
  };

  useEffect(() => {
    isMounted.current = true;

    const debouncedResize = debounce(() => {
      if (renderer.current && textLockup.current?.$element?.current) {
        textLockup.current.$element.current.classList.remove(
          styles.canvasRendered,
        );

        tickerAddOnce(() => {
          resizeRenderer();
          for (const text of texts.current) {
            text.resize();
          }

          tickerAddOnce(() => {
            if (
              distortedPost.current &&
              textLockup.current?.$element?.current &&
              camera.current
            ) {
              distortedPost.current.render({
                scene: scene.current,
                camera: camera.current,
              });

              textLockup.current.$element.current.classList.add(
                styles.canvasRendered,
              );
            }
          });
        });
      }
    }, 10);

    const unsubscribe = UIStore.subscribe(
      (state) => state.windowWidth,
      debouncedResize,
    );

    return () => {
      isMounted.current = false;
      rendererCleanup();
      unsubscribe();
    };
  }, []);

  const onProgress = useCallback(
    (progress: number, isCurrentlyInView: boolean) => {
      if (
        isCurrentlyInView &&
        isInView.current === false &&
        renderer.current === null
      ) {
        tryInitialization();
      }
      isInView.current = isCurrentlyInView;
    },
    // tryInitialization is not reactive
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useScrollProgress($element, onProgress, {
    shouldAlwaysComplete: false,
    runImmediately: true,
  });

  return (
    <div className={cn(styles.textElements)} ref={$element}>
      <TextLockup
        className={cn(styles.portableText, className)}
        value={content}
        options={customOptions}
        ref={textLockup}
      />
      <div className={styles.canvasWrapper} ref={$canvasWrapper}>
        <canvas ref={$canvas} />
      </div>
    </div>
  );
};

export default forwardRef(WebglText);
