import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { RefObject } from 'react';

import UIStore from '~/state/ui';
import { tickerAddOnce } from '~/utils/ticker';

import { fitsMap, SequenceTypeImage } from '../Frame/Frame.types';
import getDrawParameters from './getDrawParameters';
import loadImages from './loadImages';

const initializeCanvas = async (
  $canvas: HTMLCanvasElement,
  firstFrame: number,
  sequence: SequenceTypeImage,
  fit: fitsMap,
  mounted: boolean,
  currentFrame: RefObject<number>,
): Promise<{
  render: (val: boolean | number) => void;
  destroy: () => void;
} | null> => {
  const specialFramesIndexes = [0, sequence.frames.length - 1 || 0, firstFrame];

  const rect = $canvas.getBoundingClientRect();

  const context = $canvas.getContext('2d');

  const loadedTextures: { img: HTMLImageElement; index: number }[] = [];

  const defaultImageIndex = firstFrame as number;

  let firstFrameImg = await loadImages(
    loadedTextures,
    sequence.frames,
    sequence.framesLoadOrder,
    defaultImageIndex,
    rect.width as number,
    rect.height as number,
    fit,
    sequence.aspectRatio,
  );

  // As this part of code is async, we check that we didn't unmount
  // in bewtween, and that we actually have the first frame
  if (mounted && firstFrameImg && context) {
    context.canvas.width = rect.width;
    context.canvas.height = rect.height;

    let drawParametersSpecialFrame = getDrawParameters(
      context,
      firstFrameImg,
      fit,
    );
    let drawParametersRegularFrame: null | ReturnType<
      typeof getDrawParameters
    > = null;

    let drawParameters = drawParametersSpecialFrame;

    const draw = (img: HTMLImageElement) => {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      const { sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight } =
        drawParameters;

      if (sx !== null && sy !== null && sWidth !== null && sHeight !== null) {
        context.drawImage(
          img,
          sx,
          sy,
          sWidth,
          sHeight,
          dx,
          dy,
          dWidth,
          dHeight,
        );
      } else {
        context.drawImage(img, dx, dy, dWidth, dHeight);
      }
    };

    // Let's render the very first frame we have
    tickerAddOnce(() => {
      if (firstFrameImg) {
        draw(firstFrameImg);
      }
    });

    let renderedTextureIndex = defaultImageIndex;

    let retryTimeout: ReturnType<typeof setTimeout>;

    // Where the magic happens on every frame
    const render = (force?: boolean | number) => {
      const forceRender = force === true;

      // If one of those two values is unavailable or unchanged we skip the rendering
      if (
        currentFrame.current !== null &&
        (currentFrame.current !== renderedTextureIndex || forceRender)
      ) {
        const textureIndex = currentFrame.current;

        /**
         * If the image still doesn't exist yet, start a timer to retry the
         * render in two seconds. If the render is requested again externally,
         * the timeout will be cancelled and only started again if the next
         * frame requested is still undefined. If a texture exists, no retry
         * timeout will be called. In most cases this timeout will never run,
         * but it resolves issues with slow connections not rendering the proper
         * texture if the most recent render request was for a texture that has
         * not yet loaded.
         */

        if (retryTimeout) {
          clearTimeout(retryTimeout);
        }

        if (typeof loadedTextures[textureIndex]?.img === 'undefined') {
          retryTimeout = setTimeout(() => render(), 2000);
        }

        // If the index is still the same we don't rerender (especially because it triggers an image decode etc)
        if (renderedTextureIndex !== textureIndex || forceRender) {
          if (
            loadedTextures[textureIndex] &&
            loadedTextures[textureIndex].img
          ) {
            if (
              drawParametersRegularFrame === null &&
              !specialFramesIndexes.includes(textureIndex)
            ) {
              drawParametersRegularFrame = getDrawParameters(
                context,
                loadedTextures[textureIndex].img,
                fit,
              );
            }

            if (
              !specialFramesIndexes.includes(textureIndex) &&
              drawParametersRegularFrame !== null
            ) {
              drawParameters = drawParametersRegularFrame;
            } else {
              drawParameters = drawParametersSpecialFrame;
            }

            renderedTextureIndex = textureIndex;
            draw(loadedTextures[textureIndex].img);
          }
        }
      }
    };

    // Let's handle resize
    const unsubscribeWindowWidth = UIStore.subscribe(
      (state) => state.windowWidth,
      // We debounce because we don't need to update all this while we are still in the process of resizing
      debounce((state) => {
        const windowWidth = state;
        tickerAddOnce(() => {
          const rect = $canvas.getBoundingClientRect();

          context.canvas.width = rect.width;
          context.canvas.height = rect.height;

          // We resize our arrays of textures and framesToLoad
          loadedTextures.splice(0, loadedTextures.length);

          // and now we're ready to load again
          loadImages(
            loadedTextures,
            sequence.frames,
            sequence.framesLoadOrder,
            currentFrame.current as number,
            windowWidth,
            UIStore.getState().windowHeight as number,
            fit,
          ).then((img) => {
            if (img && mounted) {
              firstFrameImg = img;
              drawParametersSpecialFrame = getDrawParameters(
                context,
                firstFrameImg,
                fit,
              );
              drawParametersRegularFrame = null;
              render(true);
            }
          });
        }, true);
      }, 800),
    );

    const unsubscribeWindowHeight = UIStore.subscribe(
      (state) => state.windowHeight,
      // We debounce because we don't need to update all this while we are still in the process of resizing
      debounce(() => {
        tickerAddOnce(() => {
          const rect = $canvas.getBoundingClientRect();

          context.canvas.width = rect.width;
          context.canvas.height = rect.height;

          if (mounted && firstFrameImg) {
            drawParametersSpecialFrame = getDrawParameters(
              context,
              firstFrameImg,
              fit,
            );
            drawParametersRegularFrame = null;
            render(true);
          }
        }, true);
      }, 800),
    );

    return {
      render,
      destroy: () => {
        unsubscribeWindowWidth();
        unsubscribeWindowHeight();
      },
    };
  }

  // We have an empty function here as we need one to be called on unmount, but it's gonna be replaced
  // during the init function if we get to there
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return noop as any;
};

export default initializeCanvas;
