'use client';
import { useRef } from 'react';

import {
  SideBySideContentProps,
  SideBySideDeviceWrapperProps,
} from '~/components/molecules/SideBySide/SideBySide.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import SideBySideLockup from '../../../molecules/SideBySide/SideBySide';
import styles from './StandaloneSideBySide.module.css';
import { SideBySideProps } from './StandaloneSideBySide.types';

/**
 * Side by side module
 * @description The parallax effect is applied to all the side by sides but the ones wrapped in a device
 * @param content (Optional) PortableText content
 * @param media (Optional)  Media data coming from Sanity
 * @param mediaMobile (Optional)  Media data coming from Sanity (only available if wrapped in a multi-device)
 * @param mediaTablet (Optional)  Media data coming from Sanity (only available if wrapped in a multi-device)
 * @param layout The position of the text compared to the media
 * @param logo (Optional) SVG content coming from Sanity, used only on certain layouts
 * @param touts (Optional) Touts to be rendered in place of the content (only available if wrapped in a device)
 * @param wrapInDevice (Optional) Which device to wrap the media in
 * @param linkTo (Optional) Link extracted from the Portable Text content in the data formatter
 * @param contentWidth The number of columns the content/touts should take
 */
const StandaloneSideBySide = (props: SideBySideProps) => {
  const {
    className,
    content,
    contentWidth,
    layout,
    linkTo,
    logo,
    media,
    touts,
    wrapInDevice,
  } = props;

  const $element = useRef<HTMLDivElement>(null);

  const isInDevice = typeof wrapInDevice !== 'undefined';

  // check contentProps to match SideBySideLockup type requirements
  let contentProps: SideBySideContentProps | undefined;
  if (content) {
    contentProps = {
      textContentType: 'richText',
      content,
      contentWidth,
      linkTo,
    };
  } else if (touts) {
    contentProps = {
      textContentType: 'touts',
      touts,
    };
  }

  // check deviceWrapperProps to match SideBySideLockup type requirements
  let deviceWrapperProps: SideBySideDeviceWrapperProps;
  if (wrapInDevice) {
    if (wrapInDevice === 'multi') {
      deviceWrapperProps = {
        wrapInDevice,
        media,
      };
    } else {
      deviceWrapperProps = {
        wrapInDevice,
        media,
      };
    }
  } else {
    deviceWrapperProps = {
      logo,
      media,
    };
  }

  // if either contentProps or deviceWrapperProps are invalid, do not attempt to render.
  if (typeof contentProps === 'undefined') {
    return null;
  }

  return (
    <ModuleWrapper
      className={cn(
        styles.standaloneSideBySide,
        isInDevice && styles.isInDevice,
        className,
      )}
      ref={$element}
      {...props}
    >
      <SideBySideLockup
        $wrapper={$element}
        layout={layout}
        {...contentProps}
        {...deviceWrapperProps}
      />
    </ModuleWrapper>
  );
};

export default StandaloneSideBySide;
