'use client';
import { CSSProperties } from 'react';

import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import { fitsMap } from '../../Sequence/Frame/Frame.types';
import getSequenceHeight from '../../Sequence/helpers/getSequenceHeight';
import Sequence from '../../Sequence/Sequence';
import Submodules from '../../Submodules/Submodules';
import styles from './LaptopClose.module.css';
import { LaptopCloseProps } from './LaptopClose.types';

const LaptopClose = (props: LaptopCloseProps) => {
  const { className, sequence, modules } = props;

  let elementHeight: number | undefined;
  if (sequence.type === 'image') {
    elementHeight = getSequenceHeight({
      type: sequence.type,
      numberOfFrames: sequence.frames.length,
      speed: sequence.speed,
    });
  } else if (sequence.type === 'video') {
    elementHeight = getSequenceHeight({
      type: sequence.type,
      videoDuration: sequence.videoSequenceDuration,
      speed: sequence.speed,
    });
  }

  const renderProps = {
    className: styles.sequence,
    canvasClassName: styles.canvas,
    fit: fitsMap.CONTAIN,
    startOnMiddleOfScreen: false,
    finishOnMiddleOfScreen: true,
  };

  return (
    <ModuleWrapper
      className={cn(styles.laptopCloseImageSequence, className)}
      style={
        {
          '--sequence-height': elementHeight,
        } as CSSProperties
      }
      {...props}
    >
      <div className={styles.antiPaddingWrapper}>
        <div className={styles.desktop}>
          {modules && (
            <div className={styles.modulesWrapper}>
              <Submodules modules={modules} className={styles.modules} />
            </div>
          )}
          <div className={styles.sequenceWrapper}>
            {sequence.type === 'image' && (
              <Sequence {...renderProps} sequence={sequence} />
            )}

            {sequence.type === 'video' && (
              <Sequence {...renderProps} sequence={sequence} />
            )}
          </div>
        </div>
        <div className={styles.mobile}>
          {modules && modules[0] && (
            <div className={styles.modulesWrapperMobile}>
              <Submodules
                modules={modules.slice(0, 1)}
                className={cn(styles.modules, styles.covered)}
              />
            </div>
          )}
          <div className={styles.sequenceWrapper}>
            {sequence.type === 'image' && (
              <Sequence {...renderProps} sequence={sequence} />
            )}

            {sequence.type === 'video' && (
              <Sequence {...renderProps} sequence={sequence} />
            )}
          </div>
          {modules && modules.length > 1 && (
            <Submodules
              modules={modules.slice(1)}
              className={cn(styles.modules, styles.postSequence)}
            />
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default LaptopClose;
