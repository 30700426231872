const shader = /* glsl */ `
#ifdef GL_ES
  precision mediump float;
  precision mediump int;
#endif

  attribute vec2 uv;
  attribute vec2 position;

  varying vec2 vUv;

  void main() {
      vUv = uv;
      gl_Position = vec4(position, 0, 1);
  }
`;

export default shader;
