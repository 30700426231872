'use client';
import { mergeRefs } from '@react-aria/utils';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import UIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import { tickerAddOnce } from '~/utils/ticker';

import getSequenceHeight from '../helpers/getSequenceHeight';
import initializeCanvas from '../helpers/initializeCanvas';
import useIsSequenceVisible from '../helpers/useIsSequenceVisible';
import sequenceStyles from '../Sequence.module.css';
import styles from './Frame.module.css';
import { fitsMap, SequenceTypeFrameProps } from './Frame.types';

const Frame = (
  {
    sequence,
    top,
    className,
    canvasClassName,
    fit = fitsMap.COVER,
    children,
    finishOnMiddleOfScreen = false,
    startOnMiddleOfScreen = false,
  }: SequenceTypeFrameProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $canvas = useRef<HTMLCanvasElement>(null);
  const startPosition = useRef<number>(0);
  const progress = useRef(0);
  const elementHeight = getSequenceHeight({
    type: 'image',
    numberOfFrames: sequence.frames.length,
    speed: sequence.speed,
  });

  const isSequenceVisible = useIsSequenceVisible($wrapper);

  const currentFrame = useRef<number | null>(null);
  const renderFrame = useRef<
    ((delta?: number | boolean) => void) | typeof noop
  >(noop);

  useLayoutEffect(() => {
    if ($wrapper.current) {
      UIStore.subscribe(
        (state) => state.windowWidth,
        debounce(() => {
          tickerAddOnce(() => {
            const box = $wrapper.current?.getBoundingClientRect();
            if (box) {
              // TODO: like for garage doors, let's see if we can avoid querying the window directly here
              // Actually we also need to get the offset from the garage door if it's part of one
              const start = top || box.top + window.scrollY;

              // TODO: This calculation seems to be off when loading the page with an initial scroll position that is not 0
              startPosition.current = start;
            }
          }, true);
        }, 300),
        {
          fireImmediately: true,
        },
      );
    }
  }, [top]);

  const onProgress = useCallback(
    (currentProgress: number) => {
      progress.current = currentProgress;

      currentFrame.current = Math.max(
        0,
        Math.round((sequence.frames.length - 1) * currentProgress),
      );

      renderFrame.current();
    },
    [sequence.frames?.length],
  );

  useScrollProgress($wrapper, onProgress, {
    finishOnMiddleOfScreen,
    startOnMiddleOfScreen,
  });

  useEffect(() => {
    let mounted = true;
    if ($canvas.current && isSequenceVisible) {
      // We have an empty function here as we need one to be called on unmount, but it's gonna be replaced
      // during the init function if we get to there
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      let cleanUp = () => {};

      initializeCanvas(
        $canvas.current,
        Math.max(
          0,
          Math.round((sequence.frames.length - 1) * progress.current),
        ),
        sequence,
        fit,
        mounted,
        currentFrame,
      ).then((actions) => {
        if (mounted && actions) {
          cleanUp = actions.destroy;
          renderFrame.current = actions.render;
        }
      });

      return () => {
        mounted = false;
        cleanUp();
      };
    }

    return () => {
      mounted = false;
    };
  }, [sequence, fit, isSequenceVisible]);

  return (
    <div
      className={cn(sequenceStyles.container, className)}
      style={
        {
          '--sequence-height': elementHeight,
        } as CSSProperties
      }
      ref={mergeRefs(ref, $wrapper)}
    >
      {children}
      <canvas
        aria-hidden={true}
        className={cn(
          styles.canvas,
          canvasClassName,
          fit === fitsMap.COVER && styles.cover,
        )}
        ref={$canvas}
      />
    </div>
  );
};

export default forwardRef(Frame);
