'use client';
import { gsap } from 'gsap';
import { useCallback, useEffect, useRef } from 'react';

import useUIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import deviceHeroStore from '../StandoutDevice.store';
import {
  CardWrapperProps,
  CardWrapperRef,
} from './CardWrapper/CardWraper.types';
import CardWrapper from './CardWrapper/CardWrapper';
import styles from './InteractiveStack.module.css';
import { InteractiveStackProps } from './InteractiveStack.types';

const InteractiveStack = ({ className, cards }: InteractiveStackProps) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $container = useRef<HTMLDivElement>(null);
  const numCards = cards.length;

  const [windowWidth, windowHeight] = useUIStore((state) => [
    state.windowWidth,
    state.windowHeight,
  ]);

  const sectionRefs = useRef<CardWrapperRef[]>(Array(numCards));
  const progressMap = useRef<{ low: number; high: number }[]>(Array(numCards));

  const onProgress = useCallback((progress: number, isInView: boolean) => {
    deviceHeroStore.setState({ stackProgress: progress });

    sectionRefs.current?.forEach(
      (ref: CardWrapperRef, sectionIndex: number) => {
        ref.setProgress(
          gsap.utils.clamp(
            0,
            1,
            gsap.utils.normalize(
              progressMap.current[sectionIndex].low,
              progressMap.current[sectionIndex].high,
              progress,
            ),
          ),
          isInView,
        );
      },
    );
  }, []);

  useScrollProgress($wrapper, onProgress);

  useEffect(() => {
    // map the ranges for each section so progress can be normalized accordingly
    let index = 0;
    let progressPixels = 0;
    for (const sectionRef of sectionRefs.current) {
      const $sectionElement = sectionRef.$element?.current;
      if ($sectionElement && $wrapper.current && windowHeight) {
        const lockupHeight = $sectionElement.offsetHeight;
        const wrapperHeight = $wrapper.current.offsetHeight;
        const windowLockupGap = (windowHeight - lockupHeight) / 2;
        const totalAmountToMove = wrapperHeight + windowHeight;

        if (index === 0) {
          // the intro section has to travel an additional distance to come to center from offscreen
          const firstLockupStartPixels = 0;
          const firstLockupStartRatio = 0;
          const firstLockupIntroPixels =
            firstLockupStartPixels + lockupHeight + windowLockupGap;
          const firstLockupOutroPixels = firstLockupIntroPixels + lockupHeight;
          const firstLockupCompleteRatio =
            firstLockupOutroPixels / totalAmountToMove;

          progressPixels = firstLockupIntroPixels;
          progressMap.current[index] = {
            low: firstLockupStartRatio,
            high: firstLockupCompleteRatio,
          };
        } else if (index === sectionRefs.current.length - 1) {
          const lockupStartPixels = progressPixels;
          const lockupStartRatio = lockupStartPixels / totalAmountToMove;
          const lockupIntroPixels = lockupStartPixels + lockupHeight;
          const lockupOutroPixels = lockupIntroPixels + lockupHeight;
          const lockupCompleteRatio = lockupOutroPixels / totalAmountToMove;

          progressPixels = lockupIntroPixels;

          progressMap.current[index] = {
            low: lockupStartRatio,
            high: lockupCompleteRatio,
          };
        } else {
          // an in-between section moves vertically one lockupHeight
          const lockupStartPixels = progressPixels;
          const lockupStartRatio = lockupStartPixels / totalAmountToMove;
          const lockupIntroPixels = lockupStartPixels + lockupHeight;
          const lockupOutroPixels = lockupIntroPixels + lockupHeight;
          const lockupCompleteRatio = lockupOutroPixels / totalAmountToMove;

          progressPixels = lockupIntroPixels;

          progressMap.current[index] = {
            low: lockupStartRatio,
            high: lockupCompleteRatio,
          };
        }
      }
      index++;
    }
  }, [windowWidth, windowHeight]);

  return (
    <div className={cn(styles.container, className)} ref={$container}>
      <div ref={$wrapper} className={cn(styles.wrapper)}>
        {cards.map(
          ({ content, layers, _key }: CardWrapperProps, index: number) => {
            return (
              <CardWrapper
                ref={(section: CardWrapperRef) =>
                  addToRefArray({
                    element: section,
                    refArray: sectionRefs,
                    index,
                  })
                }
                key={_key}
                total={numCards}
                index={index}
                className={cn(styles.cardWrapper)}
                content={content}
                layers={layers}
              />
            );
          },
        )}
      </div>
    </div>
  );
};

export default InteractiveStack;
