'use client';
import noop from 'lodash/noop';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import Image from '~/components/atoms/Image/Image';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import { GlowRef } from '~/components/molecules/Glow/Glow.types';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { cn } from '~/utils';

import styles from './InteractiveQuotesSmCarouselSlide.module.css';
import {
  ForwardedInteractiveQuotesSmCarouselSlideSlideRef,
  InteractiveQuotesSmCarouselSlideSlideProps,
} from './InteractiveQuotesSmCarouselSlide.types';

/**
 * Quote Carousel slide item.
 * @param slideIndex Slide index number
 * @param gradientOverlay Selected gradient opacity option, defaults to medium

 * @param logo Image or SVG logo, displayed as eyebrow above the slide text
 * @param text Portable Text content displayed on the slide
 * @param image The slide image
 * @param onClick Callback for when a slide is clicked
 * @param isInView Boolean from intersection observer for whether the carousel is currently in view
 * @param className
 * @example <QuoteCardCarouselSlide slides={slides]/>
 */
const InteractiveQuotesSmCarouselSlide = (
  {
    className,
    eyebrow,
    image,
    isInView,
    onClick = noop,
    slideIndex,
    text,
  }: InteractiveQuotesSmCarouselSlideSlideProps,
  ref: ForwardedRef<ForwardedInteractiveQuotesSmCarouselSlideSlideRef>,
) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $glow = useRef<GlowRef>(null);

  useImperativeHandle(
    ref,
    () => ({
      $element: $wrapper,
      setActive: (active: boolean) => {
        if (active) {
          if ($wrapper.current) {
            $wrapper.current.setAttribute('data-active', `${active}`);
            $wrapper.current.ariaHidden = 'false';
          }
        } else {
          if ($wrapper.current) {
            $wrapper.current.removeAttribute('data-active');
            $wrapper.current.ariaHidden = 'true';
          }
        }
      },
    }),
    [],
  );

  const handleReady = useCallback(
    (element: HTMLImageElement | HTMLVideoElement) => {
      $glow.current?.startGlow(element);
    },
    [],
  );

  const handleClick = () => {
    onClick(slideIndex);
  };

  // Need to memoize this object, otherwise portable text gets re-rendered even with memo
  const memoizedPortableTextOptions = useMemo(() => {
    return {
      block: {
        titles: {
          className: cn(styles.titles),
          title4: {
            className: styles.title4,
            tagName: 'h3' as keyof JSX.IntrinsicElements,
          },
        },
        bodies: {
          className: cn(styles.bodies),
        },
        accents: {
          label: {
            className: cn(styles.label),
          },
        },
      },
      marks: {
        em: {
          className: styles.labelEm,
        },
      },
    };
  }, []);

  const onReady = useCallback(
    (element: HTMLImageElement | HTMLVideoElement) => handleReady(element),
    [handleReady],
  );

  return (
    <div
      className={cn(styles.slide, className)}
      ref={$wrapper}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <Shadow className={styles.slideInnerWrapper}>
        <EnhancedMedia
          overlay={image.overlay}
          className={styles.slideImageWrapper}
        >
          <Image
            className={styles.slideImage}
            source={image.image}
            onReady={onReady}
            fixedAspectRatio={true}
            isDisplayed={isInView}
          />
        </EnhancedMedia>
        {eyebrow && <div className={styles.slideEyebrow}>{eyebrow}</div>}
        <PortableText
          value={text}
          className={styles.slideText}
          options={memoizedPortableTextOptions}
        />
      </Shadow>
    </div>
  );
};

const ForwardedQuoteCarouselSlide = forwardRef(
  InteractiveQuotesSmCarouselSlide,
);

export default ForwardedQuoteCarouselSlide;
