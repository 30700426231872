'use client';
import { gsap } from 'gsap';
import { KeenSliderInstance, useKeenSlider } from 'keen-slider/react';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import { dict } from '~/data/stores/Dictionary';
import { cn, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './PaginationDots.module.css';
import {
  MIN_DOTS,
  PaginationDotsProps,
  PaginationDotsRef,
  SCALE,
} from './PaginationDots.types';

/**
 * Pagination dots, used in conjunction with a keen slider carousel
 * @param activeIndex The active item of the carousel containing the paginagtion
 * @param total Total number of slides
 * @param onClick Callback for clicking a dot
 * @param parentSliderRef Ref to the keen slider instance that the pagination dots correspond to. Passed so the dots can access its current track details.
 * @param className
 * @example <PaginationDots activeIndex={1} total={6} parentSliderRef={sliderInstance}/>
 */
const PaginationDots = (
  {
    className,
    activeIndex = 0,
    total,
    onClick,
    parentSliderRef,
  }: PaginationDotsProps,
  ref: ForwardedRef<PaginationDotsRef>,
) => {
  const refDots = useRef<HTMLButtonElement[]>(Array(total));

  // when there are too many dots to fit into the container space, we use a slider
  const [sliderRef, sliderInstanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.dotItem',
    mode: 'snap',
    drag: false,
    initial: activeIndex,
    loop: parentSliderRef.current?.options.loop,
    slides: {
      perView: 2,
      origin: 'auto',
    },
    created: (slider) => {
      // when the slider is created, position and scale the dots according the parent slider's position
      if (parentSliderRef.current) positionTrack(slider);
    },
  });

  const scaleDots = (slider: KeenSliderInstance, parentPosition: number) => {
    if (total < MIN_DOTS) return;

    const parentTrackLength =
      parentSliderRef.current?.track.details.length || 0;

    // map the parent slider's position to a number between 0 and total slides to get the index of the active dot. this ensures that when multiple slides are visible in the carousel's finished state, we still get the nearest dot index.
    const mappedPosition = gsap.utils.mapRange(
      0,
      parentTrackLength,
      0,
      total - 1,
      parentPosition,
    );

    slider.track.details.slides.forEach((slide, i: number) => {
      const distFromCurrent = i - mappedPosition;

      // currently active slide has dist of 0
      // previous slide has dist of -1
      // next slide has dist of 1
      // prev dots are scaled to 0, unless its the second to last dot

      // when distance is between 0 and -1 (moving from current to prev), scale is between 1 and 0 (or .5 if its the second to last dot)
      // when distance is between 1 and 0 (moving from next to current), scale is between .5 and 1
      const scaleNext = SCALE;
      const scalePrev = i === total - 2 ? SCALE : 0;

      const scale = Math.max(
        0,
        distFromCurrent > 0
          ? gsap.utils.mapRange(0, 1, 1, scaleNext, distFromCurrent)
          : gsap.utils.mapRange(-1, 0, scalePrev, 1, distFromCurrent),
      );

      gsap.set(refDots.current[i], { scale: scale });
    });
  };

  const positionTrack = (slider: KeenSliderInstance) => {
    // the position of the parent slide, % total for looping carousels
    const parentPosition =
      (parentSliderRef.current?.track.details.position || 0) % total;
    // track length of the pagination slider
    const trackLength = slider.track.details.length || 0;

    // map the current position of the parent slider (a number between 0 and the number of slides - 1) to a percentage of the track length
    // the pagination carousel reaches its last position when the second to last slide has been reached so we based it on total - 2
    const relProgress = Math.min(
      trackLength,
      (parentPosition / (total - 2)) * trackLength,
    );

    slider.track.to(relProgress);
    scaleDots(slider, parentPosition);
  };

  useImperativeHandle(
    ref,
    () => ({
      setTrackPosition: () => {
        if (sliderInstanceRef.current) positionTrack(sliderInstanceRef.current);
      },
    }),
    [sliderInstanceRef, positionTrack],
  );

  const onDotClick = (index: number) => {
    if (onClick && typeof onClick === 'function') {
      // this callback should update `activeIndex` prop
      onClick(index);
    }
  };

  if (total < 2) {
    return null;
  }

  return (
    <div
      className={cn(styles.paginationDotsContainer, className)}
      ref={sliderRef}
    >
      <ul className={cn(styles.paginationDotsList)}>
        {[...Array(total)].map((x, i) => (
          <li
            className={cn(
              styles.dotItem,
              activeIndex === i && styles.active,
              'dotItem',
            )}
            key={i}
          >
            <button
              className={styles.dotButton}
              onClick={() => onDotClick(i)}
              aria-label={`${dict('goToSlide')} ${i + 1}`}
              aria-current={activeIndex === i}
            >
              <figure
                className={styles.dotFigure}
                ref={(ref: HTMLButtonElement) => {
                  addToRefArray({
                    element: ref,
                    refArray: refDots,
                    index: i,
                  });
                }}
              />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default forwardRef(PaginationDots);
