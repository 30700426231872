import { pricingPlans } from '@frameio-bs/tokens';

import { BaseButtonProps } from '~/components/atoms/Buttons/Ctas/ButtonBase.types';

export enum PricePeriodValue {
  MONTH = 'month',
  MONTH_ANNUAL_PREPAID = 'monthly-annual-prepaid',
  YEAR = 'year',
}

export type CMSPricePeriod = {
  title: string;
  abbreviation: string;
  value: PricePeriodValue;
};

export type CMSBullet = {
  _key: string;
  title: string;
  description?: string;
};

export type CMSPriceDetails = {
  _key: string;
  price: number;
  pricePeriod: CMSPricePeriod;
  contextLine1: string;
  contextLine2: string;
  signUpButton: {
    button: BaseButtonProps;
  };
};

export type CMSPriceTier = {
  title: string;
};

export type CMSPricingProduct = {
  _key: string;
  tier: CMSPriceTier;
  slug: PricingPlanOption;
  pricesByPeriod: CMSPriceDetails[];
  secondaryTitle?: string;
  description: string;
  topBullets?: CMSBullet[];
  topDescription?: string;
  signUpButton: {
    button: BaseButtonProps;
  };
  secondaryButton: BaseButtonProps;
  detailsTitle: string;
  detailsBullets: CMSBullet[];
};

// https://stackoverflow.com/a/53662389
export type PricingPlanOption = keyof typeof pricingPlans;
export type PricingPlanValue = (typeof pricingPlans)[PricingPlanOption];

export type PricingFeature = {
  title: string;
  description: string;
  limits: {
    plan: PricingPlanOption;
    value: string | boolean | number;
  }[];
};

export type PricingCategory = {
  title: string;
  features: PricingFeature[];
  defaultExpanded: boolean;
};

export type CMSPricing = {
  products: CMSPricingProduct[];
  pricingFeatures: PricingCategory[];
};
