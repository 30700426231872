'use client';
import { gsap } from 'gsap';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import ButtonClose from '~/components/atoms/Buttons/UI/ButtonClose/ButtonClose';
import ButtonPlay from '~/components/atoms/Buttons/UI/ButtonPlay/ButtonPlay';
import Image from '~/components/atoms/Image/Image';
import Video from '~/components/atoms/Video/Video';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import useFocusTrap from '~/utils/useFocusTrap';

const useUIStore = UIStore;

import debounce from 'lodash/debounce';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import PortableText from '~/components/molecules/PortableText/PortableText';
import {
  VIDEO_MODAL_SCROLL_STOP_THRESHOLD,
  VIDEO_MODAL_WHEEL_THRESHOLD,
} from '~/types/decorations';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import { tickerAddOnce } from '~/utils/ticker';

import styles from './Manifesto.module.css';
import { ManifestoProps } from './Manifesto.types';

const Manifesto = (props: ManifestoProps) => {
  const { buttonLabel, className, image, content, video } = props;
  const [playingVideo, setPlayingVideo] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [videoScale, setVideoScale] = useState(1);

  const [
    currentFullscreenVideoSrc,
    isScrollLocked,
    setIsScrollLocked,
    setIsNavigationBarVisible,
  ] = useUIStore(
    (state) => [
      state.currentFullscreenVideoSrc,
      state.isScrollLocked,
      state.setIsScrollLocked,
      state.setIsNavigationBarVisible,
    ],
    shallow,
  );

  const $element = useRef<HTMLDivElement>(null);
  const $video = useRef<VideoRef>(null);
  const $mediaWrapper = useRef<HTMLDivElement>(null);
  const scrollProgressOpacitySetter = useRef<(value: number) => void>();
  const scrollProgressYSetter = useRef<(value: number) => void>();
  const mediaRect = useRef<DOMRect>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const $buttonWrapper = useRef<HTMLDivElement>(null);

  const wheelAmountRef = useRef<number>(0);

  // Opacity animation on scroll progress
  const onElementProgress = (progress: number) => {
    if (scrollProgressOpacitySetter.current) {
      const normalizedProgressOpacity = gsap.utils.clamp(
        0,
        1,
        gsap.utils.normalize(0.7, 1, progress),
      );
      scrollProgressOpacitySetter.current(1 - normalizedProgressOpacity);
    }
    if (scrollProgressYSetter.current) {
      const normalizedProgressY = gsap.utils.clamp(-1, 1, progress);
      scrollProgressYSetter.current(0 - normalizedProgressY * 12);
    }
  };
  useScrollProgress($element, onElementProgress);

  useEffect(() => {
    scrollProgressOpacitySetter.current = gsap.quickSetter(
      $element.current,
      '--scroll-progress-opacity',
    ) as (value: number) => void;
    scrollProgressYSetter.current = gsap.quickSetter(
      $element.current,
      '--scroll-progress-y',
      'vh',
    ) as (value: number) => void;
  }, []);

  // Calculate video element scale required to hide border radius (for animation purposes)
  useLayoutEffect(() => {
    const unsubscribe = UIStore.subscribe(
      (state) => state.windowWidth,
      debounce(() => {
        tickerAddOnce(() => {
          if (!$mediaWrapper.current) return;
          mediaRect.current = $mediaWrapper.current?.getBoundingClientRect();
          if (mediaRect.current) {
            const computedStyle = getComputedStyle($mediaWrapper.current);
            // find the scale value to reach fullscreen
            // inset value + borderRadius on both side
            setVideoScale(
              ((parseInt(computedStyle.inset) +
                parseInt(computedStyle.borderRadius)) *
                2) /
                Math.min(mediaRect.current.height, mediaRect.current.width) +
                1,
            );
          }
        }, true);
      }, 300),
      {
        fireImmediately: true,
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const handleWheel = useCallback((event: WheelEvent) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    wheelAmountRef.current += event.deltaY;
    if (Math.abs(wheelAmountRef.current) > VIDEO_MODAL_WHEEL_THRESHOLD) {
      wheelAmountRef.current = 0;
      setPlayingVideo(false);
    }

    // reset wheel value if scroll is not happening for a certain amount of time
    timeoutRef.current = setTimeout(() => {
      wheelAmountRef.current = 0;
    }, 100);
  }, []);

  // Controls manifesto player and related effects (nav / Scroll lock)
  useEffect(() => {
    const videoEl = $video.current;
    // Play video
    if (playingVideo) {
      videoEl?.restart(true);

      // a11y
      $video.current?.focusControls();

      const onEndMobileFullScreen = () => {
        setPlayingVideo(false);
      };
      // mobile player exit fullscreen
      videoEl?.$player?.addEventListener(
        'webkitendfullscreen',
        onEndMobileFullScreen,
        { once: true },
      );

      if ($mediaWrapper.current) {
        let top = 0;
        // hide nav bar when entering video
        setIsNavigationBarVisible(false);

        // calculate top position of the element to center on scroll
        tickerAddOnce(() => {
          mediaRect.current = $mediaWrapper.current?.getBoundingClientRect();
          top = document.documentElement.scrollTop;
          if (mediaRect.current) {
            top += mediaRect.current.top;
            const centerOffset =
              ((UIStore.getState().windowHeight || 0) -
                mediaRect.current.height) /
              2;
            top -= centerOffset;
          }
        }, true);

        const unsubscribeScroll = ScrollPosition.subscribe(({ y: scrollY }) => {
          if (
            scrollY &&
            scrollY < top + VIDEO_MODAL_SCROLL_STOP_THRESHOLD &&
            scrollY > top - VIDEO_MODAL_SCROLL_STOP_THRESHOLD
          ) {
            setIsScrollLocked(true);
            unsubscribeScroll();
          }
        });

        tickerAddOnce(() => {
          scrollTo({
            top,
            behavior: 'smooth',
          });

          // add another ticker to prevent triggering render on the same frame as the scrollTo (which sometimes would prevent a smooth transition when the scroll was large)
          tickerAddOnce(() => {
            setShowVideo(true);
          });
        });
      }

      return () => {
        videoEl?.$player?.removeEventListener(
          'webkitendfullscreen',
          onEndMobileFullScreen,
        );
      };
    } else {
      // Pause video
      videoEl?.pause();
      setIsScrollLocked(false);
      setIsNavigationBarVisible(true);

      tickerAddOnce(() => {
        setShowVideo(false);
      });
    }
  }, [playingVideo, setIsScrollLocked, setIsNavigationBarVisible, handleWheel]);

  // Add / remove wheel event listener only when video is playing and scroll is locked + not in full screen mode
  useEffect(() => {
    if (!!currentFullscreenVideoSrc && playingVideo) {
      window.removeEventListener('wheel', handleWheel);
    } else if (playingVideo && isScrollLocked) {
      window.addEventListener('wheel', handleWheel);
    }

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [currentFullscreenVideoSrc, playingVideo, isScrollLocked, handleWheel]);

  // Focus trap

  const onFocusTrapKeyDown = useFocusTrap($mediaWrapper);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setPlayingVideo(false);
      }
      if (onFocusTrapKeyDown) onFocusTrapKeyDown(event);
    },
    [setPlayingVideo, onFocusTrapKeyDown],
  );

  useEffect(() => {
    if (showVideo) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, showVideo]);

  return (
    <ModuleWrapper
      className={cn(styles.manifesto, className)}
      ref={$element}
      {...props}
    >
      <div className={styles.container}>
        {/* Media wrapper (Video & background image) */}
        <div
          ref={$mediaWrapper}
          className={cn(styles.mediaWrapper, showVideo && styles.isActive)}
          style={
            {
              '--manifesto-video-scale': videoScale,
            } as CSSProperties
          }
        >
          <div
            className={cn(styles.videoWrapper, showVideo && styles.isActive)}
          >
            <Video
              ref={$video}
              className={styles.video}
              src={video.url}
              isLooping={false}
              isMuted={video.isMuted}
              controls={true}
              playsInline={false}
              forceIsInView={false}
              willAutoplay={false}
              disablePictureInPicture
              aspectRatio={video.aspectRatio}
            />
            <ButtonClose
              className={styles.closeButton}
              onClick={() => setPlayingVideo(false)}
            />
          </div>

          <EnhancedMedia
            overlay={image.overlay}
            className={cn(
              styles.backgroundWrapper,
              showVideo && styles.isHidden,
            )}
          >
            <Image
              source={image.image}
              className={styles.backgroundImage}
              quality={70}
            />
          </EnhancedMedia>
        </div>

        {/* Content wrapper (Texts & video button) */}
        <div
          className={cn(styles.contentWrapper, showVideo && styles.isHidden)}
        >
          {content && (
            <PortableText
              value={content}
              className={styles.text}
              options={{
                block: {
                  bodies: {
                    body: {
                      className: styles.body,
                    },
                  },
                },
              }}
            />
          )}
          {video.videoId && buttonLabel && (
            <div className={styles.buttonWrapper} ref={$buttonWrapper}>
              <button
                className={styles.button}
                onClick={() => {
                  setPlayingVideo(true);
                }}
              >
                <ButtonPlay tag="span" buttonColorScheme="solid" />
                <span className={styles.buttonLabel}>{buttonLabel}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default Manifesto;
