import { breakpoints, glows } from '@frameio-bs/tokens';
import { Vec2 } from 'ogl';
import { CSSProperties, ReactNode, RefObject } from 'react';

export type GlowGradient = {
  startColor: string;
  stopColor: string;
  startPosition: number;
  stopPosition: number;
  orientation: number;
};

export type GlowBreakpoints = Exclude<
  (typeof breakpoints)[number]['name'],
  'xl'
>;

export type GlowRef = {
  startGlow: (element: HTMLImageElement | HTMLVideoElement) => void;
  $wrapper: RefObject<HTMLDivElement>;
};

export type GlowProps = {
  glowImageSrc?: string;
  hasMedia?: boolean;
  className?: string;
  backgroundClassName?: string;
  style?: CSSProperties;
  children?: ReactNode;
  source?: GlowSource;
  debug?: boolean;
  shouldGenerate?: boolean;
};

export type GlowConfig = {
  shapeType: 'rectangle' | 'hexagon';
  shapes: GlowShape[];
  gradient: {
    type: 'radial' | 'linear';
    offset: Vec2 | number[];
    scale: number;
    orientation: number;
  };
};

export type GlowShape = Record<GlowBreakpoints, GlowShapeConfig>;

export type GlowShapeConfig = {
  origin: 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight';
  position: Vec2 | number[];
  scale?: number;
  hidden?: boolean;
};

export type CMSGlowSource = {
  colors?: string[];
  glowType: keyof typeof glows;
  shapesKey: string;
  enabled: boolean;
};

export type CanvasScale = number | Record<GlowBreakpoints, number>;

export type GlowSource = CMSGlowSource;

// Const
export const SHAPE_ORIGIN = {
  topLeft: 0,
  topRight: 1,
  bottomLeft: 2,
  bottomRight: 3,
};

export const SHAPE_SIZES: Record<
  GlowBreakpoints,
  {
    rectangle: number[];
    hexagon: number[];
  }
> = {
  lg: {
    rectangle: [675, 450],
    hexagon: [450, 450],
  },
  md: {
    rectangle: [450, 300],
    hexagon: [320, 320],
  },
  sm: {
    rectangle: [250, 167],
    hexagon: [186, 186],
  },
};

export const SHAPE_TYPE = {
  rectangle: 0,
  hexagon: 1,
};
export const GRADIENT_TYPE = {
  radial: 0,
  linear: 1,
};

export const CANVAS_SCALE = 1.8;
