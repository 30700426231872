'use client';

import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useMemo, useRef, useState } from 'react';

import Graphic from '~/components/atoms/Graphic/Graphic';
import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import { PaginationDotsRef } from '~/components/atoms/Pagination/Dots/PaginationDots.types';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import title6Styles from '~/components/molecules/TextLockups/TextLockupTitle6.module.css';
import { cn, getSpacer, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './Touts.module.css';
import { MOBILE_SIZE_TEXT_LIMIT, Tout, ToutsProps } from './Touts.types';

const spacerValue = getSpacer(24);

const Touts = ({
  className,
  touts,
  isTile = false,
  isColumnLayout = false,
  isMobileSlider,
  tallestWrapperHeight = 0,
}: ToutsProps) => {
  const countClass = styles[`with${touts.length}Touts`];
  const pagination = useRef<PaginationDotsRef>(null);

  // check for touts with longest text, and use it decide wether it spans over 3 or 4 columns on mobile
  const isMobileSmall = useMemo(() => {
    let isMobileSmall = true;
    let maxTextLength = 0;
    // if its a tile, no need for the calculation, it should always be 4 column
    if (isTile) return false;
    touts.forEach((tout) => {
      const body = tout.content.find((block) => block.style === 'body');
      if (body) {
        const text = body.children.map((child) => child.text).join('');
        if (text.length > maxTextLength) {
          maxTextLength = text.length;
        }
      }
    });
    // if the max tout length is more than {{MOBILE_SIZE_TEXT_LIMIT}}, span touts over 4 columns on mobile
    if (maxTextLength > MOBILE_SIZE_TEXT_LIMIT) {
      isMobileSmall = false;
    }
    return isMobileSmall;
  }, [touts, isTile]);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const toutRefs = useRef<HTMLElement[]>(Array(touts.length));

  // Keen slider (mobile only)

  const [sliderRef, instanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    loop: false,
    disabled: !isMobileSlider,
    selector: '.tout',
    slides: {
      origin: 'auto',
      spacing: spacerValue,
      perView: 1,
      number: touts.length,
    },
    detailsChanged() {
      pagination.current?.setTrackPosition();
    },
    slideChanged(slider) {
      setActiveIndex(slider.track.details.rel);
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: true,
      },
    },
  });

  const goToIndex = useCallback(
    (index: number) => {
      instanceRef.current?.moveToIdx(index);
    },
    [instanceRef],
  );

  const Inner = isTile ? Shadow : 'div';
  const tallestGraphicWrapper = `${tallestWrapperHeight / 10}rem`;

  return (
    <div className={cn(styles.touts, className)}>
      <div
        ref={sliderRef}
        className={cn(
          styles.container,
          countClass,
          isTile && styles.toutsOnTiles,
          isColumnLayout && styles.isColumnLayout,
          isMobileSlider && styles.isMobileSlider,
          isMobileSmall && styles.mobileSmallLayout,
        )}
      >
        {touts?.length &&
          touts.map((tout: Tout, index: number) => {
            const isLogo = tout.graphicType === 'logo';
            return (
              <div
                ref={(tout: HTMLDivElement) =>
                  addToRefArray({
                    element: tout,
                    refArray: toutRefs,
                    index,
                  })
                }
                key={tout._key}
                className={cn('tout', styles.toutInnerWrapper)}
              >
                <Inner
                  className={cn(
                    styles.tout,
                    isTile && styles.tile,
                    isTile && styles.shadow,
                  )}
                >
                  {tout?.icon && (
                    <div
                      className={cn(
                        styles.iconWrapper,
                        isLogo && styles.isLogo,
                      )}
                      style={{
                        height: tallestGraphicWrapper,
                      }}
                    >
                      <Graphic {...tout?.icon} className={styles.icon} />
                    </div>
                  )}
                  <PortableText
                    value={tout.content}
                    className={styles.content}
                    options={{
                      block: {
                        titles: {
                          className: cn(title6Styles.titles, styles.titles),
                          title6: {
                            tagName: 'h4',
                          },
                        },
                        accents: {
                          className: styles.accents,
                        },
                        bodies: {
                          className: cn(title6Styles.bodies, styles.bodies),
                        },
                      },
                      types: {
                        'block.buttonGroup': {
                          className: styles.button,
                        },
                      },
                    }}
                  />
                </Inner>
              </div>
            );
          })}
      </div>
      {isMobileSlider && (
        <PaginationDots
          className={styles.paginationDots}
          activeIndex={activeIndex}
          total={touts.length}
          onClick={goToIndex}
          ref={pagination}
          parentSliderRef={instanceRef}
        />
      )}
    </div>
  );
};

export default Touts;
