import { useEffect, useRef } from 'react';

import Graphic from '~/components/atoms/Graphic/Graphic';
import { CMSGraphicProps } from '~/components/atoms/Graphic/Graphic.types';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import useMarquee from '~/utils/useMarquee';

import { LogoRow } from '../LogoWall.types';
import styles from './LogoWallRow.module.css';
import { LogoWallRowProps } from './LogoWallRow.types';

const LogoWallRow = ({ row, isInView, speed = 1 }: LogoWallRowProps) => {
  const $row = useRef<HTMLDivElement>(null);
  const $duplicates = useRef<HTMLDivElement[]>([]);

  const { play, pause } = useMarquee($duplicates, {
    speed: speed,
    deltaThreshold: 150,
    deltaThresholdMobile: 20,
  });

  useEffect(() => {
    if (isInView) {
      play();
    } else {
      pause();
    }
  }, [isInView, play, pause]);

  return (
    <div ref={$row} className={cn(styles.row, isInView && styles.isAnimating)}>
      {row.map(({ key, logos }: LogoRow) => (
        <div
          className={styles.duplicate}
          key={key}
          ref={(element: HTMLDivElement) =>
            addToRefArray({ element, refArray: $duplicates })
          }
        >
          {logos.map((logo: CMSGraphicProps) => (
            <Graphic key={logo._key} {...logo} className={styles.icon} />
          ))}
        </div>
      ))}
    </div>
  );
};
export default LogoWallRow;
