import { KeenSliderInstance } from 'keen-slider/react';
import { MutableRefObject } from 'react';

export type PaginationDotsProps = {
  className?: string;
  /**
   * Total number of slides
   */
  total: number;
  /**
   * The active item of the carousel containing the paginagtion
   */
  activeIndex?: number;
  /**
   * Callback for clicking a dot
   */
  onClick: (index: number) => void;
  /**
   * Ref to the keen slider instance that the pagination dots correspond to. Passed so the dots can access its current track details.
   */
  parentSliderRef: MutableRefObject<KeenSliderInstance | null>;
};

export type PaginationDotsRef = {
  setTrackPosition: () => void;
};

export const MIN_DOTS = 3;
export const SCALE = 0.5;
