'use client';
/**
 * Rounded next and previous arrow buttons for the carousel
 * @param props see UiButtonBase props
 * @param iconDirection Direction of the arrow
 * @param disabled If the button is disabled
 * @example <ButtonCarouselArrow
        className={styles.buttonPrev}
        iconDirection={ButtonArrowDirection.LEFT}
        onClick={scrollPrev}
        disabled={!prevBtnEnabled}
      />
 */
import { ForwardedRef, forwardRef } from 'react';

import SvgArrowCenteredLeft from '~/assets/svg/arrow-centered-left.svg';
import SvgArrowCenteredRight from '~/assets/svg/arrow-centered-right.svg';
import { ForwardedButtonRef } from '~/components/atoms/Buttons/Ctas/Button/Button.types';
import { ButtonCarouselArrowProps } from '~/components/atoms/Buttons/UI/ButtonCarouselArrow/ButtonCarouselArrow.types';
import UIButtonBase from '~/components/atoms/Buttons/UI/UIButtonBase';
import { cn } from '~/utils';

import styles from './ButtonCarouselArrow.module.css';

const ButtonCarouselArrow = (
  props: ButtonCarouselArrowProps,
  ref: ForwardedRef<ForwardedButtonRef>,
) => {
  const { iconDirection, className, disabled, ...rest } = props;

  const renderClasses = cn(
    styles.buttonCarouselArrow,
    disabled && styles.disabled,
    className,
  );

  const innerContent = (
    <>
      {iconDirection === 'left' && (
        <SvgArrowCenteredLeft className={styles.icon} />
      )}
      {iconDirection === 'right' && (
        <SvgArrowCenteredRight className={styles.icon} />
      )}
    </>
  );

  return (
    // TODO: Add carousel button label to dictionary

    <UIButtonBase
      className={renderClasses}
      disabled={disabled}
      {...rest}
      label={
        iconDirection === 'left' ? 'Go to previous slide' : 'Go to next slide'
      }
      iconClassName={styles.buttonIcon}
      ref={ref}
    >
      {innerContent}
    </UIButtonBase>
  );
};

export default forwardRef(ButtonCarouselArrow);
