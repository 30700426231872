import { Pass, Post, Renderer, Transform } from 'ogl';
import { MutableRefObject, RefObject } from 'react';

import { TextBlockPropertiesGroup } from '../../TextLockups/TextLockups.types';
import styles from '../WebglText.module.css';
import OGLText from './OGLText';

/**
 * This is the function responsible for instanciating all the `OGLText` objects,
 */
const draw = ({
  letterGroups,
  renderer,
  $canvasWrapper,
  texts,
  scene,
  distortedPost,
  postParams,
  $textLockupEl,
}: {
  letterGroups: TextBlockPropertiesGroup[];
  renderer: MutableRefObject<Renderer | undefined | null>;
  $canvasWrapper: RefObject<HTMLElement>;
  texts: MutableRefObject<OGLText[]>;
  scene: MutableRefObject<Transform>;
  distortedPost: MutableRefObject<Post | undefined | null>;
  postParams: Partial<Pass>[];
  $textLockupEl: HTMLDivElement;
}) => {
  if (letterGroups && renderer.current && $canvasWrapper.current) {
    // if the text lockup instance has an open quotation mark character (added by the data formatter), the quote's overflow (due to the negative indent) will need to be accounted for in when rendering the block's letters

    const needsOpenQuoteOffset = letterGroups.some((group) =>
      Object.keys(group).includes('openQuoteMark'),
    );

    const $openQuote = letterGroups.find((group) => group?.openQuoteMark)
      ?.openQuoteMark;

    const openQuoteWidth = $openQuote
      ? $openQuote.getBoundingClientRect().width
      : 0;

    for (const letterBlock of letterGroups) {
      const selectedText = letterBlock.parentRef;
      const selectedLetters = letterBlock.letterEls;

      const blockHasOpenQuote =
        Object.keys(letterBlock).includes('openQuoteMark');

      if (selectedLetters) {
        const text = new OGLText({
          gl: renderer.current.gl,
          $parent: $canvasWrapper.current,
          $text: selectedText,
          $letters: selectedLetters,
          blockHasOpenQuote: blockHasOpenQuote,
          openQuoteWidth: needsOpenQuoteOffset ? openQuoteWidth : 0,
          pixelRatio: renderer.current.dpr,
        });

        texts.current.push(text);

        text.plane.setParent(scene.current);
      }
    }

    for (const postParam of postParams) {
      distortedPost.current?.addPass(postParam);
    }

    if ($textLockupEl && distortedPost.current) {
      $textLockupEl.classList.add(styles.canvasRendered);
    }
  }
};

export default draw;
