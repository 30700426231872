import { distance } from '~/utils/math';

import { tickerAddOnce } from '../../ticker';
import {
  InitialMousePositionValue,
  MousePositionSubscriber,
  MousePositionValue,
  MouseVelocity,
} from './MousePosition.types';

class MousePositionSingleton {
  x: InitialMousePositionValue;
  y: InitialMousePositionValue;
  velocity: MouseVelocity;
  time: number | null;
  subscribers: MousePositionSubscriber[];
  boundHandleUpdatePosition: (e: MouseEvent) => void;
  isActive: boolean;

  constructor() {
    this.x = null;
    this.y = null;
    this.velocity = 0;
    this.subscribers = [];
    this.time = null;

    this.boundHandleUpdatePosition = this.handleUpdatePosition.bind(this);

    this.isActive = false;
    if (typeof window !== 'undefined' && window.document) {
      this.addEventListeners();
    }
  }

  addEventListeners() {
    this.isActive = true;
    document.addEventListener('mousemove', this.boundHandleUpdatePosition);
  }

  removeEventListeners() {
    this.isActive = false;
    document.removeEventListener('mousemove', this.boundHandleUpdatePosition);
  }

  handleUpdatePosition(e: MouseEvent) {
    if (this.isActive) {
      tickerAddOnce(() => this.updatePosition(e), true);
    }
  }

  updatePosition(e: MouseEvent) {
    const x = e.clientX;
    const y = e.clientY;
    let velocity = 0;

    if (this.x !== x || this.y !== y) {
      const time = performance.now();

      if (this.time && this.x && this.y) {
        const interval = time - this.time;
        velocity = distance(x, y, this.x, this.y) / interval;
      }

      this.time = time;

      this.setPosition(x, y, velocity);

      for (let i = 0; i < this.subscribers.length; i++) {
        this.subscribers[i]({ x, y, velocity });
      }
    }
  }

  subscribe(
    callback: MousePositionSubscriber,
    { fireImmediately }: { fireImmediately?: boolean } = {},
  ) {
    this.subscribers.push(callback);
    if (fireImmediately && this.x && this.y) {
      callback({ y: this.y, x: this.x, velocity: this.velocity });
    }
    return () => {
      this.subscribers = this.subscribers.filter((cb) => cb !== callback);
    };
  }

  getPosition() {
    return {
      x: this.x,
      y: this.y,
    };
  }

  setPosition(x: MousePositionValue, y: MousePositionValue, v: MouseVelocity) {
    this.x = x;
    this.y = y;
    this.velocity = v;
  }
}

const instance = new MousePositionSingleton();

export default instance;
