'use client';
import { ForwardedRef, forwardRef } from 'react';

import AnimatedSvgArrow from '~/components/atoms/Buttons/AnimatedSvgArrow/AnimatedSvgArrow';
import { cn } from '~/utils';

import styles from './ModalCarouselArrows.module.css';
import { ModalCarouselArrowsProps } from './ModalCarouselArrows.types';

const ModalCarouselArrows = (
  {
    className,
    activeIndex,
    total,
    prevTitle,
    nextTitle,
    handleNext,
    handlePrevious,
  }: ModalCarouselArrowsProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  if (total < 2) {
    return null;
  }

  return (
    <div ref={ref} className={cn(className, styles.modalCarouselArrows)}>
      <div className={styles.innerWrapper}>
        <button
          className={styles.controlsButton}
          disabled={activeIndex === 0}
          onClick={handlePrevious}
        >
          <AnimatedSvgArrow
            direction={'previous'}
            className={styles.controlsButtonArrow}
          />
          {prevTitle && (
            <>
              <span className={styles.controlsButtonTitle}>{prevTitle}</span>
              <span className={styles.controlsDash}>-</span>
            </>
          )}
          <span className={styles.controlsButtonLabel}>Previous</span>
        </button>
        <button
          className={styles.controlsButton}
          disabled={activeIndex === total - 1}
          onClick={handleNext}
        >
          <span className={styles.controlsButtonLabel}>Next</span>
          {nextTitle && (
            <>
              <span className={styles.controlsDash}>-</span>
              <span className={styles.controlsButtonTitle}>{nextTitle}</span>
            </>
          )}
          <AnimatedSvgArrow className={styles.controlsButtonArrow} />
        </button>
      </div>
    </div>
  );
};

export default forwardRef(ModalCarouselArrows);
