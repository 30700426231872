'use client';
import { gsap } from 'gsap';
import {
  KeenSliderInstance,
  TrackDetails,
  useKeenSlider,
} from 'keen-slider/react';
import debounce from 'lodash/debounce';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';

import DeviceWrapper from '~/components/atoms/DeviceWrapper/DeviceWrapper';
import Observer from '~/components/atoms/Observer/Observer';
import Glow from '~/components/molecules/Glow/Glow';
import { GlowRef } from '~/components/molecules/Glow/Glow.types';
import Shadow from '~/components/molecules/Shadow/Shadow';
import useUIStore from '~/state/ui';
import { cn, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import ScreenCarouselSlide from './ScreenCarouselSlide/ScreenCarouselSlide';
import { CMSScreenCarouselSlide } from './ScreenCarouselSlide/ScreenCarouselSlide.types';
import styles from './ScreenCarouselSlidesAndLabels.module.css';
import {
  CMSModuleScreenCarouselSlidesAndLabels,
  rect,
  SLIDE_DURATION,
} from './ScreenCarouselSlidesAndLabels.types';

/**
 * Component that handles the keen slider slider functionality for the Screen carousel.
 * @param slides An array of slide data objects
 * @param className
 * @example <ScreenCarouselSlidesAndLabels slides={slides]/>
 */
const ScreenCarouselSlidesAndLabels = ({
  slides,
}: CMSModuleScreenCarouselSlidesAndLabels) => {
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  // updates on click of label pagination, to set the active label text and to move carousel
  const [clickedLabelIndex, setClickedLabelIndex] = useState(0);
  const [currentSlideTransitionedIndex, setCurrentSlideTransitionedIndex] =
    useState(0);
  const currentTransitionedSlideRef = useRef(0);

  // details for the image carousel, updated on carousel move
  const details = useRef<TrackDetails>();
  // the details for the label carousel, update on carousel move
  const labelSlidesDetails = useRef<TrackDetails>();
  const prevSwipeProgress = useRef<number | null>(null);

  const $slidesViewport = useRef<HTMLDivElement>(null);
  const $slides = useRef<HTMLLIElement[]>([]);
  const $labels = useRef<HTMLDivElement[]>([]);
  const $labelButtons = useRef<HTMLButtonElement[]>([]);
  const $labelsWrapper = useRef<HTMLDivElement>(null);
  const $labelHighlight = useRef<HTMLSpanElement>(null);

  const labelRects = useRef<rect[]>([]);

  const $glows = useRef<GlowRef[]>(Array(slides.length));

  const [isComputedStyleComplete, windowWidth] = useUIStore(
    (state) => [state.isComputedStyleComplete, state.windowWidth],
    shallow,
  );

  // initialize image slider
  const [sliderRef, imageSliderInstance] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.slide',
    created() {
      moveLabelHighlight();
    },
    detailsChanged(slider) {
      if (prevSwipeProgress.current === null) {
        prevSwipeProgress.current = slider.track.details.progress;
      }
      details.current = slider.track.details;
      onImageSliderMove();
    },
    animationEnded: (slider) => {
      prevSwipeProgress.current = null;
      checkCurrentSlide(slider);
    },
  });

  // initialize labels slider
  const [labelsSliderRef, labelsSliderInstance] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.label',
    mode: 'free-snap',

    detailsChanged(slider) {
      labelSlidesDetails.current = slider.track.details;
      moveLabelHighlight();
    },
    slides: {
      perView: 'auto',
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: true,
      },
    },
  });

  const checkCurrentSlide = useCallback(
    (slider: KeenSliderInstance) => {
      const currentIndex = slider.track.details.rel;
      setCurrentSlideTransitionedIndex(currentIndex);
      setClickedLabelIndex(currentIndex);
      currentTransitionedSlideRef.current = currentIndex;
    },
    [setCurrentSlideTransitionedIndex, setClickedLabelIndex],
  );

  useEffect(() => {
    const slider = imageSliderInstance.current;
    if (slider) {
      if (isComputedStyleComplete) {
        slider.update();
        checkCurrentSlide(slider);
      }
    }
  }, [imageSliderInstance, isComputedStyleComplete, checkCurrentSlide]);

  const handleReady = useCallback(
    (element: HTMLImageElement | HTMLVideoElement, slideIndex: number) => {
      $glows.current[slideIndex].startGlow(element);
    },
    [],
  );

  const paginationClick = (e: SyntheticEvent, index: number) => {
    // if it's in between 2 slides / currently animating, prevent re-render (currentSlideIndex will be set on animation complete)
    if (
      imageSliderInstance.current &&
      imageSliderInstance.current.track.details.position % 1 === 0
    ) {
      setClickedLabelIndex(index);
    }

    imageSliderInstance.current?.moveToIdx(index, false, {
      duration: SLIDE_DURATION * 1000,
    });
  };

  function moveSlides() {
    let direction = 1;

    if (!details.current) return;

    const { progress, slides, position } = details.current;
    if (prevSwipeProgress.current && prevSwipeProgress.current < progress) {
      direction = 1;
    } else if (
      prevSwipeProgress.current &&
      prevSwipeProgress.current > progress
    ) {
      direction = -1;
    }

    const dest = direction === 1 ? Math.ceil(position) : Math.floor(position);

    slides.forEach((slide, i) => {
      const overlayOpacity = slide.portion * 100;
      let x;

      if (position - currentTransitionedSlideRef.current === 0) {
        // slider has stopped animating, set all slides to translate 0
        x = 0;
      } else {
        if (
          (i === currentTransitionedSlideRef.current && direction === 1) ||
          (i === dest && direction === -1)
        ) {
          x = -slide.distance * 50;
        } else {
          x = 0;
        }
      }

      gsap.set($slides.current[i], {
        '--slide-overlay-opacity': `${overlayOpacity}%`,
        '--slide-x': `${x}%`,
      });
    });
  }

  // gets the amount that keen slider has translated the labels carousel
  const getTranslatedAmount = useCallback(() => {
    if (labelsSliderInstance.current && labelSlidesDetails.current) {
      return (
        labelsSliderInstance.current?.size *
        labelSlidesDetails.current.slides[0].distance
      );
    } else {
      return 0;
    }
  }, [labelsSliderInstance]);

  // on transitioning slides, set position and width of the active label underline
  const moveLabelHighlight = useCallback(() => {
    if (details.current && labelRects.current.length) {
      const floor = Math.floor(details.current.position);
      const ceil = Math.ceil(details.current.position);

      //get how much keen slider has translated the label slider
      const translatedAmount = getTranslatedAmount();
      if (!labelRects.current[floor] || !labelRects.current[ceil]) return;

      const curWidth = labelRects.current[floor].width;
      const destWidth = labelRects.current[ceil].width;
      const curLeft = labelRects.current[floor].left;
      const destLeft = labelRects.current[ceil].left;

      const left = gsap.utils.mapRange(
        floor,
        ceil,
        curLeft,
        destLeft,
        details.current.position,
      );

      const width = gsap.utils.mapRange(
        floor,
        ceil,
        curWidth,
        destWidth,
        details.current.position,
      );

      gsap.set($labelHighlight.current, {
        '--label-highlight-left': `${left + (translatedAmount || 0)}px`,
        '--label-highlight-width': `${width}px`,
      });
    }
  }, [getTranslatedAmount]);

  function onImageSliderMove() {
    moveSlides();
    moveLabelHighlight();
  }

  const calculateLabelRects = useCallback(() => {
    if (
      $labelsWrapper.current &&
      'current' in $labelsWrapper &&
      $labelButtons.current &&
      'current' in $labelButtons &&
      $labelButtons.current.length > 0
    ) {
      const boundingBoxLeft =
        $labelsWrapper.current?.getBoundingClientRect().left || 0;

      //get how much keen slider has translated the label slider
      const translatedAmount = getTranslatedAmount();

      slides.forEach((slide, i) => {
        labelRects.current[i] = {
          width: $labelButtons.current[i].getBoundingClientRect().width,
          left:
            $labelButtons.current[i].getBoundingClientRect().left -
            boundingBoxLeft -
            translatedAmount,
        };
      });

      moveLabelHighlight();
    }
  }, [slides, moveLabelHighlight, getTranslatedAmount]);

  // get dom calculations on resize
  useEffect(() => {
    const unsubscribe = useUIStore.subscribe(
      (state) => state.windowHeight,
      debounce(() => {
        calculateLabelRects();
        moveLabelHighlight();
      }, 100),

      {
        fireImmediately: true,
      },
    );
    return () => {
      unsubscribe();
    };
  }, [windowWidth, slides, moveLabelHighlight, calculateLabelRects]);

  // when moving or dragging a slider on mobile, move the labels slider to show the active label
  useEffect(() => {
    labelsSliderInstance.current?.moveToIdx(clickedLabelIndex, false, {
      duration: 500,
    });
  }, [clickedLabelIndex, labelsSliderInstance]);

  const renderLabels = (ariaHidden: boolean) => {
    return slides.map((slide: CMSScreenCarouselSlide, labelIndex: number) => (
      <div
        key={slide._key}
        ref={(element) =>
          addToRefArray({
            element,
            refArray: $labels,
            index: labelIndex,
          })
        }
        className={cn(styles.labelButtonWrapper, 'label')}
      >
        <button
          // prevent duplicate aria-label with dummy element
          id={`${slide._key}-label${ariaHidden ? '-dummy' : ''}`}
          aria-hidden={ariaHidden}
          aria-controls={slide._key}
          ref={(element) =>
            addToRefArray({
              element,
              refArray: $labelButtons,
              index: labelIndex,
            })
          }
          className={cn(
            styles.labelButton,
            labelIndex === clickedLabelIndex && styles.active,
          )}
          onClick={(e) => {
            paginationClick(e, labelIndex);
          }}
        >
          {slide.label}
        </button>
      </div>
    ));
  };
  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselDeviceWrapper}>
        <div className={styles.carouselDeviceBorders}>
          <Shadow className={styles.shadow} />
          {/* Glows behind the carousel */}
          <ul className={styles.glows} aria-hidden="true">
            {slides.map((slide: CMSScreenCarouselSlide, glowIndex: number) => {
              return (
                <li
                  key={slide._key}
                  className={cn(
                    styles.glowSlide,
                    glowIndex === currentSlideTransitionedIndex &&
                      styles.active,
                  )}
                >
                  <Glow
                    className={styles.glow}
                    source={slide.media.glow}
                    ref={(glow: GlowRef) =>
                      addToRefArray({
                        element: glow,
                        refArray: $glows,
                        index: glowIndex,
                      })
                    }
                  />
                </li>
              );
            })}
          </ul>
          {/* Slides */}
          <div className={styles.slidesViewport} ref={$slidesViewport}>
            <Observer
              className={styles.slidesInner}
              options={{ rootMargin: '200% 0%' }}
              callback={updateIsInView}
            >
              <ul className={styles.slides} ref={sliderRef}>
                {slides.map(
                  (slide: CMSScreenCarouselSlide, slideIndex: number) => (
                    <li
                      key={slide._key}
                      id={slide._key}
                      className={cn(styles.slide, 'slide')}
                      aria-labelledby={`${slide._key}-label`}
                      ref={(element) =>
                        addToRefArray({
                          element,
                          refArray: $slides,
                          index: slideIndex,
                        })
                      }
                    >
                      <div className={styles.slideInner}>
                        <ScreenCarouselSlide
                          media={slide.media}
                          isActive={
                            slideIndex === currentSlideTransitionedIndex
                          }
                          onReady={handleReady}
                          isInView={isInView !== false}
                          index={slideIndex}
                        />
                      </div>
                    </li>
                  ),
                )}
              </ul>
            </Observer>
          </div>
          <DeviceWrapper
            className={styles.deviceWrapper}
            deviceType="studioDisplay"
            isInView={isInView !== false}
          />
        </div>
      </div>
      <div className={styles.labelsContainer}>
        {/* used to calculate height of labels on sm */}
        <div className={styles.labelsWrapperDummy}>{renderLabels(true)}</div>
        <div
          className={cn(styles.labels, slides.length < 4 && styles.smallSet)}
          ref={labelsSliderRef}
        >
          <div className={styles.labelsWrapper} ref={$labelsWrapper}>
            <span
              className={styles.labelHighlight}
              ref={$labelHighlight}
            ></span>
            {renderLabels(false)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenCarouselSlidesAndLabels;
