'use client';

import { gsap } from 'gsap';
import { useCallback, useEffect, useRef, useState } from 'react';

import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Media from '~/components/molecules/Media/Media';
import PortableText from '~/components/molecules/PortableText/PortableText';
import titleStyles from '~/components/molecules/TextLockups/TextLockupTitle2.module.css';
import useUIStore from '~/state/ui';
const UIStore = useUIStore;
import {
  cn,
  isBreakpointOrGreater,
  isBreakpointOrSmaller,
  useScrollProgress,
} from '~/utils';

import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import Device from './Device/Device';
import DeviceThemeToggle from './DeviceThemeToggle/DeviceThemeToggle';
import InteractiveStack from './InteractiveStack/InteractiveStack';
import Snow from './Snow/Snow';
import { SnowRef } from './Snow/Snow.types';
import styles from './StandoutDevice.module.css';
import deviceHeroStore from './StandoutDevice.store';
import { StandoutDeviceProps } from './StandoutDevice.types';

const StandoutDevice = (props: StandoutDeviceProps) => {
  const { className, background, video: overlay } = props;
  const bgEnhMedia = background?.media;

  const $module = useRef<HTMLDivElement>(null);
  const $container = useRef<HTMLDivElement>(null);
  const $intro = useRef<HTMLDivElement>(null);
  const $titleWrapper = useRef<HTMLDivElement>(null);
  const $deviceWrapper = useRef<HTMLDivElement>(null);
  const $stackWrapper = useRef<HTMLDivElement>(null);
  const $media = useRef<HTMLElement>(null);
  const $deviceContainer = useRef<HTMLDivElement>(null);
  const $snow = useRef<SnowRef>(null);
  const $stackIntroTracker = useRef<HTMLDivElement>(null);

  const breakpoint = useUIStore((state) => state.breakpoint);
  const [isInteractive, setIsInteractive] = useState<boolean>(false);

  useEffect(() => {
    if (!$container.current || !bgEnhMedia?.sanityMedia) {
      return;
    }

    if (bgEnhMedia?.sanityMedia?.mediaType !== 'image') {
      console.warn('background media not set to image');
      return;
    }

    const { asset, hotspot: hot } = bgEnhMedia.sanityMedia;

    if (!hot) {
      console.warn('no hotspot found for device background media');
      return;
    }

    const computeVariables = () => {
      const rect = $deviceContainer.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }
      // on small breakpoints use a fixed scaledown to better fit devices
      let smScaleFactor = 1.0;
      if (isBreakpointOrSmaller(breakpoint, 'sm')) {
        smScaleFactor = 0.75;
      }
      // scale the image according container & hotspot sizes
      const toScale = rect.width / ((hot.width * asset.width) / smScaleFactor);
      const imgWidth = Math.ceil(asset.width * toScale);
      const imgHeight = Math.ceil(asset.height * toScale);
      // compute edges & image offset. for x-axis account for the scale factor
      const imgLeft = -((hot.x - (hot.width / smScaleFactor) * 0.5) * imgWidth);
      const imgTop = -((hot.y - hot.height * 0.5) * imgHeight);
      // update the height of the container so it matches device height
      const containerHeight = hot.height * imgHeight;

      if ($snow.current) {
        const state = UIStore.getState();
        const winWidth = state.windowWidth || window.innerWidth;
        const winHeight = state.windowHeight || window.innerHeight;
        const mScaleW = imgWidth / winWidth;
        const mScaleH = imgHeight / winHeight;

        $snow.current.setMaskScale(mScaleW, mScaleH);
        $snow.current.setMaskPosition(
          ((imgWidth - winWidth) / winWidth) * 0.5,
          (imgHeight - winHeight) / winHeight,
        );
      }

      gsap.set($container.current, {
        '--img-scale': `${toScale}`,
        '--img-width': `${imgWidth}px`,
        '--img-left': `${imgLeft}px`,
        '--img-top': `${imgTop}px`,
        '--container-height': `${containerHeight}px`,
      });
    };

    computeVariables();

    return UIStore.subscribe((state) => [state.windowWidth], computeVariables);
  }, [bgEnhMedia, breakpoint]);

  const checkIsInteractive = useCallback(
    (progress: number, isInView: boolean) => {
      if (progress > 0 && progress < 1 && isInView) {
        !isInteractive && setIsInteractive(true);
      } else {
        isInteractive && setIsInteractive(false);
      }
    },
    [isInteractive],
  );

  useScrollProgress($deviceWrapper, checkIsInteractive);

  useScrollProgress($intro, (progress: number) => {
    deviceHeroStore.setState({ moduleIntroProgress: progress });
  });

  useScrollProgress($stackIntroTracker, (progress: number) => {
    deviceHeroStore.setState({ stackIntroProgress: progress });
  });

  useEffect(() => {
    const title = $titleWrapper.current;
    const device = $deviceWrapper.current;
    const stack = $stackWrapper.current;
    const media = $media.current;
    const { min, max } = Math;

    if (!title || !device || !stack || !media) {
      return;
    }

    return deviceHeroStore.subscribe((state) => {
      const moduleIntroProgress = state.moduleIntroProgress;
      const stackProgress = state.stackProgress;
      const stackIntroProgress = state.stackIntroProgress;
      const ease = 'expo.out';
      let duration = 1;

      if ($snow.current?.$overlay.current) {
        gsap.set($snow.current.$overlay.current, {
          '--bottom-gradient-opacity': stackProgress,
        });
      }

      if (isBreakpointOrSmaller(breakpoint, 'sm')) {
        duration = 0.5;

        // NOTE: the parallax is currently disabled for mobile until we can figure out the scroll position reporting issue. right now, if you flick the screen quickly on the actual phone, there is a delay to when the phone starts reporting scroll values after the section is already in view, which causes a stutter in the parallax.

        gsap.killTweensOf(title);
        gsap.to(title, {
          ease,
          duration,
          y: 0,
          opacity: 1 - min(0.95, max(0.75, moduleIntroProgress) - 0.75) / 0.2,
        });

        gsap.killTweensOf(device);
        gsap.to(device, {
          ease,
          duration,
          y: 0,
          opacity: 1,
        });

        gsap.killTweensOf(stack);
        gsap.to(stack, {
          ease,
          duration,
          y: 0,
          opacity: 1,
        });

        const rect = $media.current?.getBoundingClientRect();
        if (rect) {
          $snow.current?.setMaskOffset(0, rect.top);
        }

        return;
      }

      gsap.killTweensOf(title);
      gsap.to(title, {
        ease,
        duration,
        // move title down to meet with the device
        y: `${moduleIntroProgress * 4}rem`,
      });
      gsap.to(title, {
        ease,
        duration: 0.2,
        // title should fade out just as the device is overtaking it
        opacity: 1 - min(0.95, max(0.75, moduleIntroProgress) - 0.75) / 0.2,
      });

      gsap.killTweensOf(device);
      gsap.to(device, {
        ease,
        duration,
        y: `${
          // move arm upwards to overlap with title
          5 +
          moduleIntroProgress * -10 +
          // continue moving arm once it is sticky
          stackProgress * -10
        }rem`,
        // the arm should fade out between the first and third card centering
        opacity: 1 - (min(0.6, max(0.2, stackProgress)) - 0.2) / 0.4,
        // in order to match the mask to the arm & device we need to sample
        // the position of the media element rectangle
        onUpdate: () => {
          const rect = $media.current?.getBoundingClientRect();
          if (rect && $snow.current) {
            $snow.current.setMaskAlpha(
              Number(gsap.getProperty(device, 'opacity')),
            );
            $snow.current.setMaskOffset(0, rect.top);
          }
        },
      });

      gsap.killTweensOf(stack);
      gsap.to(stack, {
        ease,
        duration,
        y: `${20 - stackIntroProgress * 20}rem`,
      });
    });
  }, [breakpoint]);

  return (
    <ModuleWrapper
      className={cn(className, styles.module)}
      {...props}
      ref={$module}
    >
      <div className={styles.container} ref={$container}>
        <div className={styles.titleWrapper} ref={$titleWrapper}>
          <PortableText
            value={props.title}
            className={styles.title}
            options={{
              block: {
                accents: {
                  className: titleStyles.accents,
                },
                titles: {
                  className: titleStyles.titles,
                  tagName: 'h1',
                },
                bodies: {
                  className: titleStyles.bodies,
                },
              },
              types: {
                className: titleStyles.types,
                'block.buttonGroup': {
                  className: titleStyles.buttons,
                },
                'block.graphic': {
                  className: titleStyles.graphic,
                },
              },
            }}
          />
        </div>
        <div ref={$intro}>
          {/* This element is required to get an accurate reading of intro progress. Tracking the element below does not work as we are transforming it. */}
        </div>
        <div className={styles.deviceWrapper} ref={$deviceWrapper}>
          <Device layer={overlay} isInteractive={isInteractive} />
          <div className={styles.deviceContainer} ref={$deviceContainer}>
            {bgEnhMedia && (
              <EnhancedMedia
                overlay={background.overlay}
                className={styles.enhMedia}
              >
                <Media
                  sanityMedia={bgEnhMedia.sanityMedia}
                  quality={90}
                  controls={false}
                  forceIsInView={true}
                  autoResize={false}
                  willAutoplay={false}
                  isMuted={true}
                  isLooping={true}
                  fixedAspectRatio={true}
                  ref={$media}
                  className={styles.armMedia}
                />
              </EnhancedMedia>
            )}
          </div>
        </div>
        {isBreakpointOrGreater(breakpoint, 'md') && (
          <Snow particles={props.particles} mask={props.mask} ref={$snow} />
        )}
        <div className={styles.stackWrapper} ref={$stackWrapper}>
          <div ref={$stackIntroTracker}></div>
          <InteractiveStack cards={props.cards} />
        </div>
      </div>
      <DeviceThemeToggle />
    </ModuleWrapper>
  );
};

export default StandoutDevice;
