import { useEffect, useRef } from 'react';

import Graphic from '~/components/atoms/Graphic/Graphic';
import { cn, getUniqueId } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import useMarquee from '~/utils/useMarquee';

import styles from './LogoTileRow.module.css';
import { LogoTile, LogoTileRowProps } from './LogoTileRow.types';

const LogoTileRow = ({ row, isInView, speed }: LogoTileRowProps) => {
  const $row = useRef<HTMLDivElement>(null);
  const $duplicates = useRef<HTMLDivElement[]>([]);

  const duplicates = useRef([
    {
      key: getUniqueId('row'),
      row: row.logoTileRowItems,
    },
    {
      key: getUniqueId('row'),
      row: row.logoTileRowItems,
    },
    {
      key: getUniqueId('row'),
      row: row.logoTileRowItems,
    },
  ]);

  const { play, pause } = useMarquee($duplicates, {
    speed: speed || 1,
  });

  useEffect(() => {
    if (isInView) {
      play();
    } else {
      pause();
    }
  }, [isInView, play, pause]);

  return (
    <div ref={$row} className={cn(styles.row, isInView && styles.isAnimating)}>
      {duplicates.current.map(
        ({ key, row }: { key: string; row: LogoTile[] }) => (
          <div
            className={styles.duplicate}
            key={key}
            ref={(element: HTMLDivElement) =>
              addToRefArray({ element, refArray: $duplicates })
            }
          >
            {row.map((logoTile: LogoTile) => (
              <div className={styles.logoTile} key={logoTile._key}>
                <div className={styles.logoWrapper}>
                  <Graphic {...logoTile.logo} className={styles.logo} />
                </div>
                <span className={styles.label}>{logoTile.label}</span>
              </div>
            ))}
          </div>
        ),
      )}
    </div>
  );
};
export default LogoTileRow;
