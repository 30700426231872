import { glows } from '@frameio-bs/tokens';
import { gsap } from 'gsap';
import {
  Mesh,
  OGLRenderingContext,
  Pass,
  Post,
  Program,
  Renderer,
  Triangle,
  Vec2,
} from 'ogl';

import DebugStore from '~/state/debug';
import UIStore from '~/state/ui';
import { Breakpoint } from '~/types';
import { colorToComponent } from '~/utils';
import { tickerAddOnce } from '~/utils/ticker';

import {
  CANVAS_SCALE,
  GlowConfig,
  GlowSource,
  GRADIENT_TYPE,
  SHAPE_ORIGIN,
  SHAPE_SIZES,
  SHAPE_TYPE,
} from '../Glow.types';
import getCurrentAvailableBreakpoint from './getCurrentAvailableBreakpoint';
import {
  blurFragment,
  compositeFragment,
  shapeGradientsFragment,
  vertex,
} from './glsl/shaders';

const DEFAULT_BLUR_DISTANCE = 6;
const BLUR_PASS_COUNT = 12;

// DEBUG purposes toggle to true to disable blur
const DISABLE_BLUR = true;

type RequestProps = {
  $wrapper: HTMLDivElement;
  params: GlowSource;
  targetCanvasContext: CanvasRenderingContext2D;
  breakpoint: Breakpoint | null;
  windowWidth: number | null;
  debug: boolean;
};

// Empty class for SSR and when Webgl is disabled
class GlowWebglFactorySSR {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestGlow() {}
}

class GlowWebglFactoryClientSide {
  $canvas: HTMLCanvasElement;
  renderer: Renderer;
  mesh: Mesh;
  postBlur: Post;
  postComposite: Post;
  compositePass: Pass;
  blurResolution: Vec2;
  isRendering: boolean;
  isProcessingQueue: boolean;
  queue: RequestProps[];
  windowWidth: number | null;
  currentBreakpoint: Breakpoint | null;

  constructor() {
    // sizing
    this.windowWidth = null;
    this.currentBreakpoint = null;

    this.$canvas = document.createElement('canvas');

    // catch context lost issue and prevent browser default behavior
    this.$canvas.addEventListener(
      'webglcontextlost',
      (e) => {
        console.error('Webgl context was lost, hiding all glow canvases', e);
        e.preventDefault();
        UIStore.setState({ webglDisabled: true });
      },
      false,
    );

    this.isRendering = false;
    this.isProcessingQueue = false;

    this.queue = [];

    DebugStore.subscribe((state) => {
      if (state.glowCanvas) {
        this.$canvas.style.position = 'fixed';
        this.$canvas.style.top = '0';
        this.$canvas.style.right = '0';
        this.$canvas.style.zIndex = '1000';
        document.body.appendChild(this.$canvas);
      } else {
        this.$canvas.remove();
      }
    });

    this.renderer = new Renderer({
      canvas: this.$canvas,
      alpha: true,
    });

    const { mesh, postBlur, postComposite, compositePass, blurResolution } =
      this.initializeScene(this.renderer.gl);

    this.mesh = mesh;

    this.postBlur = postBlur;
    this.postComposite = postComposite;
    this.compositePass = compositePass;
    this.blurResolution = blurResolution;
  }

  private initializeScene(gl: OGLRenderingContext) {
    gl.clearColor(0, 0, 0, 1);

    const geometry = new Triangle(gl);
    const program = new Program(gl, {
      vertex,
      fragment: shapeGradientsFragment,
      uniforms: {
        u_resolution: {
          value: [gl.canvas.clientWidth, gl.canvas.clientHeight],
        },
        u_roundness: { value: 10 },
        u_canvas_scale: { value: CANVAS_SCALE },
        u_wrapper_scale: { value: 1 },
        u_shape_type: { value: 1 },
        u_shape_size: { value: [1, 1] },
        // GRADIENT CONFIG
        u_gradient_type: { value: 1 },
        u_gradient_orientation: { value: 0 },
        u_gradient_scale: { value: 1 },
        u_gradient_offset: { value: [0, 0] },
        //  COLOR
        u_color1: { value: [1, 1, 1, 1] },
        u_color2: { value: [0, 1, 0.1, 1] },
        u_color3: { value: [0, 0.1, 1, 1] },
        u_color4: { value: [1, 0, 0, 1] },
        // SHAPE CONFIG
        u_shape1_pos: { value: [0, 0] },
        u_shape2_pos: { value: [-0, -0] },
        u_shape3_pos: { value: [-0, -0] },
        // vec3 for 3 shapes
        // 0 - invisible / 1 - visible
        u_shape_visible: { value: [0, 0, 0] },
        u_shape_scale: { value: [1, 1, 1] },
        u_shape_origin: { value: [0, 1, 2] },
      },
    });

    //  Post process
    // Create composite post
    const postComposite = new Post(gl);

    // `targetOnly: true` prevents post from rendering to canvas
    const postBlur = new Post(gl, { targetOnly: true });
    const blurResolution = new Vec2(this.renderer.width, this.renderer.height);
    // Add gaussian blur passes
    const horizontalPass = postBlur.addPass({
      fragment: blurFragment,
      uniforms: {
        u_resolution: { value: blurResolution },
        u_direction: { value: new Vec2(1, 0) },
        u_blur_distance: {
          value: DEFAULT_BLUR_DISTANCE,
        },
      },
    });
    const verticalPass = postBlur.addPass({
      fragment: blurFragment,
      uniforms: {
        u_resolution: { value: blurResolution },
        u_direction: { value: new Vec2(0, 1) },
        u_blur_distance: {
          value: DEFAULT_BLUR_DISTANCE,
        },
      },
    });

    // Re-add the gaussian blur passes several times to the array to get smoother results
    for (let i = 0; i < BLUR_PASS_COUNT; i++) {
      postBlur.passes.push(horizontalPass, verticalPass);
    }

    const compositePass = postComposite.addPass({
      fragment: compositeFragment,
      uniforms: {
        u_resolution: blurResolution,
      },
    });

    return {
      mesh: new Mesh(gl, {
        geometry,
        program,
      }),
      postBlur,
      postComposite,
      compositePass,
      blurResolution,
    };
  }

  requestGlow({
    $wrapper,
    params,
    targetCanvasContext,
    breakpoint,
    windowWidth,
    debug,
  }: RequestProps) {
    if (
      breakpoint &&
      (!this.currentBreakpoint || this.currentBreakpoint !== breakpoint)
    ) {
      this.currentBreakpoint = breakpoint;
    }

    if (
      windowWidth &&
      (!this.windowWidth || this.windowWidth !== windowWidth)
    ) {
      this.windowWidth = windowWidth;
    }

    this.queue.push({
      $wrapper,
      params,
      targetCanvasContext,
      breakpoint,
      windowWidth,
      debug,
    });
    if (!this.isProcessingQueue) {
      this.processQueue();
    }
  }

  async processQueue() {
    this.isProcessingQueue = true;
    while (this.queue.length > 0) {
      const { $wrapper, params, targetCanvasContext, debug } =
        this.queue.shift() as RequestProps;

      if (params && targetCanvasContext) {
        try {
          await new Promise<void>((resolve, reject) => {
            tickerAddOnce(() => {
              const width = targetCanvasContext.canvas.width;
              const height = targetCanvasContext.canvas.height;

              if (width === 0 || height === 0) {
                reject(
                  'Skipping drawing on canvas, Target canvas has styling issue: width or height is equal to zero',
                );
                return;
              }

              this.renderer.gl.clearColor(0, 0, 0, 1);
              this.renderer.setSize(width, height);
              this.getGlow($wrapper, params, width, height, debug);
              targetCanvasContext.clearRect(0, 0, width, height);
              targetCanvasContext.drawImage(this.$canvas, 0, 0, width, height);
              resolve();
            });
          });
        } catch (e) {
          console.warn(e, $wrapper);
        }
      }
    }
    this.isProcessingQueue = false;
  }

  getGlow(
    $wrapper: HTMLDivElement,
    params: GlowSource,
    width: number,
    height: number,
    debug: boolean,
  ) {
    if (!this.mesh) return;
    const uniforms = this.mesh.program.uniforms;
    uniforms.u_resolution.value = [width, height];
    uniforms.u_resolution.value = [width, height];
    const { colors, glowType, shapesKey } = params;

    // init canvas scale
    let canvasScale = 1;

    const glowTypeShapes = glows[glowType];
    if (!glowTypeShapes) return;

    // Casting to GlowConfig because it's not fetching the object structure from the tokens
    const config: GlowConfig = glowTypeShapes[
      shapesKey as keyof typeof glowTypeShapes
    ] as GlowConfig;

    if (!config) return;

    if (config.gradient) {
      uniforms.u_gradient_offset.value = config.gradient.offset;
      uniforms.u_gradient_scale.value = config.gradient.scale || 1;
      uniforms.u_gradient_type.value =
        // casting because config.gradient.type is a string (from tokens)
        GRADIENT_TYPE[config.gradient.type as keyof typeof GRADIENT_TYPE];
      uniforms.u_gradient_orientation.value = Math.max(
        0.1,
        config.gradient.orientation,
      );
    }

    if (colors) {
      for (let i = 1; i <= 4; i++) {
        uniforms[`u_color${i}`].value = colorToComponent(colors[i - 1]);
      }
    }

    if (config.shapeType) {
      uniforms.u_shape_type.value = SHAPE_TYPE[config.shapeType];
    }

    uniforms.u_shape_visible.value = [0, 0, 0];

    // max offset
    const maxOffset = [0, 0];
    if (config.shapes) {
      config.shapes.forEach((shape, index) => {
        const breakpoint = getCurrentAvailableBreakpoint(
          shape,
          this.currentBreakpoint,
          this.windowWidth,
        );

        if (!breakpoint) return;

        // cast from string to glowshape key since the type from the tokens are too stricts
        const shapeConfig = shape[breakpoint.name as keyof typeof shape];

        uniforms.u_shape_visible.value[index] = shapeConfig.hidden ? 0 : 1;

        if (!shapeConfig.hidden) {
          const sizeBpKey = Object.keys(SHAPE_SIZES).find(
            (bp) =>
              bp === this.currentBreakpoint?.name || bp === breakpoint?.name,
          );
          const shapeSize =
            // cast from string to shapeSize key since the type from the tokens are too stricts
            SHAPE_SIZES[sizeBpKey as keyof typeof SHAPE_SIZES];
          if (shapeSize)
            uniforms.u_shape_size.value = shapeSize[config.shapeType];

          uniforms[`u_shape${index + 1}_pos`].value = shapeConfig.position;

          // looking for the furthest positionned shape's value
          if (
            shapeConfig.position[0] < maxOffset[0] &&
            shapeConfig.position[0] < 0
          ) {
            maxOffset[0] = shapeConfig.position[0];
          }
          if (
            shapeConfig.position[1] < maxOffset[1] &&
            shapeConfig.position[1] < 0
          ) {
            maxOffset[1] = shapeConfig.position[1];
          }

          if (SHAPE_ORIGIN[shapeConfig.origin] !== undefined)
            uniforms.u_shape_origin.value[index] =
              SHAPE_ORIGIN[shapeConfig.origin];
          uniforms.u_shape_scale.value[index] = shapeConfig.scale || 1;
        }
      });
    }

    // calculate scale
    const xScale =
      (Math.max(width, uniforms.u_shape_size.value[0]) +
        Math.abs(maxOffset[0]) * DEFAULT_BLUR_DISTANCE +
        BLUR_PASS_COUNT * DEFAULT_BLUR_DISTANCE * 4) /
      width;
    const yScale =
      (Math.max(height, uniforms.u_shape_size.value[1]) +
        Math.abs(maxOffset[1]) * DEFAULT_BLUR_DISTANCE +
        BLUR_PASS_COUNT * DEFAULT_BLUR_DISTANCE * 4) /
      height;
    canvasScale = Math.max(xScale, yScale);
    const wrapperScale = parseFloat(
      getComputedStyle($wrapper).getPropertyValue('--wrapper-scale'),
    );

    // Canvas scale defines the actual scale of the canvas in comparison to the source image
    uniforms.u_canvas_scale.value = canvasScale;
    // Wrapper scale defines the scale that decides the number of pixels the canvas will be
    uniforms.u_wrapper_scale.value = wrapperScale;
    // setting the glow canvas scale through gsap since the
    gsap.set($wrapper, {
      '--canvas-scale': canvasScale,
    });

    this.renderer.render({ scene: this.mesh });

    if (!DISABLE_BLUR || !debug) {
      this.postComposite.resize();
      this.postBlur.resize();

      this.blurResolution.set(
        this.postBlur.resolutionWidth,
        this.postBlur.resolutionHeight,
      );

      // Disable compositePass pass, so this post will just render the scene for now
      this.compositePass.enabled = false;
      // `targetOnly` prevents post from rendering to the canvas
      this.postComposite.targetOnly = true;
      // This renders the scene to postComposite.uniform.value
      this.postComposite.render({ scene: this.mesh });
      // This render the blur effect's  blur passes to postBloom.fbo.read
      // Passing in a `texture` argument avoids the post initially rendering the scene

      this.postBlur.render({
        texture: this.postComposite.uniform.value,
      });
      // Re-enable composite pass
      this.compositePass.enabled = true;
      // // Allow post to render to canvas upon its last pass
      this.postComposite.targetOnly = false;
      // // This renders to canvas, compositing the blur pass on top
      // // pass back in its previous render of the scene to avoid re-rendering

      this.postComposite.render({
        texture: this.postBlur.uniform.value,
      });
    }
  }
}

const instanciateGlowWebglFactory = () => {
  if (typeof window !== 'undefined') {
    // Make sure WEBGL is supported before initializing the Webgl class
    try {
      const canvas = document.createElement('canvas');
      const supportWebgl =
        !!window.WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));

      if (!supportWebgl) {
        throw new Error(
          'Webgl is not available, Glow Webgl Factory is not instanciated',
        );
      }
      // return Client Side class if window and Webgl is supported
      return new GlowWebglFactoryClientSide();
    } catch (e) {
      UIStore.setState({ webglDisabled: true });
      console.error(e);
    }

    // return SSR class if Webgl is not supported
    return new GlowWebglFactorySSR();
  }

  // return SSR class if on server-side
  return new GlowWebglFactorySSR();
};

export default instanciateGlowWebglFactory();
