import Graphic from '~/components/atoms/Graphic/Graphic';
import { cn } from '~/utils';

import styles from './ChicletItem.module.css';
import { ChicletItemProps } from './ChicletItem.types';

const ChicletItem = ({
  logo,
  className,
  isFeatured,
  isInView,
}: ChicletItemProps) => {
  return (
    <div className={cn(styles.logo, className)}>
      <Graphic
        {...logo.logo}
        name={logo.name}
        className={cn(styles.icon, isFeatured && styles.featured)}
        isInView={isInView}
      />
    </div>
  );
};

export default ChicletItem;
