import Image from '~/components/atoms/Image/Image';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import title55Styles from '~/components/molecules/TextLockups/TextLockupTitle55.module.css';
import { cn } from '~/utils';

import styles from './AsymmetricalBentoCard.module.css';
import { AsymmetricalBentoCardProps } from './AsymmetricalBentoCard.types';

const AsymmetricalBentoCard = ({
  className,
  content,
  image,
  link,
  type,
  isInView,
}: AsymmetricalBentoCardProps) => {
  return (
    <Shadow className={cn(styles.shadow, className, styles.card, styles[type])}>
      <div className={cn(styles.asymmetricalBentoCard, link && styles.hasLink)}>
        <PortableText
          value={content}
          className={styles.content}
          options={{
            block: {
              accents: {
                className: title55Styles.accents,
              },
              titles: {
                className: title55Styles.titles,
              },
              bodies: {
                className: title55Styles.bodies,
              },
            },
          }}
        />
        <div className={styles.imageWrapper}>
          <Image
            source={image.image}
            className={styles.image}
            quality={100}
            fixedAspectRatio={true}
            isDisplayed={isInView}
          />
        </div>
      </div>
    </Shadow>
  );
};

export default AsymmetricalBentoCard;
