const shader = /* glsl */ `
#ifdef GL_ES
  precision mediump float;
  precision mediump int;
#endif

  uniform sampler2D uTexture;
  uniform float     uTime;
  uniform float     uDT;
  uniform vec3      uColorMultiplier;

  varying vec4  vRandom;
  varying vec2  vParticleIndex;
  varying float vParticleLife;
  varying float vParticleAlpha;

  mat2 rotate(float a) {
    float s = sin(a), c = cos(a);
    return mat2(
      c, -s,
      s,  c
    );
  }

  void main() {
    vec2 center = vec2(0.5);
    mat2 r = rotate(uTime * 0.001);
    vec2 uv = (r * (gl_PointCoord.xy - center) + center) * 0.25 + vParticleIndex;

    gl_FragColor = texture2D(uTexture, uv);
    gl_FragColor.rgb *= uColorMultiplier;
    gl_FragColor.a *= vParticleAlpha;
  }
`;

export default shader;
