import Graphic from '~/components/atoms/Graphic/Graphic';
import PortableText from '~/components/molecules/PortableText/PortableText';
import { cn } from '~/utils';

import styles from './Announcement.module.css';
import { AnnouncementProps } from './Announcement.types';

/**
 * Speedbump - Announcement variant
 * @param content Portable Text content
 * @param logo SVG content coming from Sanity, used only on certain layouts
 * @param variantName (From formatter) Based on character count of the body block of the content, used to determine the number of columns automatically
 */
const Announcement = ({
  content,
  logo,
  variantName,
  className,
}: AnnouncementProps) => (
  <div
    className={cn(
      className,
      styles.announcement,
      styles[variantName],
      typeof logo === 'undefined' && styles.noLogo,
    )}
  >
    <Graphic {...logo} className={styles.logo} />
    <PortableText
      value={content}
      className={styles.content}
      options={{
        block: {
          bodies: {
            body: {
              className: styles.paragraph,
            },
          },
          accents: {
            eyebrow: {
              className: styles.eyebrow,
            },
          },
        },
      }}
    />
  </div>
);

export default Announcement;
