const getSequenceHeight = ({
  numberOfFrames,
  videoDuration,
  speed = 1,
  type = 'image',
}: {
  speed?: number;
} & (
  | {
      type: 'video';
      numberOfFrames?: never;
      videoDuration: number;
    }
  | {
      type: 'image';
      numberOfFrames: number;
      videoDuration?: never;
    }
)) => {
  if (type === 'image' && numberOfFrames) {
    return numberOfFrames * speed;
  } else if (videoDuration) {
    return videoDuration * speed * 30;
  }
  return 0;
};

export default getSequenceHeight;
