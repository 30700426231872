'use client';
import { gsap } from 'gsap';
import { useCallback, useRef, useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from './ChicletConstellation.module.css';
import { ChicletConstellationProps } from './ChicletConstellation.types';
import ChicletItem from './ChicletItem/ChicletItem';
import { CMSChicletItem } from './ChicletItem/ChicletItem.types';

const ChicletConstellation = (props: ChicletConstellationProps) => {
  const { chiclets, className } = props;
  const $wrapper = useRef<HTMLDivElement>(null);
  const $placeholder = useRef<HTMLDivElement>(null);
  const progressSetter = useRef<(value: number) => void>();
  const onProgress = useCallback((progress: number) => {
    if (progressSetter.current) progressSetter.current(progress);
  }, []);
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  useScrollProgress($placeholder, onProgress);

  useLayoutEffect(() => {
    progressSetter.current = gsap.quickSetter(
      $wrapper.current,
      '--constellation-progress',
    ) as (value: number) => void;
  }, []);

  return (
    <ModuleWrapper
      ref={$wrapper}
      className={cn(styles.chicletConstellation, className)}
      {...props}
    >
      <div ref={$placeholder} className={styles.placeholder}></div>
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '100% 0%' }}
        className={cn(styles.chiclets)}
      >
        {chiclets.map((logo: CMSChicletItem) => {
          return (
            <div key={logo._key} className={styles.chicletItem}>
              <ChicletItem logo={logo} isInView={isInView !== false} />
            </div>
          );
        })}
      </Observer>
    </ModuleWrapper>
  );
};

export default ChicletConstellation;
