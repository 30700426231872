'use client';
import { gsap } from 'gsap';
import { ReactElement, ReactNode, useCallback, useRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import ConditionalInteractiveWrapper from '~/components/molecules/ConditionalInteractiveWrapper/ConditionalInteractiveWrapper';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Glow from '~/components/molecules/Glow/Glow';
import Shadow from '~/components/molecules/Shadow/Shadow';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import Announcement from './layouts/Announcement/Announcement';
import CallToAction from './layouts/CallToAction/CallToAction';
import SocialProof from './layouts/SocialProof/SocialProof';
import styles from './SpeedBump.module.css';
import { SpeedbumpProps } from './SpeedBump.types';

const intMaxParallax = parseInt(styles.maxParallax);
const intMaxParallaxSm = parseInt(styles.maxParallaxSm);

/**
 * Speedbump module
 * @param content PortableText content
 * @param image Image data coming from Sanity, used in the background of the component
 * @param layout SPEEDBUMP_LAYOUT, will determine which sub-component to use to render the content properly
 * @param logo SVG content coming from Sanity, used only on certain layouts
 * @param characterCount The character count of the main block in content, used to determine the variant used in certain layouts
 * @param gradientOverlay String value for the name of gradient overlay that sits on top of the background image

*/
const SpeedBump = (props: SpeedbumpProps) => {
  const { className, content, image, layout, logo, variantName, linkTo } =
    props;

  const $element = useRef<HTMLDivElement>(null);
  const $background = useRef<HTMLDivElement>(null);

  const progressSetter = useRef<(value: number) => void>();

  useLayoutEffect(() => {
    if (typeof $background.current !== 'undefined') {
      progressSetter.current = gsap.quickSetter(
        $background.current,
        'y',
        'rem',
      ) as (value: number) => void;
    }
  });

  const onProgress = useCallback((progress: number, isInView: boolean) => {
    if (isInView) {
      if (progressSetter.current) {
        const roundedProgress = Math.round(progress * 1000) / 1000;
        progressSetter.current(
          roundedProgress *
            (UIStore.getState().breakpoint?.name === 'sm'
              ? intMaxParallaxSm
              : intMaxParallax) *
            -1,
        );
      }
    }
  }, []);

  useScrollProgress($element, onProgress);

  const InteractiveWrapper = ({
    children,
    className,
  }: {
    children: ReactNode;
    className: string;
  }) => (
    <ConditionalInteractiveWrapper linkTo={linkTo} className={className}>
      {children}
    </ConditionalInteractiveWrapper>
  );

  const SpeedbumpWrapper:
    | keyof JSX.IntrinsicElements
    | ((props: { children: ReactElement; className: string }) => ReactElement) =
    layout !== 'callToAction'
      ? ({
          children,
          className,
        }: {
          children: ReactElement;
          className: string;
        }) => (
          <InteractiveWrapper className={styles.wrapper}>
            <EnhancedMedia
              overlay={image.overlay}
              overlayClassName={styles.overlay}
              className={className}
            >
              {children}
            </EnhancedMedia>
          </InteractiveWrapper>
        )
      : ({
          children,
          className,
        }: {
          children: ReactElement;
          className: string;
        }) => (
          <InteractiveWrapper className={className}>
            {children}
          </InteractiveWrapper>
        );

  let Wrapper: keyof JSX.IntrinsicElements | typeof Glow = 'div';

  let glowWrapperProps = {};

  if (image.glow) {
    Wrapper = Glow;
    glowWrapperProps = {
      backgroundClassName: styles.glowBackground,
      source: image.glow && {
        ...image.glow,
      },
    };
  }

  return (
    <ModuleWrapper
      className={cn(styles.speedbump, styles[layout], className)}
      ref={$element}
      {...props}
    >
      <div className={styles.noSpacerWrapper}>
        <SpeedbumpWrapper className={styles.container}>
          <Wrapper className={styles.layoutContainer} {...glowWrapperProps}>
            <Shadow className={styles.shadow} />
            {layout === 'announcement' && (
              <Announcement
                logo={logo}
                content={content}
                variantName={variantName}
                className={styles.content}
              />
            )}
            {layout === 'socialProof' && (
              <SocialProof
                logo={logo}
                content={content}
                variantName={variantName}
                className={styles.content}
              />
            )}
            {layout === 'callToAction' && (
              <CallToAction
                content={content}
                className={styles.content}
                variantName={variantName}
              />
            )}
            <div className={styles.backgroundContainer}>
              <Image
                source={image.image}
                className={styles.background}
                ref={$background}
                // If we have a button of type Drift, it will re-render as soon as the external
                // lib is loaded, so we disable the image animation in order to prevent a flash
                animated={linkTo?.originalButton._type !== 'driftButton'}
                fixedAspectRatio={true}
              />
            </div>
          </Wrapper>
        </SpeedbumpWrapper>
      </div>
    </ModuleWrapper>
  );
};

export default SpeedBump;
