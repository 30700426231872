'use client';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import PortableText from '~/components/molecules/PortableText/PortableText';
import {
  textLockup2,
  textLockup5,
} from '~/components/molecules/TextLockups/TextLockups.config';
import Touts from '~/components/molecules/Touts/Touts';
import { cn } from '~/utils';

import MultiImageMediaContainer from './MultiImageMediaContainer/MultiImageMediaContainer';
import styles from './SideBySideV4.module.css';
import { SideBySideV4Props, SideBySideV4Ref } from './SideBySideV4.types';

const SideBySideV4 = (
  props: SideBySideV4Props,
  ref: ForwardedRef<SideBySideV4Ref>,
) => {
  const { className, content, media, title, touts } = props;

  const mediaRef = useRef<HTMLElement | VideoRef>(null);

  useImperativeHandle(
    ref,
    () => ({
      mediaRef,
    }),
    [],
  );

  return (
    <div className={cn(styles.sideBySide, className)}>
      <div className={cn(styles.contentWrapper)}>
        {(title.length > 0 || content) && (
          <>
            <div className={styles.titleContentWrapper}>
              {title.length > 0 && (
                <PortableText
                  value={title}
                  options={textLockup2().options}
                  className={cn(styles.content, styles.titleContent)}
                />
              )}
            </div>
            <div className={styles.bodyContentWrapper}>
              {content && (
                <PortableText
                  value={content}
                  options={textLockup5().options}
                  className={cn(
                    styles.content,
                    styles.bodyContent,
                    styles.contentBlock,
                  )}
                />
              )}
            </div>
          </>
        )}
        {touts && (
          <div className={cn(styles.toutsWrapper, styles.isTablet)}>
            <Touts className={styles.touts} {...touts} isColumnLayout={true} />
          </div>
        )}
      </div>
      <MultiImageMediaContainer media={media} />
      {touts && (
        <div className={styles.contentWrapper}>
          <div className={cn(styles.toutsWrapper, styles.isMobile)}>
            <Touts className={styles.touts} {...touts} isColumnLayout={true} />
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef<SideBySideV4Ref, SideBySideV4Props>(SideBySideV4);
