'use client';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import SvgPlusIcon from '~/assets/svg/plus-icon.svg';
import ModalCarouselArrows from '~/components/atoms/CarouselControls/ModalCarouselArrows/ModalCarouselArrows';
import Image from '~/components/atoms/Image/Image';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { InteractiveQuoteCard } from '~/components/organisms/modules/InteractiveQuotes/InteractiveQuotes.types';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import {
  modalEnterTimeline,
  modalLeaveTimeline,
} from './InteractiveQuotesLgModalContent.animation';
import styles from './InteractiveQuotesLgModalContent.module.css';
import {
  InteractiveQuotesLgModalContentProps,
  InteractiveQuotesLgModalContentRef,
} from './InteractiveQuotesLgModalContent.types';

const InteractiveQuotesLgModalContent = (
  {
    carouselIndex,
    sourceRect,
    quoteCards,
    handleNext,
    handlePrevious,
    startClosingModal,
  }: InteractiveQuotesLgModalContentProps,
  ref: ForwardedRef<InteractiveQuotesLgModalContentRef>,
) => {
  // Dummy div used to compute in grid size
  const $dummy = useRef<HTMLDivElement>(null);
  const $shadow = useRef<HTMLDivElement>(null);
  const $closeButton = useRef<HTMLButtonElement>(null);
  const $modalControls = useRef<HTMLDivElement>(null);
  const $modalContent = useRef<HTMLDivElement>(null);
  const $carouselItems = useRef<HTMLLIElement[]>(Array(quoteCards.length));

  useImperativeHandle(
    ref,
    () => {
      return {
        enterTimeline: () =>
          modalEnterTimeline({
            carouselIndex,
            $dummy,
            $shadow,
            $modalContent,
            $modalControls,
            $closeButton,
            $carouselItems,
            sourceRect,
          }),
        leaveTimeline: () =>
          modalLeaveTimeline({
            $modalContent,
            $shadow,
          }),
      };
    },
    [carouselIndex, sourceRect],
  );

  return (
    <>
      <div
        aria-hidden={true}
        className={cn(styles.modalBackground)}
        onClick={startClosingModal}
      />
      <div ref={$dummy} aria-hidden="true" className={styles.dummy}></div>
      <Shadow ref={$shadow} className={styles.modalShadow} />

      <div
        ref={$modalContent}
        aria-modal="true"
        role="dialog"
        className={styles.modalContent}
      >
        <ul
          className={cn(styles.carousel)}
          style={{ '--carousel-index': carouselIndex } as CSSProperties}
        >
          {quoteCards.map((quoteCard: InteractiveQuoteCard, index: number) => {
            const isActive = carouselIndex === index;

            return (
              <li
                ref={(node: HTMLLIElement) =>
                  ($carouselItems.current[index] = node)
                }
                className={styles.carouselItem}
                key={quoteCard._key}
                aria-hidden={isActive ? 'false' : 'true'}
              >
                <EnhancedMedia
                  overlay={quoteCard.image.overlay}
                  className={styles.quoteCardMedia}
                >
                  <Image
                    className={styles.quoteCardMediaImg}
                    source={quoteCard.image.image}
                    autoResize={false}
                    animated={false}
                    fixedAspectRatio={true}
                  />
                </EnhancedMedia>

                <div className={styles.quoteCardContent}>
                  <span className={styles.quoteCardTitle}>
                    {quoteCard.eyebrow}
                  </span>

                  <PortableText
                    className={styles.quoteCardText}
                    value={quoteCard.text}
                    options={{
                      block: {
                        accents: {
                          className: styles.accents,
                          label: {
                            className: styles.label,
                          },
                        },
                        titles: {
                          className: styles.titles,
                        },
                        bodies: {
                          className: styles.bodies,
                        },
                      },
                      marks: {
                        em: {
                          className: styles.emWithDash,
                        },
                      },
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
        <button
          ref={$closeButton}
          className={styles.closeButton}
          onClick={startClosingModal}
        >
          <SvgPlusIcon className={styles.buttonIcon} />
          <span className="visuallyHidden">{dict('clickToClose')}</span>
        </button>

        <ModalCarouselArrows
          ref={$modalControls}
          activeIndex={carouselIndex}
          className={styles.modalCarouselArrows}
          total={quoteCards.length}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          prevTitle={quoteCards[(carouselIndex || 0) - 1]?.eyebrow}
          nextTitle={quoteCards[(carouselIndex || 0) + 1]?.eyebrow}
        />
      </div>
    </>
  );
};

export default forwardRef(InteractiveQuotesLgModalContent);
