'use client';

import { useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import AsymmetricalBentoCard from './AsymmetricalBentoCard/AsymmetricalBentoCard';
import styles from './AsymmetricalBentos.module.css';
import { BentoProps } from './AsymmetricalBentos.types';

const AsymmetricalBentos = (props: BentoProps) => {
  const { cards, className, variant } = props;
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  return (
    <ModuleWrapper className={cn(styles.bento, className)} {...props}>
      <Observer
        className={cn(styles.cardsGrid, styles[variant])}
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
      >
        {cards.map((card) => (
          <AsymmetricalBentoCard
            {...card}
            key={card._key}
            className={styles.cardItem}
            isInView={isInView !== false}
          />
        ))}
      </Observer>
    </ModuleWrapper>
  );
};

export default AsymmetricalBentos;
