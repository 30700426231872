const shader = /* glsl */ `
#ifdef GL_ES
  precision mediump float;
  precision mediump int;
#endif

  uniform sampler2D tMap;
  uniform sampler2D uMask;
  uniform float     uMaskAlpha;
  uniform vec2      uMaskScale;
  uniform vec2      uMaskPosition;
  uniform vec2      uMaskOffset;
  uniform vec2      uResolution;

  varying vec2 vUv;

  void main() {
    vec2 maskUv = (vUv + uMaskPosition + uMaskOffset) / uMaskScale;
    vec4 mask = texture2D(uMask, maskUv);
    vec4 tex = texture2D(tMap, vUv);

    // multiply by the mask alpha and allow the mask to fade out such that
    // the background layer correctly comes through
    tex.a = tex.a * mask.a * uMaskAlpha + (tex.a * (1.0 - uMaskAlpha));

    gl_FragColor = tex;
  }
`;

export default shader;
