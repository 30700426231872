// get comprehensive dimensions relative to root or parent

import getRootOffset from './getRootOffset';

const getRootOffsetBounds = (el: HTMLElement, scrollContainer = window) => {
  if (el && scrollContainer) {
    const rootOffset = getRootOffset(el, scrollContainer);
    const offsetTop = rootOffset.top;
    const offsetLeft = rootOffset.left;

    const { offsetWidth, offsetHeight } = el;

    const bounds = {
      top: offsetTop,
      bottom: offsetTop + offsetHeight,
      left: offsetLeft,
      right: offsetLeft + offsetWidth,
      width: offsetWidth,
      height: offsetHeight,
      centerX: offsetLeft + offsetWidth / 2,
      centerY: offsetTop + offsetHeight / 2,
    };

    return bounds;
  }

  return null;
};

export default getRootOffsetBounds;
