import { create } from 'zustand';

import { DeviceTheme } from './StandoutDevice.types';

type DeviceHeroStore = {
  theme: DeviceTheme;
  setTheme: (theme: DeviceTheme) => void;

  moduleIntroProgress: number;
  moduleProgress: number;
  stackProgress: number;
  stackIntroProgress: number;
  scrollInertia: number;
};

const deviceHeroStore = create<DeviceHeroStore>((set) => ({
  theme: 'dark',
  setTheme: (theme: DeviceTheme) => set({ theme }),

  moduleIntroProgress: 0,
  moduleProgress: 0,
  stackProgress: 0,
  stackIntroProgress: 0,
  scrollInertia: 0,
}));

export default deviceHeroStore;
