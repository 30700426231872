'use client';
import { gsap } from 'gsap';
import { debounce } from 'lodash';
import { Pass, Vec2 } from 'ogl';
import { useCallback, useEffect, useRef } from 'react';

import {
  textLockup1,
  textLockup2,
} from '~/components/molecules/TextLockups/TextLockups.config';
import textLockup1Styles from '~/components/molecules/TextLockups/TextLockupTitle1.module.css';
import textLockup2Styles from '~/components/molecules/TextLockups/TextLockupTitle2.module.css';
import WebglText from '~/components/molecules/WebglText/WebglText';
import { WebglTextRef } from '~/components/molecules/WebglText/WebglText.types';
import UIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';
import getOverlap from '~/utils/getOverlap';
import isNotSmall from '~/utils/isNotSmall';
import { tickerAddOnce } from '~/utils/ticker';

import Tube from '../../../molecules/Tube/Tube';
import { TubeRef } from '../../../molecules/Tube/Tube.types';
import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import styles from './DistortedText.module.css';
import { DistortedTextProps } from './DistortedText.types';
import passFragment from './shaders/passFragment';

/**
 * Distorted text module, displays a text in webgl, and applies a visual distortion
 * as an horizontal tube overlaps with it. Requires a global tube asset to be present
 * in the CMS
 */
const DistortedText = (props: DistortedTextProps) => {
  const tubeRef = useRef<TubeRef>(null);

  const translateProgress = useRef(0);
  const windowHeight = useRef(0);
  const overlap = useRef(0);
  const pixelRatio = useRef(1);

  const webglText = useRef<WebglTextRef>(null);
  const tubeTl = useRef<GSAPTimeline | null>(null);

  const { className, content } = props;

  const $component = useRef<HTMLDivElement>(null);

  const uniforms = useRef<{
    uResolution: Vec2;
    uTubeHeight: number;
    uTubeTop: number;
    uHorizontalStretch: number;
  }>({
    uResolution: new Vec2(0, 0),
    uTubeHeight: 0,
    uTubeTop: 0,
    uHorizontalStretch: 0.15,
  });

  let isTitle1 = false;
  if (content) {
    for (const item of content) {
      if (item.style === 'title1') {
        isTitle1 = true;
        break;
      }
    }
  }

  const richTextLockupStyles = isTitle1 ? textLockup1Styles : textLockup2Styles;
  const textLockup1Config = textLockup1();
  const textLockup2Config = textLockup2();
  let richTextLockupOptions = isTitle1
    ? textLockup1Config.options
    : textLockup2Config.options;

  richTextLockupOptions = {
    ...richTextLockupOptions,
    block: {
      ...richTextLockupOptions.block,
      titles: {
        ...richTextLockupOptions.block?.titles,
        title2: {
          ...richTextLockupOptions.block?.titles?.title2,
          className: cn(
            richTextLockupOptions.block?.titles?.title2?.className,
            styles.title2,
          ),
        },
      },
      accents: {
        ...richTextLockupOptions.block?.accents,
        className: cn(
          richTextLockupOptions.block?.accents?.className,
          styles.accents,
        ),
      },
    },
    marks: {
      ...richTextLockupOptions.marks,
      emWithDash: {
        ...richTextLockupOptions.marks?.emWithDash,
        className: styles.emWithDash,
      },
      openQuote: {
        ...richTextLockupOptions.marks?.openQuote,
        className: styles.openQuote,
      },
    },
  };

  const translateFactor = () =>
    isNotSmall() ? -720 * (1 / windowHeight.current) : -0.7;

  const horizontalStretch = 0.03;

  const update = () => {
    if (!tubeRef.current) return;
    gsap.set(tubeRef.current.$container.current, {
      y: translateProgress.current * windowHeight.current * translateFactor(),
    });
    uniforms.current.uTubeTop = overlap.current * pixelRatio.current;
    webglText.current?.updatePassesUniforms(uniforms.current);
    webglText.current?.forceRender();
  };

  const onProgress = useCallback((progress: number, isInView: boolean) => {
    if (isInView) {
      translateProgress.current = progress;
      tickerAddOnce(() => {
        if (
          webglText.current?.$canvasWrapper.current &&
          tubeRef.current?.$tubeSizer.current
        ) {
          const currentOverlap = getOverlap(
            webglText.current.$canvasWrapper.current,
            tubeRef.current?.$tubeSizer.current,
          );
          overlap.current = currentOverlap;
          update();
        }
      });

      if (tubeTl.current === null && tubeRef.current) {
        tubeTl.current = gsap.timeline();
        const ballAnimation = tubeRef.current.play({
          repeat: -1,
          repeatDelay: 2,
        });
        if (ballAnimation) {
          tubeTl.current.add(ballAnimation);
        }
      }
    } else if (!isInView && tubeTl.current) {
      tubeTl.current.kill();
      tubeTl.current = null;
    }
    // update isn't supposed to be a dependency as it's not reactive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollProgress($component, onProgress, { shouldAlwaysComplete: false });

  const updateUniforms = () => {
    if (
      webglText.current?.$canvasWrapper.current &&
      tubeRef.current?.$tubeSizer.current
    ) {
      const tubeHeight =
        tubeRef.current.$tubeSizer.current.getBoundingClientRect().height;
      const canvasRect =
        webglText.current.$canvasWrapper.current.getBoundingClientRect();
      uniforms.current = {
        uTubeHeight: tubeHeight * pixelRatio.current,
        uResolution: new Vec2(
          canvasRect.width * pixelRatio.current,
          canvasRect.height * pixelRatio.current,
        ),
        uTubeTop: 0,
        uHorizontalStretch: horizontalStretch,
      };
      webglText.current?.updatePassesUniforms(uniforms.current);
    }
  };

  useEffect(() => {
    windowHeight.current = UIStore.getState().windowHeight || 0;
    pixelRatio.current = window.devicePixelRatio;

    updateUniforms();

    const debouncedResize = debounce(() => {
      updateUniforms();

      windowHeight.current = UIStore.getState().windowHeight || 0;
    }, 10);

    const unsubscribe = UIStore.subscribe(
      (state) => state.windowWidth,
      debouncedResize,
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const postParams: Partial<Pass>[] = [
    {
      fragment: passFragment,
      uniforms: {
        uResolution: {
          value: uniforms.current.uResolution,
        },
        uTubeHeight: {
          value: uniforms.current.uTubeHeight,
        },
        uTubeTop: {
          value: uniforms.current.uTubeTop,
        },
        uHorizontalStretch: {
          value: uniforms.current.uHorizontalStretch,
        },
      },
    },
  ];

  return (
    <ModuleWrapper
      className={cn(styles.distortedText, className)}
      {...props}
      ref={$component}
    >
      <WebglText
        content={content}
        options={richTextLockupOptions}
        className={cn(
          textLockup2Config.wrapperClass,
          richTextLockupStyles.alignCenter,
          styles.content,
        )}
        ref={webglText}
        postParams={postParams}
      />
      <Tube
        ref={tubeRef}
        type={'tube1'}
        className={styles.tube}
        autoPlayWhenInView={false}
      />
    </ModuleWrapper>
  );
};

export default DistortedText;
