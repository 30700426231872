/**
 * Checks if a point is inside a rectangle centered at (0,0).
 *
 * @param x - The x-coordinate of the point (center of your target)
 * @param y - The y-coordinate of the point (center of your target)
 * @param rectWidth - The width of the rectangle.
 * @param rectHeight - The height of the rectangle.
 * @returns `true` if the point is inside the rectangle, `false` otherwise.
 * @example
    const pointX = 1;
    const pointY = 1;
    const rectWidth = 10;
    const rectHeight = 10;

    isPointInRectangle(pointX, pointY, rectWidth, rectHeight); // Outputs: true or false
 */

const isPointInRectangle = (
  x: number,
  y: number,
  rectWidth: number,
  rectHeight: number,
): boolean => {
  // Calculate the x and y ranges based on the rectangle's dimensions
  const halfWidth = rectWidth / 2;
  const halfHeight = rectHeight / 2;

  // Check if the point's x-coordinate is within the rectangle's width
  const isInXRange = x >= -halfWidth && x <= halfWidth;

  // Check if the point's y-coordinate is within the rectangle's height
  const isInYRange = y >= -halfHeight && y <= halfHeight;

  return isInXRange && isInYRange;
};

export default isPointInRectangle;
