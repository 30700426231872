import { premultiply } from './func/BlendFunc';

const shader = /* glsl */ `
#ifdef GL_ES
precision mediump float;
precision mediump int;
#endif

uniform sampler2D uBackground;
uniform sampler2D uForeground;

varying vec2 vUv;

${premultiply}

void main() {
  vec4 bg = texture2D(uBackground, vUv);
  vec4 fg = texture2D(uForeground, vUv);

  gl_FragColor = premultiply(bg, fg);
}
`;

export default shader;
