import PortableText from '~/components/molecules/PortableText/PortableText';
import {
  textLockup1,
  textLockup2,
  textLockup3,
  textLockup5,
} from '~/components/molecules/TextLockups/TextLockups.config';
import textLockup1Styles from '~/components/molecules/TextLockups/TextLockupTitle1.module.css';
import textLockup2Styles from '~/components/molecules/TextLockups/TextLockupTitle2.module.css';
import textLockup3Styles from '~/components/molecules/TextLockups/TextLockupTitle3.module.css';
import textLockup5Styles from '~/components/molecules/TextLockups/TextLockupTitle5.module.css';
import { cn } from '~/utils';

import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import styles from './StandaloneText.module.css';
import { StandaloneTextProps } from './StandaloneText.types';

const StandaloneText = (props: StandaloneTextProps) => {
  const { alignment, className, content, contentColumns, size } = props;

  const renderClass = cn(styles.standaloneText, className);

  const textLockup1Config = textLockup1();
  const textLockup2Config = textLockup2();
  const textLockup3Config = textLockup3();
  const textLockup5Config = textLockup5();

  let richTextLockupStyles = textLockup1Styles;
  let richTextLockupOptions = textLockup1Config.options;
  if (content) {
    for (const item of content) {
      if (item.style === 'title2') {
        richTextLockupStyles = textLockup2Styles;
        richTextLockupOptions = textLockup2Config.options;
        break;
      }

      if (item.style === 'title3') {
        richTextLockupStyles = textLockup3Styles;
        richTextLockupOptions = textLockup3Config.options;
        break;
      }
    }
  }

  return (
    <ModuleWrapper className={renderClass} {...props}>
      <div className={styles.container}>
        {content && (
          <PortableText
            className={cn(
              textLockup2Config.wrapperClass,
              alignment === 'center' && richTextLockupStyles.alignCenter,
              size === 'wide' && richTextLockupStyles.sizeWide,
              styles.content,
            )}
            value={content}
            options={{
              ...richTextLockupOptions,
              block: {
                ...richTextLockupOptions.block,
                titles: {
                  ...richTextLockupOptions.block?.titles,
                  className: cn(
                    styles.titles,
                    richTextLockupOptions.block?.titles?.className,
                  ),
                },
              },
              marks: {
                em: {
                  className: styles.colorHighlight,
                },
              },
            }}
          />
        )}

        {contentColumns && (
          <div className={styles.columnsWrapper}>
            <PortableText
              className={cn(
                textLockup5Config.wrapperClass,
                alignment === 'center' && textLockup5Styles.alignCenter,
                size === 'wide' && textLockup5Styles.sizeWide,
                size === 'wide' && styles.sizeWide,
                textLockup5Styles.columns,
                styles.contentCol1,
              )}
              value={contentColumns.col1}
              options={textLockup5Config.options}
            />

            <PortableText
              className={cn(
                textLockup5Config.wrapperClass,
                alignment === 'center' && textLockup5Styles.alignCenter,
                size === 'wide' && textLockup5Styles.sizeWide,
                size === 'wide' && styles.sizeWide,
                textLockup5Styles.columns,
                styles.contentCol2,
              )}
              value={contentColumns.col2}
              options={{
                ...textLockup5Config.options,
                ...{
                  types: {
                    'block.graphic': {
                      className: styles.icon,
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default StandaloneText;
