'use client';
import { gsap } from 'gsap';
import { CSSProperties, useRef } from 'react';

import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';

import styles from './MediaWithText.module.css';
import { MediaWithTextProps } from './MediaWithText.types';
import MediaWithTextContent from './MediaWithTextContent/MediaWithTextContent';

const MAX_PARALLAX_PERCENT = 16;

const MediaWithText = (props: MediaWithTextProps) => {
  const {
    customGlowImage,
    customGlowLayout,
    media,
    mobileAlignment,
    text,
    textColumns,
    textLayout,
    textPosition,
    wrapInDevice,
  } = props;

  const $element = useRef<HTMLDivElement>(null);

  const progressSetter = useRef<(value: number) => void>();

  useLayoutEffect(() => {
    progressSetter.current = gsap.quickSetter(
      $element.current,
      '--media-with-text-progress',
    ) as (value: number) => void;
  }, []);

  return (
    <ModuleWrapper
      className={cn(
        styles.mediaWithText,
        text && textPosition !== 'above' && styles.withText,
        mobileAlignment === 'right' && styles.isMobileRightAligned,
        mobileAlignment === 'left' && styles.isMobileLeftAligned,
      )}
      style={
        {
          '--max-parallax-percent': MAX_PARALLAX_PERCENT - 2,
        } as CSSProperties
      }
      ref={$element}
      {...props}
    >
      <MediaWithTextContent
        customGlowImage={customGlowImage}
        customGlowLayout={customGlowLayout}
        media={media}
        text={text}
        textColumns={textColumns}
        textLayout={textLayout}
        textPosition={textPosition}
        wrapInDevice={wrapInDevice}
      />
    </ModuleWrapper>
  );
};

export default MediaWithText;
