import { gsap } from 'gsap/all';
import { RefObject } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';

export const clearMagnetism = ({
  $perspectiveWrapper,
  $video,
}: {
  $perspectiveWrapper: RefObject<HTMLElement>;
  $video: RefObject<VideoRef>;
}) => {
  if ($video.current?.$wrapper.current) {
    gsap.killTweensOf([
      $perspectiveWrapper.current,
      $video.current.$wrapper.current,
    ]);
    gsap.set([$perspectiveWrapper.current, $video.current.$wrapper.current], {
      clearProps: 'all',
    });
  }
};
