import { RefObject } from 'react';

import { CMSVideoProps } from '../../atoms/Video/Video.types';
import { PortableTextCustomValue } from '../PortableText/PortableText.types';

export type CMSFloatingVideoPreview = {
  /**
   * The video data
   */
  video: CMSVideoProps;
  /**
   * Whether the preview displays a static thumbnail or an autoplaying video. Defaults to true if video is not set to autoplay.
   */
  useStaticThumbnail?: boolean;
  /**
   * Whether the video preview should remain sticky after its parent component has scrolled away.
   */
  isSticky?: boolean;
  /**
   * The text content
   */
  textContent: PortableTextCustomValue;
};

export type FloatingVideoPreviewProps = {
  /**
   * The Video options
   */
  options: CMSFloatingVideoPreview;
  /**
   * Ref for the component that contains the video preview
   */
  $parent?: RefObject<HTMLDivElement>;
  className?: string;
};

/**
 * The amount of time before restarting the video in the thumbnail in seconds
 */
export const PREVIEW_LOOP_DURATION = 6;

/**
 * The poll value for the video loop
 */
export const PREVIEW_LOOP_DURATION_POLL = 1000;

/**
 * the id for the client portal the floating video preview will be mounted in
 */
export const FLOATING_VIDEO_PREVIEW_PORTAL_CONTAINER_ID =
  'floating-video-preview-container-desktop';
