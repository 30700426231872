const passFragment = /* glsl */ `
precision mediump float;
#define PI 3.14159

uniform vec2 uResolution;
uniform sampler2D tMap;
uniform float uTubeHeight;
uniform float uTubeTop;
uniform float uHorizontalStretch;
uniform float uTime;

float circularOut(float t) {
  return sqrt((2.0 - t) * t);
}

void main ()
{
  vec2 p = gl_FragCoord.xy;

  // Normalized pixel coordinates (from 0 to 1)
  vec2 uv = gl_FragCoord.xy / uResolution.xy;

  float tubeHeight = uTubeHeight / uResolution.y;

  float tubeRadius = tubeHeight / 2.;

  float tubeTopY = uTubeTop / uResolution.y;

  float tubeCenterY = tubeTopY - tubeRadius;
  float distanceFromCenter = 1. -  smoothstep(0., tubeRadius, distance(tubeCenterY, uv.y));

  float circOut = circularOut(distanceFromCenter);

  float resetY = uv.y - tubeCenterY;
  float stretchedY = resetY * mix( -0.1, 0.8, circOut);
  float finalY = uv.y - stretchedY * step(.001, distanceFromCenter);

  float finalX = uv.x - (uv.x - 0.5) * mix( 0., uHorizontalStretch, circOut);

  gl_FragColor += texture2D(tMap, vec2(finalX, finalY));
}
`;

export default passFragment;
