'use client';
import { gsap } from 'gsap';
import { useKeenSlider } from 'keen-slider/react';
import { CSSProperties, useCallback, useRef, useState } from 'react';

import SvgArrowDiagonal from '~/assets/svg/arrow-diagonal.svg';
import buttonStyles from '~/components/atoms/Buttons/Ctas/Button/Button.module.css';
import ModalCarouselArrows from '~/components/atoms/CarouselControls/ModalCarouselArrows/ModalCarouselArrows';
import Graphic from '~/components/atoms/Graphic/Graphic';
import { GRAPHIC_IMAGE_MULTIPLIER } from '~/components/atoms/Graphic/Graphic.types';
import Link from '~/components/atoms/Link/Link';
import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import { PaginationDotsRef } from '~/components/atoms/Pagination/Dots/PaginationDots.types';
import { CMSLogoTextItem } from '~/components/molecules/LogoTextItem/LogoTextItem.types';
import Modal from '~/components/molecules/Modal/Modal';
import PortableText from '~/components/molecules/PortableText/PortableText';
import { cn, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import getSvgDimensions from '~/utils/getSvgDimensions';

import styles from './TileTableModal.module.css';
import { TileTableModalProps } from './TileTableModal.types';

const TileTableModal = ({
  selectedGroup,
  selectedItemIndex,
  onClose,
}: TileTableModalProps) => {
  const [activeIndex, setActiveIndex] = useState(selectedItemIndex);

  const totalItems = selectedGroup.length;
  const refSlides = useRef<HTMLLIElement[]>(Array(totalItems));
  const pagination = useRef<PaginationDotsRef>(null);

  // initialize carousel
  const [sliderRef, sliderInstanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    initial: selectedItemIndex,

    detailsChanged(slider) {
      pagination.current?.setTrackPosition();

      slider.track.details?.slides.map((slide, i) => {
        gsap.set(refSlides.current[i], { opacity: slide.portion });
      });
    },
    slideChanged: (slider) => {
      const currentIndex = slider.track.details?.rel;
      setActiveIndex(currentIndex);
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.sm.mediaQuery]: {
        selector: '.slide',
        slides: {
          perView: 'auto',
        },
      },
    },
  });

  const scrollPrev = useCallback(() => {
    sliderInstanceRef.current?.prev();
  }, [sliderInstanceRef]);

  const scrollNext = useCallback(() => {
    sliderInstanceRef.current?.next();
  }, [sliderInstanceRef]);

  const goToIndex = useCallback(
    (index: number) => {
      sliderInstanceRef.current?.moveToIdx(index);
    },
    [sliderInstanceRef],
  );

  const renderItem = (item: CMSLogoTextItem, index: number) => {
    // if an icon is too short (for example, a wide horizontal logo), then scale up to MIN_ICON_HEIGHT
    const MIN_ICON_HEIGHT = 14;
    const iconHeight =
      item.logo.source.type === 'image'
        ? item.logo.source.asset.height / GRAPHIC_IMAGE_MULTIPLIER
        : getSvgDimensions(item.logo.source.markupString).height;
    const iconScale = Math.max(1, MIN_ICON_HEIGHT / iconHeight);

    return (
      <li
        key={`integration-item-${index}`}
        className={cn(
          styles.slideItem,
          'slide',
          index === activeIndex && styles.active,
        )}
        ref={(ref: HTMLLIElement) => {
          addToRefArray({ element: ref, refArray: refSlides, index });
        }}
        aria-hidden={activeIndex === index ? false : true}
      >
        <header className={styles.modalHeader}>
          <div
            className={styles.iconWrapper}
            style={{ '--graphic-scale': iconScale } as CSSProperties}
          >
            <Graphic {...item.logo} className={styles.icon} />
          </div>
          <h4 className={styles.label}>{item.label}</h4>
        </header>

        {item.description && (
          <PortableText
            className={styles.description}
            value={item.description}
            options={{
              block: {
                titles: {
                  className: styles.titles,
                },
              },
              marks: {
                link: {
                  className: styles.link,
                },
              },
            }}
          />
        )}

        {item.link && (
          <Link
            to={item.link}
            className={cn(styles.link, buttonStyles.textLink)}
            tabIndex={activeIndex === index ? 0 : -1}
          >
            {item.link.label}

            <SvgArrowDiagonal className={styles.arrowDiagonal} />
          </Link>
        )}
      </li>
    );
  };
  return (
    <Modal
      onClose={onClose}
      wrapperClassName={styles.modal}
      closeButtonClassName={styles.closeButton}
      hasShadow={true}
    >
      <div className={styles.modalContent}>
        <ul className={styles.slidesContainer} ref={sliderRef}>
          {selectedGroup.map((item, index) => {
            return renderItem(item, index);
          })}
        </ul>

        {/* desktop only */}
        <div className={styles.carouselArrowsContainer}>
          <ModalCarouselArrows
            activeIndex={activeIndex}
            total={totalItems}
            className={styles.carouselArrows}
            handleNext={scrollNext}
            handlePrevious={scrollPrev}
            prevTitle={selectedGroup[activeIndex - 1]?.label}
            nextTitle={selectedGroup[activeIndex + 1]?.label}
          />
        </div>
        {/* mobile only */}
        <PaginationDots
          className={styles.carouselPagination}
          total={totalItems}
          activeIndex={activeIndex}
          onClick={goToIndex}
          ref={pagination}
          parentSliderRef={sliderInstanceRef}
        />
      </div>
    </Modal>
  );
};

export default TileTableModal;
