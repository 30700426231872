'use client';

import {
  ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import PortableText from '~/components/molecules/PortableText/PortableText';

import {
  AllWordOrLetterItems,
  ForwardedTextLockupRef,
  TextBlockPropertiesGroup,
  TextLockupProps,
  WordOrLetterItem,
} from './TextLockups.types';

const TextLockup = (
  { className, value, options }: TextLockupProps,
  ref: ForwardedRef<ForwardedTextLockupRef>,
) => {
  const element = useRef<HTMLDivElement>(null);

  const accentsWordRef = useRef<WordOrLetterItem[]>([]);
  const titlesLetterRef = useRef<WordOrLetterItem[]>([]);
  const titlesWordRef = useRef<WordOrLetterItem[]>([]);
  const bodiesWordRef = useRef<WordOrLetterItem[]>([]);
  const buttonsRef = useRef<WordOrLetterItem[]>([]);
  const graphicRef = useRef<WordOrLetterItem[]>([]);
  const refsGrouped = useRef<TextBlockPropertiesGroup[]>();

  useImperativeHandle(
    ref,
    () => ({
      refs: refsGrouped,
      $element: element,
    }),
    [],
  );

  const groupElementsByIndexAndType = (allRefs: AllWordOrLetterItems) => {
    const result: TextBlockPropertiesGroup[] = [];

    for (const key in allRefs) {
      const keyOfType = key as 'wordEls' | 'letterEls';

      allRefs[keyOfType].forEach(
        ({ $el, index, groupStyleKey, category, parentRef, markType }) => {
          if (!result[index] && parentRef) {
            result[index] = {
              groupStyleKey,
              category,
              parentRef: parentRef.current,
              [keyOfType]: [],
            };
          }

          if (!result[index][keyOfType]) {
            result[index][keyOfType] = [];
          }

          if (markType === 'openQuote') result[index]['openQuoteMark'] = $el;

          keyOfType === 'wordEls'
            ? result[index].wordEls?.push($el)
            : result[index].letterEls?.push($el);
        },
      );
    }
    return result;
  };

  useEffect(() => {
    const buttonRefs =
      options.types &&
      options.types['block.buttonGroup'] &&
      Array.isArray(options.types['block.buttonGroup'].elementRef?.current)
        ? options.types['block.buttonGroup'].elementRef?.current
        : [];

    const graphicRefs =
      options.types &&
      options.types['block.graphic'] &&
      Array.isArray(options.types['block.graphic'].elementRef?.current)
        ? options.types['block.graphic'].elementRef?.current
        : [];

    const allRefs = {
      wordEls: [
        ...(options.block?.titles?.wordRef?.current
          ? options.block?.titles?.wordRef?.current
          : []),
        ...(options.block?.accents?.wordRef?.current
          ? options.block?.accents?.wordRef?.current
          : []),
        ...(options.block?.bodies?.wordRef?.current
          ? options.block?.bodies?.wordRef?.current
          : []),
        ...buttonRefs,
        ...graphicRefs,
      ],
      letterEls: [
        ...(options.block?.titles?.letterRef?.current
          ? options.block?.titles?.letterRef?.current
          : []),
        ...(options.block?.accents?.letterRef
          ? options.block?.accents?.letterRef?.current
          : []),
        ...(options.block?.bodies?.letterRef
          ? options.block?.bodies?.letterRef?.current
          : []),
      ],
    };

    refsGrouped.current = groupElementsByIndexAndType(allRefs);
  }, [options]);

  for (const option in options) {
    for (const group in options[option as 'block' | 'types']) {
      if (group !== 'className') {
        switch (group) {
          case 'accents':
            if (!options.block?.accents) return;

            const hasOptionsAccentsWordRef =
              typeof options.block.accents.wordRef !== 'undefined';

            // if no option is passed to access word refs from a parent component, store it in an internal ref to access for typography animation
            if (!hasOptionsAccentsWordRef) {
              options.block.accents.wordRef = accentsWordRef;
            }

            break;

          case 'titles':
            if (!options.block?.titles) return;
            const hasOptionsTitlesWordRef =
              typeof options.block.titles.wordRef !== 'undefined';

            if (!hasOptionsTitlesWordRef && options.block?.titles) {
              options.block.titles.wordRef = titlesWordRef;
            }

            const hasOptionsTitlesLetterRef =
              typeof options.block.titles.letterRef !== 'undefined';

            if (!hasOptionsTitlesLetterRef) {
              options.block.titles.letterRef = titlesLetterRef;
            }
            break;

          case 'bodies':
            if (!options.block?.bodies) return;
            const hasOptionsBodiesWordRef =
              typeof options.block.bodies.wordRef !== 'undefined';

            if (!hasOptionsBodiesWordRef) {
              options.block.bodies.wordRef = bodiesWordRef;
            }

            break;

          case 'block.buttonGroup':
            if (!options.types || !options.types['block.buttonGroup']) return;
            const hasOptionsButtonRef =
              typeof options.types['block.buttonGroup'].elementRef !==
              'undefined';

            if (!hasOptionsButtonRef) {
              options.types['block.buttonGroup'].elementRef = buttonsRef;
            }

            break;

          case 'block.graphic':
            if (!options.types || !options.types['block.graphic']) return;
            const hasOptionsGraphicRef =
              typeof options.types['block.graphic'].elementRef !== 'undefined';

            if (!hasOptionsGraphicRef) {
              options.types['block.graphic'].elementRef = graphicRef;
            }

            break;
        }
      }
    }
  }

  return (
    <PortableText
      className={className}
      value={value}
      options={options}
      ref={element}
    />
  );
};
export default memo(forwardRef(TextLockup));
