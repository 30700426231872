import MediaLayer from '~/components/organisms/modules/StandoutDevice/MediaLayer/MediaLayer';
import { cn } from '~/utils';

import styles from './Device.module.css';
import { DeviceProps } from './Device.types';

const Device = ({ layer, className, isInteractive }: DeviceProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <MediaLayer
        layer={layer}
        isInteractive={isInteractive}
        innerClassNames={{
          container: styles.mediaWrapper,
          baseMedia: styles.base,
          variantMedia: styles.variant,
        }}
      />
    </div>
  );
};

export default Device;
