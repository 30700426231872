import { ReactNode } from 'react';

import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

// components that should always maintain their aspect ratio on screen (no parallax)
export const SHOULD_MAINTAIN_ASPECT_RATIO_COMPONENTS = ['pothole'];

export const HEAVY_PARALLAX_COMPONENTS = ['imageSequence'];

export const LIGHT_PARALLAX_COMPONENTS = ['caseStudy'];

export const SHOULD_MAINTAIN_OPACITY_ON_LEAVE_COMPONENTS = [
  'pothole',
  'imageSequence',
  'heroScreen',
];

export type StackingOrder = 'above' | 'below' | undefined;

export type PageSectionProps = {
  /**
   * id of the section
   */
  _id: string;

  /**
   * a string containing the section's background color
   */
  background?: string;
  /**
   * array of modules in the section
   */
  modules: CMSModuleBase[];
  /**
   * array of all module types in the section
   */
  moduleTypes: string[];
  /**
   * the index of the section on the page
   */
  sectionIndex: number;
  /**
   * an array of strings containing all the module types in the section
   */
  stackingOrder?: StackingOrder;
  /**
   *  whether the section is stacked on top of, below, or at the same level as the previous section
   */
  zIndex: number;
  /**
   * the stacking order of the prev section on the page
   */
  prevSectionStackingOrder?: StackingOrder;
  /**
   * the stacking order of the next section on the page
   */
  nextSectionStackingOrder?: StackingOrder;
  /**
   *  should the section always maintain its aspect ratio on scroll (no parallax)
   */
  shouldMaintainAspectRatio: boolean;
  /**
   *  does the section immediately follow a section that should maintain its aspect ratio (no parallax)
   */
  followsShouldMaintainAspectRatio: boolean;
  /**
   *  the sections parallax/overlap reveal type
   */
  parallaxRevealType: 'heavy' | 'light' | 'default';
  /**
   *  if the section is the last section on the page
   */
  isLastSection: boolean;
  /**
   *  does the section contain a module that has a floating video preview
   */
  containsFloatingVideoPreview: boolean;
  className?: string;

  children: ReactNode;
};
