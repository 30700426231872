'use client';
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

import ButtonCarouselArrow from '~/components/atoms/Buttons/UI/ButtonCarouselArrow/ButtonCarouselArrow';
import Observer from '~/components/atoms/Observer/Observer';
import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import { PaginationDotsRef } from '~/components/atoms/Pagination/Dots/PaginationDots.types';
import UIStore from '~/state/ui';
import globalStyles from '~/styles/global.module.css';
import { cn, getSpacer, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import getKeenSliderSlidesPerView from '~/utils/getKeenSliderSlidesPerView';

import styles from './QuoteCarousel.module.css';
import { QuoteCarouselProps } from './QuoteCarousel.types';
import QuoteCarouselSlide from './QuoteCarouselSlide/QuoteCarouselSlide';
import { ForwardedQuoteCarouselSlideRef } from './QuoteCarouselSlide/QuoteCarouselSlide.types';

/**
 * Quote carousel component
 * @param slides An array of slide data objects
 * @param className
 * @example <QuoteCarousel slides={slides} />
 */
const QuoteCarousel = (props: QuoteCarouselProps) => {
  const { accentType, slides, className } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const lastIndex = useRef<number>(-1);
  const refSlides = useRef<ForwardedQuoteCarouselSlideRef[]>(
    Array(slides.length),
  );
  const pagination = useRef<PaginationDotsRef>(null);
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const spacerValue = getSpacer(64);
  const $dummyGrid = useRef<HTMLDivElement>(null);

  const getSliderOptions: () => KeenSliderOptions = useCallback(() => {
    // config for md and lg breakpoints
    const multiSlideConfig: KeenSliderOptions = {
      loop: true,
      slides: {
        spacing: spacerValue,
        origin: 'center',
        // using the window width and grid width, calculate how many slides are visible on the screen if the active slide is set to the grid width
        perView: getKeenSliderSlidesPerView($dummyGrid, spacerValue),
      },
    };

    return {
      ...keenSliderConfig.defaultConfig,
      selector: '.slide',
      loop: true,

      breakpoints: {
        [keenSliderConfig.breakpoints.sm.mediaQuery]: {
          // TODO: investigate why loop option needs to be specificed for each breakpoint for it to work on after resizing
          loop: true,
          slides: {
            perView: 1,
          },
          detailsChanged: (slider) => {
            const progresses = slider.track.details.slides.map(
              (slide) => slide.portion,
            );
            progresses.forEach((progress: number, slideIndex: number) => {
              const slide = refSlides.current[slideIndex];
              if (slide) slide.setProgress(progress);
            });
            pagination.current?.setTrackPosition();
          },
        },

        [keenSliderConfig.breakpoints.md.mediaQuery]: { ...multiSlideConfig },

        [keenSliderConfig.breakpoints.lg.mediaQuery]: { ...multiSlideConfig },
      },
      slideChanged: () => {
        // this doesn't get triggered until the slide is settled
        checkCurrentSlide.current();
      },
    };
  }, [spacerValue]);

  const [sliderRef, instanceRef] = useKeenSlider(getSliderOptions());

  const checkCurrentSlide = useRef((targetIdx = -1) => {
    const keenSlider = instanceRef.current;
    if (keenSlider && keenSlider.track) {
      const useTargetIdx = targetIdx >= 0;
      const currentIndex =
        (useTargetIdx ? targetIdx : keenSlider.track.details?.rel) || 0;
      if (currentIndex !== lastIndex.current) {
        refSlides.current[lastIndex.current]?.setActive(false);
        refSlides.current[currentIndex]?.setActive(true);

        // for pagination dots
        setActiveIndex(currentIndex);
        lastIndex.current = currentIndex;
      }
    }
  });

  // on window resize, call slider update function to resize slides per view
  const updateSlider = useCallback(() => {
    instanceRef.current?.update(getSliderOptions());
  }, [instanceRef, getSliderOptions]);

  useEffect(() => {
    checkCurrentSlide.current();

    const unsubscribe = UIStore.subscribe(
      (state) => [state.windowWidth as number],
      debounce(updateSlider, 100),
    );

    return () => {
      unsubscribe();
    };
  }, [updateSlider]);

  const scrollPrev = useCallback(() => {
    instanceRef.current?.prev();
  }, [instanceRef]);

  const scrollNext = useCallback(() => {
    instanceRef.current?.next();
  }, [instanceRef]);

  const goToIndex = useCallback(
    (index: number) => {
      instanceRef.current?.moveToIdx(index);
    },
    [instanceRef],
  );

  return (
    <div className={cn(styles.quoteCarousel, className)}>
      <Observer callback={updateIsInView} options={{ rootMargin: '200% 0%' }}>
        <div className={styles.dummyGrid}>
          {/* used to calculate the grid width regardless of screen size, to set the active slide's width */}
          <div className={styles.dummyGridInner} ref={$dummyGrid}></div>
        </div>
        <div className={`${styles.slidesContainer}`} ref={sliderRef}>
          {slides.map(
            ({ _key, eyebrow, image, logo, text, variantName }, slideIndex) => (
              <QuoteCarouselSlide
                ref={(ref: ForwardedQuoteCarouselSlideRef) => {
                  addToRefArray({
                    element: ref,
                    refArray: refSlides,
                    index: slideIndex,
                  });
                }}
                _key={_key}
                className={cn(styles.slide, 'slide')}
                eyebrow={
                  accentType === 'eyebrow' || typeof accentType === 'undefined'
                    ? eyebrow
                    : undefined
                }
                image={image}
                isInView={isInView !== false}
                key={_key}
                logo={
                  accentType === 'logo' || typeof accentType === 'undefined'
                    ? logo
                    : undefined
                }
                onClick={goToIndex}
                slideIndex={slideIndex}
                text={text}
                variantName={variantName}
              />
            ),
          )}
        </div>
        <PaginationDots
          className={styles.paginationDots}
          activeIndex={activeIndex}
          total={slides.length}
          onClick={goToIndex}
          ref={pagination}
          parentSliderRef={instanceRef}
        />
        <div className={globalStyles.carouselArrowsContainer}>
          <ButtonCarouselArrow
            className={globalStyles.carouselArrowsButtonPrev}
            iconDirection={'left'}
            onClick={scrollPrev}
          />
          <ButtonCarouselArrow
            className={globalStyles.carouselArrowsButtonNext}
            iconDirection={'right'}
            onClick={scrollNext}
          />
        </div>
      </Observer>
    </div>
  );
};

export default QuoteCarousel;
