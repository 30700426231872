import { RefObject, useEffect, useState } from 'react';

import getUniqueId from '~/utils/getUniqueId';

const useIsSequenceVisible = (
  $element: RefObject<HTMLDivElement>,
  defaultValue = false,
) => {
  const [isVisible, updateIsVisible] = useState(defaultValue);

  useEffect(() => {
    if (!defaultValue) {
      // This function is empty on purpose, to have a variable non-breaking that
      // will then be replaced by the actual one when the singleton is loaded
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      let unsubscribe = () => {};

      async function initObserver() {
        if ($element.current === null) return;
        const id = getUniqueId('img');
        ($element.current as HTMLElement).setAttribute('data-id', id);
        const Observer = (
          await import('./imageSequenceIntersectionObserverSingleton')
        ).default;
        unsubscribe = Observer.subscribe($element.current, id, () => {
          updateIsVisible(true);
        });
      }
      initObserver();

      return unsubscribe;
    }
  }, [$element, defaultValue]);

  return isVisible;
};

export default useIsSequenceVisible;
