import { gsap } from 'gsap';
import { RefObject } from 'react';

import { EaseType } from '~/utils/singletons/Easing';

export const scaleDownAnimation = ({
  $uiContainerInner,
  onCompleteScaleDown,
}: {
  $uiContainerInner: RefObject<HTMLDivElement>;
  onCompleteScaleDown: () => void;
}) => {
  const tl = gsap.timeline({ paused: true, onComplete: onCompleteScaleDown });
  if ($uiContainerInner.current) {
    tl.to($uiContainerInner.current, {
      duration: 1.2,
      scale: 1,
      x: 0,
      y: 0,
      ease: EaseType.EMBELLISHMENT,
    });
  }
  return tl;
};

export const mediaSlideInAnimation = ({
  $mediaGridWrapper,
  $glowWrapper,
}: {
  $mediaGridWrapper: RefObject<HTMLDivElement>;
  $glowWrapper: RefObject<HTMLDivElement>;
}) => {
  const tl = gsap.timeline();
  if ($mediaGridWrapper.current && $glowWrapper.current) {
    tl.fromTo(
      [$mediaGridWrapper.current, $glowWrapper.current],
      {
        y: 100,
        opacity: 0,
      },
      {
        duration: 0.8,
        y: 0,
        delay: 0.6,
        ease: EaseType.DEFAULT,
      },
    );
    tl.fromTo(
      [$mediaGridWrapper.current, $glowWrapper.current],
      {
        opacity: 0,
      },
      {
        duration: 0.95,
        opacity: 1,
      },
      // seconds past the start of the previous animation
      '<+=0.1',
    );
  }
  return tl;
};
