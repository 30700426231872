'use client';
import { gsap } from 'gsap';
import { CSSProperties, useCallback, useRef } from 'react';

import Dim from '~/components/atoms/Dim/Dim';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';

import getSequenceHeight from '../../Sequence/helpers/getSequenceHeight';
import Sequence from '../../Sequence/Sequence';
import Submodules from '../../Submodules/Submodules';
import styles from './EndsWithModules.module.css';
import { EndsWithModulesProps } from './EndsWithModules.types';

const EndsWithModules = (props: EndsWithModulesProps) => {
  const {
    className,
    sequence,
    startOnMiddleOfScreen = false,
    modules,
    isRevealedModule,
    fallbackImage,
  } = props;

  let sequenceHeight: number | undefined;
  const prefersReducedMotion = UIStore((state) => state.prefersReducedMotion);
  const windowHeight = UIStore((state) => state.windowHeight) || 0;

  if (prefersReducedMotion && fallbackImage) {
    sequenceHeight = windowHeight * 2.5;
  } else {
    if (sequence.type === 'image') {
      sequenceHeight = getSequenceHeight({
        type: sequence.type,
        numberOfFrames: sequence.frames.length,
        speed: sequence.speed,
      });
    } else if (sequence.type === 'video') {
      sequenceHeight = getSequenceHeight({
        type: sequence.type,
        speed: sequence.speed,
        videoDuration: sequence.videoSequenceDuration,
      });
    }
  }

  const $dim = useRef<HTMLDivElement>(null);
  const $modulesContainer = useRef<HTMLDivElement>(null);

  const onProgress = useCallback((progress: number) => {
    const mapper = gsap.utils.mapRange(
      0,
      parseFloat(styles.v_fullyDimmedOutPercentage),
      0,
      1,
    );
    gsap.set($dim.current, {
      opacity:
        Math.round(
          (Math.max(Math.min(mapper(progress), 1), 0) + Number.EPSILON) * 100,
        ) / 100,
    });
  }, []);

  useScrollProgress($modulesContainer, onProgress, {
    finishOnMiddleOfScreen: false,
  });

  const renderProps = {
    className: styles.sequence,
    canvasClassName: styles.canvas,
    startOnMiddleOfScreen: startOnMiddleOfScreen,
    finishOnMiddleOfScreen: true,
    fallbackImage: fallbackImage,
  };

  return (
    <ModuleWrapper
      className={cn(
        className,
        styles.endsWithModules,
        isRevealedModule && styles.isRevealedModule,
      )}
      {...props}
    >
      <div className={styles.antiPaddingWrapper}>
        <div
          className={styles.sequenceWrapper}
          style={
            { '--sequence-height': sequenceHeight + 'px' } as CSSProperties
          }
        >
          {sequence.type === 'image' && (
            <Sequence {...renderProps} sequence={sequence} />
          )}

          {sequence.type === 'video' && (
            <Sequence {...renderProps} sequence={sequence} />
          )}

          <div className={styles.overlayWrapper}>
            <Dim dim="dim100" ref={$dim} className={styles.overlay} />
          </div>
        </div>
        {modules && (
          <Submodules
            modules={modules}
            className={styles.modules}
            ref={$modulesContainer}
          />
        )}
      </div>
    </ModuleWrapper>
  );
};

export default EndsWithModules;
