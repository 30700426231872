/*
  Premultiply Alpha:
  https://en.wikipedia.org/wiki/Alpha_compositing#Straight_versus_premultiplied

      a = srcA + dstA * (1 - srcA);
    rgb = srcRGB * srcA + dstRGB * dstA * (1 - srcA)
*/
export const premultiply = /* glsl */ `
  vec4 premultiply(vec4 src, vec4 dst) {
    return vec4(
      src.rgb * src.a + dst.rgb * dst.a * (1.0 - src.a),
      src.a + dst.a * (1.0 - src.a)
    );
  }
`;
