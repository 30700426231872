import { CMSScreenCarouselSlide } from './ScreenCarouselSlide/ScreenCarouselSlide.types';

export type CMSModuleScreenCarouselSlidesAndLabels = {
  /**
   * An array of slide data objects
   */
  slides: CMSScreenCarouselSlide[];
};

export const SLIDE_DURATION = 1;
export type rect = { left: number; width: number };
