import {
  SanityAsset,
  SanityImageCrop,
  SanityImageDimensions,
  SanityImageHotspot,
} from '@sanity/asset-utils';

import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

import { SequenceBaseLayoutProps } from '../Sequence.types';

/**
 * props used for the Sequence module
 */

export type SequenceTypeFrameProps = SequenceTypeFrameLayoutProps &
  Partial<CMSModuleBase>;

export type SequenceTypeFrameLayoutProps = SequenceBaseLayoutProps & {
  sequence: SequenceTypeImage;
};

export type SequenceFrameConfig = {
  asset: SanityAsset;
  crop: SanityImageCrop;
  hotspot: SanityImageHotspot;
};

export type SequenceFrame = SequenceFrameConfig & {
  index: number;
};

export enum fitsMap {
  COVER = 'COVER',
  CONTAIN = 'CONTAIN',
}

export type SequenceTypeImage = SequenceFrameConfig & {
  type: 'image';
  speed: number;
  framesLoadOrder: number[];
  aspectRatio: number;
  dimensions: SanityImageDimensions;
  frames: SequenceFrame[];
};

export type SequenceRaw = CMSModuleBase & {
  sequence: {
    frames: SanityAsset[];
  };
};
