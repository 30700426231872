'use client';
import { gsap, MotionPathPlugin } from 'gsap/all';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Image from '~/components/atoms/Image/Image';
import { CMSImage } from '~/components/atoms/Image/Image.types';
import Observer from '~/components/atoms/Observer/Observer';
import webglAssetsStore from '~/components/molecules/Tube/Tube.store';
import { cn } from '~/utils';

import styles from './Tube.module.css';
import { TubeProps, TubeRef } from './Tube.types';

gsap.registerPlugin(MotionPathPlugin);

const Tube = (
  { className, type, autoPlayWhenInView = true, onLoad }: TubeProps,
  ref: ForwardedRef<TubeRef>,
) => {
  const assets = webglAssetsStore.getState().assets;
  const $tube = useRef<HTMLElement>(null);
  const $ball = useRef<HTMLDivElement>(null);
  const $tubeSizer = useRef<HTMLDivElement>(null);
  const $container = useRef<HTMLDivElement>(null);
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const id = useId();

  let asset: CMSImage | null = null;
  let ballAsset: CMSImage | null = null;

  if (assets) {
    asset = assets[type];
    ballAsset = assets['ball'];
  }

  const animate = useCallback(
    (vars?: gsap.TweenVars) => {
      try {
        const path = `[id="${id}"]`;

        return gsap.to($ball.current, {
          motionPath: {
            path,
            align: path,
            alignOrigin: [0.5, 0.5],
          },
          duration: 2,
          delay: autoPlayWhenInView ? 0.25 : 0,
          ...vars,
        });
      } catch (e) {
        return null;
      }
    },
    [autoPlayWhenInView, id],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        $container,
        $tubeSizer,
        play: animate,
      };
    },
    [animate],
  );

  useEffect(() => {
    if (!autoPlayWhenInView) return;

    if (isInView) {
      animate();
    }
  }, [autoPlayWhenInView, isInView, type, animate]);

  return asset && ballAsset ? (
    <Observer
      options={{ rootMargin: '0% 0%' }}
      callback={updateIsInView}
      className={cn(styles.tubeContainer, styles[type], className)}
      ref={$container}
    >
      <div className={styles.tubeSizer} ref={$tubeSizer} />
      {/* wrapper for absolute positioning */}
      <div className={styles.assetsWrapper}>
        <div ref={$ball} className={styles.ballWrapper}>
          <Image fixedAspectRatio className={styles.ball} source={ballAsset} />
        </div>
        {type === 'tube1' && (
          <svg
            className={styles.svg}
            width="1913"
            height="212"
            viewBox="0 0 1913 212"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id={id}
              d="M0 78C172 138 417.5 138 577.5 138C575.5 138 1521.17 132.5 1913 132.5"
            />
          </svg>
        )}
        {type === 'tube2' && (
          <svg
            className={styles.svg}
            width="1913"
            height="485"
            viewBox="0 0 1913 485"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id={id}
              d="M0 396.5C30.5 396.5 113 361.907 158.5 336.5C413.5 194.107 684.5 84.5004 1061.5 81.0003C1221.99 79.5102 1705 79.9998 1913 80"
            />
          </svg>
        )}
        <Image
          ref={$tube}
          fixedAspectRatio
          className={styles.asset}
          source={asset}
          onReady={onLoad}
        />
      </div>
    </Observer>
  ) : null;
};

export default forwardRef<TubeRef, TubeProps>(Tube);
