'use client';
/**
 * Rounded button with plus icon
 * @param props see UiButtonBase props
 * @param open Whether the parent element is open
 * @example  <ButtonExpand open={false} />
 */
import { ForwardedRef, forwardRef } from 'react';

import SvgPlusIcon from '~/assets/svg/plus-20px-center.svg';
import { ForwardedButtonRef } from '~/components/atoms/Buttons/Ctas/Button/Button.types';
import { ButtonExpandProps } from '~/components/atoms/Buttons/UI/ButtonExpand/ButtonExpand.types';
import UIButtonBase from '~/components/atoms/Buttons/UI/UIButtonBase';
import { cn } from '~/utils';

import styles from './ButtonExpand.module.css';

const ButtonExpand = (
  props: ButtonExpandProps,
  ref: ForwardedRef<ForwardedButtonRef>,
) => {
  const { className, open, ...rest } = props;

  const renderClasses = cn(styles.buttonExpand, className, open && styles.open);

  return (
    // TODO: Add expand button label to Sanity dictionary
    <UIButtonBase
      ref={ref}
      className={renderClasses}
      {...rest}
      label={'Expand'}
    >
      <SvgPlusIcon />
    </UIButtonBase>
  );
};

export default forwardRef(ButtonExpand);
