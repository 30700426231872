import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { PortableTextCustomValue } from '~/components/molecules/PortableText/PortableText.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export type CarouselV4Props = CMSModuleBase & {
  text?: PortableTextCustomValue;
  slides: (CMSEnhancedMedia & { _key: string })[];
};

export const MOBILE_MAX_VISIBLE_SLIDES = 4;
