'use client';
import { gsap } from 'gsap';
import { useCallback, useRef, useState } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Observer from '~/components/atoms/Observer/Observer';
import Media from '~/components/molecules/Media/Media';
import Shadow from '~/components/molecules/Shadow/Shadow';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from '../SideBySideV4.module.css';
import { MultiImageMediaContainerProps } from './MultiImageMediaContainer.types';

const MultiImageMediaContainer = ({ media }: MultiImageMediaContainerProps) => {
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const progressSetter = useRef<(value: number) => void>();
  const $mediaMultiContainer = useRef<HTMLDivElement>(null);

  let foregroundAsset;
  if ('asset' in media.front.sanityMedia) {
    foregroundAsset = media?.front?.sanityMedia?.asset;
  }

  useLayoutEffect(() => {
    progressSetter.current = gsap.quickSetter(
      $mediaMultiContainer.current,
      '--multi-media-container-progress',
    ) as (value: number) => void;
  }, []);

  const onProgress = useCallback((progress: number) => {
    if (progressSetter.current) {
      const roundedProgress = Math.round(progress * 1000) / 1000;
      progressSetter.current(roundedProgress);
    }
  }, []);

  useScrollProgress($mediaMultiContainer, onProgress, {
    shouldAlwaysComplete: false,
  });

  return (
    <Observer
      className={cn(
        styles.mediaMultiDevicesContainer,
        foregroundAsset && foregroundAsset.aspectRatio > 1
          ? styles.wideAspectRatioContainer
          : styles.narrowAspectRatioContainer,
      )}
      callback={updateIsInView}
      options={{ rootMargin: '200% 0%' }}
      ref={$mediaMultiContainer}
    >
      <div className={styles.mediaBackgroundContainer}>
        <GlassWrapper
          className={styles.glassWrapper}
          contentClassName={styles.glassWrapperContent}
        >
          <Media
            sanityMedia={media.back.sanityMedia}
            className={styles.mediaBackgroundElement}
            isDisplayed={isInView !== false}
            contain={true}
          />
        </GlassWrapper>
      </div>
      <div className={styles.mediaForegroundContainer}>
        <div className={styles.mediaForegroundElementWrapper}>
          <GlassWrapper
            className={styles.glassWrapper}
            contentClassName={styles.glassWrapperContent}
          >
            <Media
              sanityMedia={media.front.sanityMedia}
              className={styles.mediaForegroundElement}
              isDisplayed={isInView !== false}
              fixedAspectRatio={true}
            />
          </GlassWrapper>
          <Shadow />
        </div>
      </div>
    </Observer>
  );
};

export default MultiImageMediaContainer;
