// returns an absolute pixel value relative to root (document.body by default)

const getRootOffset = (
  el: HTMLElement,
  root: HTMLElement | Window = document?.body ?? el,
) => {
  let parent: HTMLElement | null = el;
  const bounds = {
    top: 0,
    left: 0,
  };

  do {
    if (!Number.isNaN(parent.offsetTop)) {
      bounds.top += parent.offsetTop;
    }
    if (!Number.isNaN(parent.offsetLeft)) {
      bounds.left += parent.offsetLeft;
    }
    parent = parent.offsetParent as HTMLElement;
  } while (parent && parent !== root);

  return bounds;
};

export default getRootOffset;
