'use client';
import { CSSProperties, Fragment, ReactNode } from 'react';

import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import Dim from '~/components/atoms/Dim/Dim';
import Image from '~/components/atoms/Image/Image';
import Link from '~/components/atoms/Link/Link';
import Glow from '~/components/molecules/Glow/Glow';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { textLockup5 } from '~/components/molecules/TextLockups/TextLockups.config';
import { cn } from '~/utils';

import styles from './BentoCard.module.css';
import { BentoCardProps } from './BentoCard.types';

const BentoCard = ({
  content,
  image,
  isInView,
  link,
  glow = false,
}: BentoCardProps) => {
  const hotspot = image.image?.hotspot || { height: 1, width: 1 };

  const GlowWrapper = image.glow && glow ? Glow : Fragment;

  const Wrapper = link
    ? ({ className, children }: { className: string; children: ReactNode }) => (
        <Link className={className} to={link}>
          {children}
        </Link>
      )
    : 'div';

  const options = textLockup5().options;

  return (
    <GlowWrapper
      {...(image.glow && glow === true
        ? { source: image.glow, className: styles.card }
        : {})}
    >
      <Shadow
        className={cn(styles.shadow, glow === true ? styles.glow : styles.card)}
      >
        <Wrapper className={cn(styles.bentoCard, link && styles.hasLink)}>
          <Dim dim="dim20" className={styles.hoverDim} />
          <div
            className={styles.imageWrapper}
            style={
              {
                aspectRatio: hotspot.width / hotspot.height,
              } as CSSProperties
            }
          >
            <Image
              source={image.image}
              className={styles.image}
              quality={100}
              isDisplayed={isInView}
              fixedAspectRatio={true}
            />
          </div>
          <PortableText
            value={content}
            className={styles.content}
            options={{
              ...options,
              block: {
                ...options.block,
                bodies: {
                  className: cn(options.block?.bodies?.className, styles.body),
                },
              },
            }}
          />
          {link && (
            <Button
              to={link}
              className={styles.link}
              buttonVariant="textWithIcon"
              asNonInteractive={true}
            >
              {link.label}
            </Button>
          )}
        </Wrapper>
      </Shadow>
    </GlowWrapper>
  );
};

export default BentoCard;
