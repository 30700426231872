const calculateRelativeInstanceDistance = (
  totalLength: number,
  fromIndex: number,
  toIndex: number,
  loop: boolean = false,
): number => {
  if (!loop) {
    // If loop is not enabled, simply subtract the fromIndex from the toIndex.
    return Math.abs(toIndex - fromIndex);
  } else {
    // If loop is enabled, calculate the distance considering the loop.
    const forwardDistance = (toIndex - fromIndex + totalLength) % totalLength;
    const backwardDistance = (fromIndex - toIndex + totalLength) % totalLength;

    // Return the shortest distance, considering both forward and backward possibilities.
    const returnValue =
      forwardDistance <= backwardDistance ? forwardDistance : -backwardDistance;

    return Math.abs(returnValue);
  }
};

export default calculateRelativeInstanceDistance;
