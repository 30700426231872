'use client';
import { gsap } from 'gsap';
import { CSSProperties, useCallback, useRef, useState } from 'react';

import DeviceWrapper from '~/components/atoms/DeviceWrapper/DeviceWrapper';
import Observer from '~/components/atoms/Observer/Observer';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Glow from '~/components/molecules/Glow/Glow';
import Media from '~/components/molecules/Media/Media';
import PortableText from '~/components/molecules/PortableText/PortableText';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { textLockup1 } from '~/components/molecules/TextLockups/TextLockups.config';
import textLockup1Styles from '~/components/molecules/TextLockups/TextLockupTitle1.module.css';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import { EaseType } from '~/utils/singletons/Easing';

import {
  mediaSlideInAnimation,
  scaleDownAnimation,
} from './HeroScreen.animations';
import styles from './HeroScreen.module.css';
import { HeroScreenProps } from './HeroScreen.types';

/**
 * Hero Screen component
 * @param content Portable text content
 * @param media The main media element displayed on the device screen.
 * @param uiMedia The secondary media element displayed on the device screen (displays ui screen)
 * @param moduleIndex The module's index within its section
 * @param className
 * @example <HeroScreen media={media} uiMedia={uiMedia} content={content}/>
 */
const HeroScreen = (props: HeroScreenProps) => {
  const { content, media, uiMedia, moduleIndex, className } = props;
  const $glowWrapper = useRef<HTMLDivElement>(null);
  const $element = useRef<HTMLDivElement>(null);
  const $media = useRef<VideoRef>(null);
  const $uiMedia = useRef<VideoRef>(null);
  const animation = useRef<GSAPTimeline>();
  const $mediaContainer = useRef<HTMLDivElement>(null);
  const $uiContainerInner = useRef<HTMLDivElement>(null);
  const $mediaGridWrapper = useRef<HTMLDivElement>(null);
  const progressSetter = useRef<(value: number) => void>();
  const opacityProgressSetter = useRef<(value: number) => void>();

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const initialMediaOpacity = useRef<string>(moduleIndex === 0 ? '0' : '');
  const bannerHeight = useUIStore((state) => state.bannerHeight);
  const bannerDismissed = useUIStore((state) => state.bannerDismissed);

  const options = textLockup1();

  const onProgress = useCallback((progress: number) => {
    if (progressSetter.current)
      progressSetter.current(
        gsap.utils.clamp(0, 1, gsap.utils.normalize(0.1, 1, progress)),
      );

    if (opacityProgressSetter.current) {
      const opacityNormalizedProgress = gsap.utils.clamp(
        0,
        1,
        gsap.utils.normalize(0.3, 1, progress),
      );
      opacityProgressSetter.current(1 - opacityNormalizedProgress);
    }
    if (progress > 0.26 && $media.current?.pause) {
      // We pause the main video
      $media.current?.pause();
      // Scale down medias container (so we reveal the UI)
      animation.current?.play();
    }
  }, []);

  const onCompleteScaleDown = () => {
    // We play the UI video, if it exists
    $uiMedia.current?.play();
  };

  useScrollProgress($mediaContainer, onProgress);

  useLayoutEffect(() => {
    if (uiMedia?.sanityMedia?.mediaType && $uiContainerInner.current) {
      animation.current = scaleDownAnimation({
        $uiContainerInner,
        onCompleteScaleDown,
      });
    }
    if ($mediaGridWrapper.current && $glowWrapper.current) {
      mediaSlideInAnimation({
        $mediaGridWrapper,
        $glowWrapper,
      });
    }

    progressSetter.current = gsap.quickSetter(
      $element.current,
      '--transition-out-progress',
    ) as (value: number) => void;

    opacityProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--opacity-progress',
    ) as (value: number) => void;
  }, [uiMedia?.sanityMedia?.mediaType]);

  //remove space on top of the hero that was accomodating announcement banner (only affects sm breakpoint visually)
  useLayoutEffect(() => {
    gsap.to($element.current, {
      duration: bannerDismissed ? 0.5 : 0,
      '--banner-height': bannerDismissed ? 0 : bannerHeight + 'px',
      ease: EaseType.BASIC_BUTTER,
    });
  }, [bannerHeight, bannerDismissed]);

  return (
    <ModuleWrapper
      className={cn(styles.heroScreen, className)}
      ref={$element}
      {...props}
    >
      <PortableText
        className={cn(
          options.wrapperClass,
          textLockup1Styles.alignCenter,
          styles.content,
        )}
        value={content}
        options={options.options}
      />

      <div
        className={styles.mediaGridWrapper}
        ref={$mediaGridWrapper}
        style={
          initialMediaOpacity.current
            ? ({
                opacity: initialMediaOpacity.current,
              } as CSSProperties)
            : {}
        }
      >
        <Shadow className={cn(styles.mediaContainer)}>
          <div ref={$glowWrapper} className={styles.glowWrapper}>
            <Glow
              className={styles.glow}
              source={media.glow}
              style={
                {
                  '--glow-transition-in-delay': '1s',
                } as CSSProperties
              }
            />
          </div>
          <Observer
            className={styles.mediaWrapper}
            ref={$mediaContainer}
            callback={updateIsInView}
            options={{ rootMargin: '200% 0%' }}
          >
            <div className={cn(styles.uiContainer)}>
              <div
                className={cn(
                  styles.uiContainerInner,
                  uiMedia?.sanityMedia?.mediaType && styles.hasUiMedia,
                )}
                ref={$uiContainerInner}
              >
                {uiMedia?.sanityMedia?.mediaType && (
                  <Media
                    className={styles.uiMedia}
                    sanityMedia={uiMedia.sanityMedia}
                    ref={$uiMedia}
                    forceIsInView={false}
                    isDisplayed={isInView !== false}
                    fixedAspectRatio={true}
                  />
                )}
                <Media
                  className={cn(
                    styles.media,
                    media.media.sanityMedia?.mediaType && styles.hasUiMedia,
                  )}
                  sanityMedia={media.media.sanityMedia}
                  ref={$media}
                  forceIsInView={true}
                  isDisplayed={isInView !== false}
                  fixedAspectRatio={true}
                />
              </div>
            </div>
            <DeviceWrapper
              deviceType="studioDisplay"
              isInView={isInView !== false}
              className={styles.deviceWrapper}
            />
          </Observer>
        </Shadow>
      </div>
    </ModuleWrapper>
  );
};

export default HeroScreen;
