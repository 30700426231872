import { SanityImageSource } from '@sanity/asset-utils';
import imageUrlBuilder from '@sanity/image-url';

import { SequenceFrame } from '../Sequence.types';
import sliceIntoChunks from './splitIntoChunks';

type FrameSize = { width: number; height: number };

const urlFor = (source: SanityImageSource) => {
  const builder = imageUrlBuilder({
    projectId: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_PROJECT_ID || '',
    dataset: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET || '',
  });

  return builder.image(source);
};

const loadImage = (
  image: SequenceFrame,
  size: FrameSize,
  fit: string,
  dpr = 1,
): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = '';

    let urlBuilder = urlFor(image)
      .width(size.width)
      .height(size.height)
      .dpr(dpr)
      .quality(80)
      .auto('format');

    if (fit === 'CONTAIN') {
      urlBuilder = urlBuilder.fit('fill');
      urlBuilder = urlBuilder.bg('0fff');
    }

    const url = urlBuilder.url();
    img.onload = () => resolve(img);
    img.src = url;
  });
};

const loadImages = (
  textures: { img: HTMLImageElement; index: number }[],
  framesToLoad: SequenceFrame[],
  _framesLoadOrder: number[],
  defaultFrameIndex: number,
  canvasWidth: number,
  canvasHeight: number,
  fit = 'COVER',
  aspectRatio = 16 / 9,
): Promise<HTMLImageElement | null> => {
  if (framesToLoad && framesToLoad.length > 3) {
    return new Promise(async (resolve) => {
      const possibleWidths = [2160, 1920, 1440, 1080, 720, 480];
      const possibleHeights = [2160, 1920, 1440, 1080, 720, 480];

      const size: FrameSize = {
        width: possibleWidths[0],
        height: possibleHeights[2],
      };

      const framesLoadOrder = _framesLoadOrder.slice(0);

      for (let index = 1; index < possibleWidths.length; index++) {
        const possibleWidth = possibleWidths[index];
        if (possibleWidth < canvasWidth) {
          break;
        }
        size.width = possibleWidth;
      }

      for (let index = 1; index < possibleHeights.length; index++) {
        const possibleHeight = possibleHeights[index];
        if (possibleHeight < canvasHeight) {
          break;
        }
        size.height = possibleHeight;
      }

      if (fit === 'CONTAIN') {
        size.height = Math.round(size.width / aspectRatio);
      }

      // TODO: Move that to UIStore?
      // const deviceDpr = Math.min(window.devicePixelRatio, 2);
      const deviceDpr = 1;

      // We load the initial frame first, and then first and last frames. The last frame is length - 1 + the number of frames we already loaded
      // because when we get to it we will have removed the first one and the default one (if it was one in between)
      const initialFrames = [];

      if (
        defaultFrameIndex > 0 &&
        defaultFrameIndex < framesToLoad.length - 1
      ) {
        initialFrames.push(defaultFrameIndex);
        framesLoadOrder.splice(defaultFrameIndex, 1);
      }
      initialFrames.push(0);
      initialFrames.push(framesToLoad.length - 1);

      for (let index = 0; index < initialFrames.length; index++) {
        const frameIndex = initialFrames[index];
        const frame = framesToLoad[frameIndex] as SequenceFrame;
        const img = await loadImage(
          frame,
          size,
          // For those main frames, we go all in on the dpr
          fit,
          deviceDpr,
        );
        textures.push({ img, index: frame.index });

        // We resolve with the first frame so the renderer can start with it
        if (index === 0) {
          resolve(img);
        }
      }

      // The strategy will be to gradually fill in the gaps, by loading every in the middle of those gaps
      // As we loaded the first and last frames already, we will want to load the one in the middle of those
      // two in our first loop. So we divide the array length in 2 (n) and use that index. For the next loop,
      // we double n to fill in the gaps from the previous loop, and so on,
      // As we remove elements from the array, when we reach n >= length of the array with the remaining
      // frames, we make the assumption that from there we already have enough frames to have a decent
      // sequence, so we end by simply looping through all the remaining frames.

      const chunks = sliceIntoChunks(framesLoadOrder, 10);

      const timeout = 30;
      for (const chunk of chunks) {
        const promises = [];
        for (const index of chunk) {
          promises.push(
            loadImage(framesToLoad[index], size, fit, 1).then((img) => {
              // We add our loaded texture to the array, which is now updated on our component (because it has been passed by reference)
              textures.push({ img, index });

              // We always reorder the array to ensure the frames are in the original order. Is it too costly?
              textures.sort((a, b) => a.index - b.index);
            }),
          );
        }
        await Promise.all(promises);
        // We add a little 30ms buffer between chunks to give the CPU a quick breath. This is based on nothing and is maybe useless,
        // I just felt like animations were struggling a bit during that loading phase
        await new Promise((resolve) => setTimeout(resolve, timeout));
      }
    });
  }
  return new Promise((resolve) => {
    resolve(null);
  });
};

export default loadImages;
