import { urlFor } from '~/components/atoms/Image/utils';
import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';

import { TextureInfo } from './renderer/types';

export function getTextureInfo(media: CMSEnhancedMedia<'media'>): TextureInfo {
  if (media?.media?.sanityMedia.mediaType !== 'image') {
    throw new Error('getTextureInfo requires an image');
  }

  const width = media.media.sanityMedia.asset.width;
  const height = media.media.sanityMedia.asset.height;
  const url = urlFor(media.media.sanityMedia).url();

  return {
    url,
    width,
    height,
  };
}
