import { gsap } from 'gsap/all';
import { RefObject } from 'react';

export const clearMagnetism = ({
  $componentWrapper,
}: {
  $componentWrapper: RefObject<HTMLElement>;
}) => {
  gsap.killTweensOf([$componentWrapper.current]);
  gsap.set($componentWrapper.current, {
    clearProps: 'all',
  });
};
