'use client';
import { ForwardedRef, forwardRef } from 'react';

import StandaloneText from '~/components/organisms/modules/StandaloneText/StandaloneText';
import Stats from '~/components/organisms/modules/Stats/Stats';
import Touts from '~/components/organisms/modules/Touts/Touts';
import { cn } from '~/utils';

import styles from './Submodules.module.css';
import { ImageSequenceSubmodulesProps } from './Submodules.types';

const Submodules = (
  { modules, className, moduleClassName }: ImageSequenceSubmodulesProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const moduleClassNames = cn(moduleClassName, styles.module);
  return (
    <div className={cn(styles.modulesContainer, className)} ref={ref}>
      {modules.map((module) => {
        if (module._type === 'module.standaloneText') {
          return (
            <StandaloneText
              {...module}
              key={module._key}
              className={moduleClassNames}
            />
          );
        } else if (module._type === 'module.touts') {
          return (
            <Touts {...module} key={module._key} className={moduleClassNames} />
          );
        } else if (module._type === 'module.stats') {
          return (
            <Stats
              {...module}
              key={module._key}
              className={cn(moduleClassNames, styles.stats)}
            />
          );
        } else if (module._type === 'module.spacer') {
          return (
            <div key={module._key}>
              {module.sizes?.map((size) => {
                return (
                  <span
                    key={size.breakpoint}
                    className={cn(styles.spacer, styles[size.breakpoint])}
                    style={{ height: size.height + 'px' }}
                  />
                );
              })}
            </div>
          );
        }
      })}
    </div>
  );
};

export default forwardRef(Submodules);
