const textPlaneVertex = `
precision mediump float;

// Attributes
attribute vec3 position;
attribute vec2 uv;

// Uniforms
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

// Varying (to pass data to the fragment shader)
varying vec2 vUv;

void main() {
    // Pass the uv to the fragment shader
    vUv = uv;

    // Calculate the position of the vertex
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}

`;

export default textPlaneVertex;
