'use client';

import RadioButtons from '~/components/atoms/RadioButtons/RadioButtons';
import PortableText from '~/components/molecules/PortableText/PortableText';
import textLockup1Styles from '~/components/molecules/TextLockups/TextLockupTitle1.module.css';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import usePricingStore from '~/state/pricing';
import { cn } from '~/utils';

import { CMSPricing, PricePeriodValue } from '../Pricing.types';
import PricingCard from './PricingCard/PricingCard';
import styles from './PricingOverview.module.css';
import { PricingOverviewProps } from './PricingOverview.types';

const PricingOverview = (
  props: PricingOverviewProps & { globalPricing: CMSPricing },
) => {
  const { text, radioButtons, className, globalPricing } = props;
  const pricePeriod = usePricingStore((state) => state.pricePeriod);
  const setPricePeriod = usePricingStore((state) => state.setPricePeriod);

  const onRadioButtonChange = (index: number) => {
    const radioValue = radioButtons[index].value;

    if (
      Object.values(PricePeriodValue).includes(radioValue as PricePeriodValue)
    ) {
      setPricePeriod(radioValue as PricePeriodValue);
    }
  };

  return (
    <ModuleWrapper
      className={cn(
        styles.pricingOverview,

        className,
      )}
      {...props}
    >
      <PortableText
        value={text}
        className={cn(
          textLockup1Styles.content,
          textLockup1Styles.sizeWide,
          styles.content,
        )}
        options={{
          block: {
            titles: {
              className: cn(textLockup1Styles.titles, styles.titles),
            },
          },
        }}
      />
      <RadioButtons
        className={styles.radioButtons}
        buttons={radioButtons}
        onChange={onRadioButtonChange}
      />

      <div className={styles.pricingCardsWrapper}>
        {globalPricing.products.flatMap((product) =>
          !product.secondaryTitle ? (
            <PricingCard
              key={product._key}
              product={product}
              pricePeriod={pricePeriod}
            />
          ) : null,
        )}
      </div>

      <div className={styles.customCardsWrapper}>
        {globalPricing.products.flatMap((product) =>
          product.secondaryTitle ? (
            <PricingCard
              key={product._key}
              product={product}
              pricePeriod={pricePeriod}
            />
          ) : null,
        )}
      </div>
    </ModuleWrapper>
  );
};

export default PricingOverview;
