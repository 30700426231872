import tokens from '@frameio-bs/tokens';

export type GradientOpacity = (typeof tokens.overlays)[number]['value'];

export type Alignment = HorizontalAlignment | VerticalAlignment;

export type HorizontalAlignment = 'left' | 'center';
export type VerticalAlignment = 'bottom' | 'middle';

export type TextSize = 'default' | 'wide';

export const VIDEO_MODAL_WHEEL_THRESHOLD = 250;
export const VIDEO_MODAL_SCROLL_STOP_THRESHOLD = 2;
