'use client';
import { ForwardedRef, forwardRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import { CMSImage, ImageFit } from '~/components/atoms/Image/Image.types';
import Video from '~/components/atoms/Video/Video';
import { CMSVideoProps, VideoRef } from '~/components/atoms/Video/Video.types';

import { MediaProps } from './Media.types';

const Media = (
  props: MediaProps,
  ref: ForwardedRef<HTMLElement | VideoRef>,
) => {
  const {
    forceIsInView,
    className,
    contain,
    isLooping,
    isMuted,
    willAutoplay,
    onReady,
    onVideoReady,
    preload = false,
    sanityMedia,
    sizes,
    isDisplayed,
    fixedAspectRatio,
    playsInline,
  } = props;

  if (sanityMedia?.mediaType === 'image') {
    const imageRef = ref as ForwardedRef<HTMLElement>;

    const imageProps = sanityMedia as CMSImage;

    let conditionalProps = {};

    if (sizes) {
      if (preload) {
        conditionalProps = { ...conditionalProps, preload: true, sizes };
      } else {
        conditionalProps = { ...conditionalProps, sizes };
      }
    }

    let fit: ImageFit = {};

    if (fixedAspectRatio) {
      fit = { fixedAspectRatio: true };
    } else if (contain) {
      fit = { contain: true };
    }

    return (
      <Image
        source={imageProps}
        className={className}
        ref={imageRef}
        onReady={onReady}
        {...fit}
        {...conditionalProps}
        isDisplayed={isDisplayed}
      />
    );
  }

  if (sanityMedia?.mediaType === 'video') {
    const videoRef = ref as ForwardedRef<VideoRef>;
    const videoProps = sanityMedia as CMSVideoProps;

    return (
      <Video
        aspectRatio={videoProps.aspectRatio}
        src={videoProps.url}
        isLooping={isLooping ?? videoProps.isLooping}
        isMuted={isMuted ?? videoProps.isMuted}
        forceIsInView={forceIsInView}
        willAutoplay={willAutoplay ?? videoProps.willAutoplay}
        // The `willAutoplay` prop is sometimes overriten by the modules, in some cases, we want to know what was actually set in the CMS
        cmsWillAutoplay={videoProps.willAutoplay}
        controls={!videoProps.willAutoplay ?? false}
        className={className}
        onReady={onReady}
        onVideoReady={onVideoReady}
        contain={contain}
        ref={videoRef}
        playsInline={playsInline}
        thumbnail={videoProps.thumbnail}
      />
    );
  }

  console.warn('Media Error: No valid Image or Video.', props);
  return null;
};

export default forwardRef(Media);
