'use client';
import { gsap } from 'gsap';
import { useCallback, useRef, useState } from 'react';

import DeviceWrapper from '~/components/atoms/DeviceWrapper/DeviceWrapper';
import Observer from '~/components/atoms/Observer/Observer';
import Media from '~/components/molecules/Media/Media';
import Shadow from '~/components/molecules/Shadow/Shadow';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from '../SideBySide.module.css';
import { MultiDeviceMediaContainerProps } from './MultiDeviceMediaContainer.types';

const MultiDeviceMediaContainer = (props: MultiDeviceMediaContainerProps) => {
  const { innerClassNames, media } = props;

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const progressSetter = useRef<(value: number) => void>();

  const $mediaMultiContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    progressSetter.current = gsap.quickSetter(
      $mediaMultiContainer.current,
      '--multi-device-container-progress',
    ) as (value: number) => void;
  }, []);

  const onProgress = useCallback((progress: number) => {
    if (progressSetter.current) {
      const roundedProgress = Math.round(progress * 1000) / 1000;
      progressSetter.current(roundedProgress);
    }
  }, []);

  useScrollProgress($mediaMultiContainer, onProgress);

  return (
    <Observer
      className={cn(
        styles.mediaMultiDevicesContainer,
        'asset' in media.mobile.sanityMedia,
        innerClassNames?.mediaContainer,
      )}
      callback={updateIsInView}
      options={{ rootMargin: '200% 0%' }}
      ref={$mediaMultiContainer}
    >
      <div className={cn(styles.mediaMultiDevicesInner)}>
        <div className={cn(styles.mediaTabletContainer)}>
          <Media
            sanityMedia={media.tablet.sanityMedia}
            className={cn(
              styles.mediaTabletElement,
              innerClassNames?.glowWrapper,
            )}
            isDisplayed={isInView !== false}
            fixedAspectRatio={true}
          />
          <Shadow />
          <DeviceWrapper deviceType="iPad" isInView={isInView !== false} />
        </div>
        <div className={cn(styles.mediaMobileContainer)}>
          <Media
            sanityMedia={media.mobile.sanityMedia}
            className={cn(
              styles.mediaMobileElement,
              innerClassNames?.glowWrapper,
            )}
            isDisplayed={isInView !== false}
            fixedAspectRatio={true}
          />
          <Shadow />
          <DeviceWrapper deviceType="iPhone" isInView={isInView !== false} />
        </div>
      </div>
    </Observer>
  );
};

export default MultiDeviceMediaContainer;
