import { gsap } from 'gsap';
import { RefObject, useEffect } from 'react';

/**
 * A threshold value (in millis) representing the maximum touch duration
 * that counts as a "flick".
 */
const SWIPE_FLICK_THRESHOLD_MS = 300;

/**
 * The useSwipeControls helper implements the mouse/touch interactions for the
 * live schedule component.
 *
 * @param $el {RefObject} A reference to the HTML element that we will scroll
 */
export function useSwipeControls<T extends HTMLElement>($el: RefObject<T>) {
  useEffect(() => {
    if (!$el.current) {
      return;
    }

    const subscriptions = new Set<() => void>();

    if (matchMedia('(pointer: coarse)').matches) {
      let touchStart: Touch | null = null;
      let touchStartTime = 0;
      let touchDuration = 0;
      let touchVelocity = 0;
      let scrollLeftAtStart = 0;
      let rafCancellationToken = 0;

      const integrate = () => {
        if (touchStart === null && $el.current) {
          if (touchDuration < SWIPE_FLICK_THRESHOLD_MS) {
            gsap.killTweensOf($el.current);
            gsap.to($el.current, {
              scrollLeft: $el.current.scrollLeft + touchVelocity,
              duration: 0.5,
              ease: 'power3',
            });
          }
          touchVelocity = 0;
          rafCancellationToken = 0;
          return;
        }

        // Converge velocity back down to zero over time. However this will
        // never actually reach zero so if the velocity falls below a threshold
        // we consider it done
        touchVelocity *= 0.8;

        if (Math.abs(touchVelocity) < 0.25) {
          touchVelocity = 0;
          rafCancellationToken = 0;
          return;
        }

        rafCancellationToken = requestAnimationFrame(integrate);
      };

      // Update scroll position while the user is touching down & swiping
      const onTouchMove = (evt: TouchEvent) => {
        if (!touchStart) {
          return;
        }

        const touch = evt.touches[0];
        const deltaX = touchStart.screenX - touch.screenX;

        touchVelocity += deltaX;

        gsap.killTweensOf($el.current);
        gsap.set($el.current, {
          scrollLeft: scrollLeftAtStart + deltaX,
        });

        if (rafCancellationToken === 0) {
          integrate();
        }

        evt.stopPropagation();
        evt.preventDefault();
      };

      // Cleanup event listeners on release
      const onTouchEnd = () => {
        touchStart = null;
        touchDuration = performance.now() - touchStartTime;

        window.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('touchend', onTouchEnd);
      };

      // Start listening for touch move/end events on the window object. This
      // is intentional so that we track touches even if the touch leaves
      // the bounds of the element.
      const onTouchStart = (evt: TouchEvent) => {
        scrollLeftAtStart = $el.current?.scrollLeft || 0;
        touchStart = evt.touches[0];
        touchStartTime = performance.now();

        window.addEventListener('touchmove', onTouchMove);
        window.addEventListener('touchend', onTouchEnd);
      };

      $el.current?.addEventListener('touchstart', onTouchStart);

      subscriptions.add(() => {
        $el.current?.removeEventListener('touchstart', onTouchStart);
      });
    }

    if (matchMedia('(pointer: fine)').matches) {
      const onWheel = (evt: WheelEvent) => {
        if (!$el.current) {
          return;
        }

        const position = $el.current.scrollLeft;
        const delta = evt.deltaX;

        gsap.killTweensOf($el.current);
        gsap.set($el.current, {
          scrollLeft: position + delta,
        });

        evt.preventDefault();
        evt.stopPropagation();
      };

      $el.current.addEventListener('wheel', onWheel);
      subscriptions.add(
        () => $el.current?.removeEventListener('wheel', onWheel),
      );
    }

    return () => {
      for (const unsubscribe of subscriptions) {
        unsubscribe();
      }
    };
  }, [$el]);
}
