import { RefObject, useEffect, useRef } from 'react';

import UIStore from '~/state/ui';

import MousePosition from '../domEvents/MousePosition/MousePosition';
import { distance } from '../math';
import { tickerAddOnce } from '../ticker';

/**
 * A React hook that calculates the mouse distance from the center of a given element and
 * executes a callback function with the distance.
 *
 * @param $elementRef - A React ref object pointing to the target HTML element.
 * @param callback - A callback function that is called with the calculated distance when the distance changes.
 * @param relativeUnit - Optional. If true, the distance is given in relative units to the viewport size, otherwise in pixels.
 * @returns The total distance, and an object containing the x distance, and the y distance.
 *
 * @remarks
 * The hook listens for mouse movements and calculates the distance from the mouse to the center of the
 * target element represented by `$elementRef`. If `relativeUnit` is true, the distance is calculated
 * relative to the viewport dimensions, otherwise it's in pixels. The provided `callback` is called
 * whenever the calculated distance changes.
 *
 * This hook does not cause re-renders since it uses `useRef` to track the mouse distance and does not
 * return any state. The hook is meant for side effects that are dependent on the mouse's distance from
 * an element, like updating styles or triggering animations directly.
 *
 * Note: This hook does not take into account the potential change of position of the element observed.
 *
 * @example
 * const elementRef = useRef<HTMLDivElement>(null);
 * useMouseDistance(elementRef, (distance, {x, y}) => {
 *   console.log(`Mouse is ${distance} units from the element center`);
 * });
 *
 * // The callback function will log the mouse distance from the center of the element referenced by `elementRef`.
 */

const useMouseDistance = (
  $elementRef: RefObject<HTMLElement>,
  callback: (distance: number, details: { x: number; y: number }) => void,
  options?: {
    relativeUnit?: boolean;
    origin?: 'center' | 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft';
  },
) => {
  const mouseDistance = useRef(0);
  useEffect(() => {
    const unsubscribe = MousePosition.subscribe((position) => {
      tickerAddOnce(() => {
        if ($elementRef.current) {
          const elementBoundingRect =
            $elementRef.current.getBoundingClientRect();

          let elementCenterX;
          let elementCenterY;

          switch (options?.origin) {
            case 'bottomRight':
              elementCenterX =
                elementBoundingRect.x + elementBoundingRect.width;
              elementCenterY =
                elementBoundingRect.y + elementBoundingRect.height;
              break;
            case 'bottomLeft':
              elementCenterX = elementBoundingRect.x;
              elementCenterY =
                elementBoundingRect.y + elementBoundingRect.height;
              break;
            case 'topRight':
              elementCenterX =
                elementBoundingRect.x + elementBoundingRect.width;
              elementCenterY = elementBoundingRect.y;
              break;
            case 'topLeft':
              elementCenterX = elementBoundingRect.x;
              elementCenterY = elementBoundingRect.y;
              break;
            default:
              elementCenterX =
                elementBoundingRect.x + elementBoundingRect.width / 2;
              elementCenterY =
                elementBoundingRect.y + elementBoundingRect.height / 2;
              break;
          }

          let newMouseDistance;
          let x = 0;
          let y = 0;
          if (options?.relativeUnit) {
            const windowWidth = UIStore.getState().windowWidth;
            const windowHeight = UIStore.getState().windowHeight;

            if (windowWidth && windowHeight) {
              x = (elementCenterX - position.x) / windowWidth;
              y = (elementCenterY - position.y) / windowHeight;
              newMouseDistance = distance(
                elementCenterX / windowWidth,
                elementCenterY / windowHeight,
                position.x / windowWidth,
                position.y / windowHeight,
              );
            }
          } else {
            x = elementCenterX - position.x;
            y = elementCenterY - position.y;
            newMouseDistance = distance(
              elementCenterX,
              elementCenterY,
              position.x,
              position.y,
            );
          }

          if (newMouseDistance && newMouseDistance !== mouseDistance.current) {
            mouseDistance.current = newMouseDistance;
            callback(mouseDistance.current, {
              x,
              y,
            });
          }
        }
      });
    });

    return unsubscribe;
  }, [$elementRef, callback, mouseDistance, options]);
};

export default useMouseDistance;
