function calculateSlideVisibility(n: number, progress: number, loop?: boolean) {
  // Adjust fractional index based on the loop parameter
  const fractionalIndex = loop ? (progress * n) % n : progress * (n - 1);

  // Initialize visibility array with zeros
  const visibilities = new Array(n).fill(0);

  // Calculate visibility for each slide considering the loop
  for (let i = 0; i < n; i++) {
    let distance;
    if (loop) {
      // Calculate the direct and looped distances for the looping slider
      const directDistance = Math.abs(fractionalIndex - i);
      const loopedDistance = n - directDistance;
      distance = Math.min(directDistance, loopedDistance);
    } else {
      // Calculate distance normally for the non-looping slider
      distance = Math.abs(fractionalIndex - i);
    }

    // For the fractionalIndex close to n, manage the transition from the last slide to the first
    if (distance < 1 || Math.abs(n - distance) < 1) {
      // Adjust visibility based on the smaller of the two distances
      visibilities[i] = Math.round((1 - distance + Number.EPSILON) * 100) / 100;
    }
  }

  return visibilities;
}

export default calculateSlideVisibility;
